import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import UseAuthentication from "./session/UseAuthentication.js";
import LandingPage from "./views/Landing";
import SignUpPage from "./views/SignUp";
import SignInPage from "./views/SignIn";
import PasswordForget from "./views/PasswordForget";
import Challenge from "./views/Challenge";
import ChallengeLanding from "./views/ChallengesLanding";
import ChallengeCreate from "./views/ChallengeCreate";
import ChallengeEdit from "./views/ChallengeEdit";
import ConfirmationPage from "./views/ConfirmationPage";
import ContactPage from "./views/Contact";
import AdminPage from "./views/Admin";
import TextParser from "./views/ParseText.js";
import Description from "./views/Description";
import CreateJobOffer from "./views/CreateJobOffer";
import JobOffers from "./views/JobOffers.js";
import JobOffer from "./views/JobOffer.js";
import ChallengeSuscription from "./views/ChallengeSuscription";
import ChallengeInvitation from "./views/ChallengeInvitation";
import ChallengeHidden from "./views/ChallengeHidden";
import * as ROUTES from "./config/routes";
import RolesContext from "./session/RolesContext";
import { auth } from "./firebase/firebase";
import Navigation from "./components/navbar/Navigation.js";

export const baseTheme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 684,
      md: 768,
      lg: 1224,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#2B3E7F",
      light: "#5c68af",
      dark: "#001952",
      contrastText: "#fff",
    },
    secondary: {
      main: "#42B5C3",
      light: "#7be7f6",
      dark: "#008593",
      contrastText: "#F7F7FF",
    },
    warning: {
      main: "#F96900",
      contrastText: "#fff",
    },
    error: {
      main: "#D62839",
    },
    text: {
      primary: "#273E80",
      secondary: "#31B5C4",
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "Roboto",
      "'Helvetica Neue'",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontWeight: "bold",
      "@media (min-width:768px)": {
        fontSize: "4rem",
      },
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
      fontSize: 18,
      "@media (min-width:600px)": {
        //desktop view
        fontSize: 25,
        margin: 0,
      },
    },
    body1: {
      fontSize: "1.1rem",
      lineHeight: "1.2",
      "@media (min-width:600px)": {
        fontSize: "1.3rem",
      },
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.2",
      "@media (min-width:600px)": {
        fontSize: "1rem",
        lineHeight: "1.2",
      },
    },
  },
});

const theme = createTheme(
  {
    overrides: {
      MuiTypography: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      MuiContainer: {
        display: "flex",
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            padding: baseTheme.spacing(2, 3),
            fontFamily: baseTheme.typography.fontFamily,
          },
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: baseTheme.palette.primary.contrastText,
        },
      },
      MuiToolbar: {
        root: {
          display: "flex",
          width: "1190px",
          margin: "0 auto",
          marginTop: baseTheme.spacing(1),
          [baseTheme.breakpoints.down("lg")]: {
            width: "684px",
          },
          [baseTheme.breakpoints.down("md")]: {
            width: "100%",
          },
        },
      },
    },
  },
  baseTheme
);

class App extends React.Component {
  state = {
    isClient: false,
  };

  componentDidMount() {
    //this function is to verify if the current user is an client or not
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          //if its a Client its going to be true
          user = idTokenResult.claims;
          this.setState({
            isClient: user.client,
          });
        });
      }
    });
  }

  render() {
    const isClient = this.state.isClient;
    return (
      <RolesContext.Provider value={{ isClient: isClient }}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <div>
              <Navigation />
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route
                exact
                path={ROUTES.CHALLENGE_CREATE}
                component={ChallengeCreate}
              />
              <Route
                exact
                path={ROUTES.CHALLENGE_EDIT}
                component={ChallengeEdit}
              />
              <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                exact
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForget}
              />
              <Route exact path={ROUTES.CHALLENGE} component={Challenge} />
              <Route
                exact
                path={ROUTES.CHALLENGES}
                component={ChallengeLanding}
              />
              <Route exact path={ROUTES.ADMIN} component={AdminPage} />
              <Route exact path={ROUTES.PARSETEXT} component={TextParser} />
              <Route
                exact
                path={ROUTES.CONFIRMATION_PAGE}
                component={ConfirmationPage}
              />
              <Route exact path={ROUTES.DESCRIPTION} component={Description} />
              <Route exact path={ROUTES.CONTACT} component={ContactPage} />
              <Route
                exact
                path={ROUTES.CREATE_JOB_OFFER}
                component={CreateJobOffer}
              />
              <Route exact path={ROUTES.JOB_OFFERS} component={JobOffers} />
              <Route exact path={ROUTES.JOB_OFFER} component={JobOffer} />
              <Route
                exact
                path={ROUTES.CHALLENGE_SUSCRIPTION}
                component={ChallengeSuscription}
              />
              <Route
                exact
                path={ROUTES.CHALLENGE_INVITATION}
                component={ChallengeInvitation}
              />
              <Route
                exact
                path={ROUTES.CHALLENGE_HIDDEN}
                component={ChallengeHidden}
              />
            </div>
          </Router>
        </MuiThemeProvider>
      </RolesContext.Provider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object,
};

export default UseAuthentication(App);
