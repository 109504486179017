import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: -1,
    position: "relative",
    display: "block",
    width: "100%",
    maxWidth: "100%",
    marginBottom: 0,
    paddingBottom: 0,
    backgroundColor: "inherit",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  innerContainer: {
    paddingTop: 0,
  },
  description: {
    lineHeight: 1.3,
    fontSize: 21,
  },
}));

const Banner = ({ title, body, size = "small" }) => {
  const classes = useStyles();
  return (
    <div>
      <Container className={classes.container}>
        <Hidden implementation="css" smDown>
          {/* For computer screen */}
          <Container maxWidth="md" className={classes.innerContainer}>
            <Box
              mt={size === "small" ? 0 : 4}
              mb={size === "small" ? 0 : 4}
              alignItems="center"
              width="initial"
            >
              {title ? (
                <Box p={5}>
                  <Typography
                    variant="h4"
                    color="primary"
                    align="center"
                    gutterBottom
                  >
                    {title}
                  </Typography>
                </Box>
              ) : null}
              {body ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1}
                >
                  <Typography
                    align="center"
                    variant="body1"
                    color="primary"
                    className={classes.description}
                  >
                    {body}
                  </Typography>
                </Box>
              ) : null}
              {!title ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    align="center"
                    variant="body1"
                    color="primary"
                    className={classes.description}
                  >
                    {body}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Container>
        </Hidden>
        <Hidden implementation="css" mdUp>
          {/* For mobile screen */}
          <Box pt={2} pb={2} alignItems="center" width="initial">
            {title ? (
              <Box pb={body ? 3 : 0}>
                <Typography align="left" variant="h4" color="primary">
                  {title}
                </Typography>
              </Box>
            ) : null}
            {body ? (
              <Typography
                align="left"
                variant="body1"
                className={classes.description}
                color="primary"
              >
                {body}
              </Typography>
            ) : null}
          </Box>
        </Hidden>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default Banner;
