import { db } from "./firebase";

export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email,
  });

export const onceGetUsers = () => db.ref("users").once("value");

//this function should get info only by the id of that user
export const onceGetUserInfo = (id) => db.ref(`users/${id}`).once("value");

export const addingUserInfo = (
  id,
  name,
  username,
  email,
  lastname,
  dateOfBirth,
  countryOfResidency,
  profession,
  companyOrSchool,
  checkMail
) => {
  let date = Date.now();
  let userData = {
    name,
    username,
    email,
    lastname,
    dateOfBirth,
    countryOfResidency,
    profession,
    companyOrSchool,
    checkMail,
    date,
  };

  let updates = {};
  updates["/users/" + id + "/"] = userData;
  // updates['/users/' + id + '/picture/'] = picture
  return db.ref().update(updates);
};

// Function to put new fields of user description in User Profile in firebase db
export const addingUserDesc = (id, userDesc) =>
  db
    .ref("/users/" + id)
    .child("userDesc")
    .set(userDesc);

export const addUserSubmission = (
  userId,
  username,
  challengeId,
  fileName,
  url,
  winner
) => {
  let date = Date.now();
  let data = {
    userId,
    username,
    fileName,
    url,
    date,
    challengeId,
    winner,
  };
  var newSubmissionKey = db.ref().child("submissions").push().key;

  let updates = {};
  updates["/challenge-submission/" + challengeId + "/" + newSubmissionKey] =
    data;
  updates["/user-submissions/" + userId + "/" + newSubmissionKey] = data;
  updates["/submissions/" + newSubmissionKey] = data;
  return db.ref().update(updates);
};

export const updateSubmission = (submissionId, data) => {
  return db.ref("/submissions/" + submissionId + "/").update(data);
};

export const updateChallengeSubmission = (challengeId, submissionId, data) => {
  return db
    .ref("/challenge-submission/" + challengeId + "/" + submissionId + "/")
    .update(data);
};

export const updateUserSubmission = (userId, submissionId, data) => {
  return db
    .ref("/user-submissions/" + userId + "/" + submissionId + "/")
    .update(data);
};

// Get submissions ordered by score
export const getSubmissionsByScore = (challengeId) =>
  db.ref("challenge-submission/" + challengeId).once("value");

//this function should get info only by the id of that user
export const getUserSubmissions = (userId) =>
  db.ref(`user-submissions/${userId}`).orderByChild("date").once("value");

export const createChallenge = (
  name,
  shortDescription,
  prizeMoney,
  endDate,
  description,
  prizesDescription,
  rulesAndEvaluation,
  instructions,
  faq,
  calendar,
  dataDescription,
  dataDownloadLink,
  tags,
  resultsFormat,
  resultsOrder,
  maxSubmissionsPerDay,
  type,
  emailPreference
) => {
  let newChallengeKey = db.ref().child("challenges").push().key;
  //let type = "binnario-default";
  let display = false;
  let data = {
    name,
    shortDescription,
    prizeMoney,
    endDate,
    logoFileName: "logo",
    termsAndConditionsFileName: "t&c",
    submissionExampleFileName: "submission_example",
    description,
    prizesDescription,
    rulesAndEvaluation,
    instructions,
    faq,
    calendar,
    dataDescription,
    dataDownloadLink,
    tags,
    isActive: true,
    resultsFormat,
    resultsOrder,
    maxSubmissionsPerDay,
    type,
    display,
    emailPreference,
  };
  let updates = {};
  updates["/challenges/" + newChallengeKey] = data;
  return {
    promess: db.ref().update(updates),
    id: newChallengeKey,
  };
};

// Get all  challenges
export const getChallenges = () =>
  db.ref("challenges/").orderByChild("endDate").once("value");

// Get a specific challenge
export const getChallenge = (challengeId) =>
  db.ref(`challenges/${challengeId}`).once("value");

// Register active user to a specific challenge
//to do: borrar esto !
export const subscribeToChallenge = (userId, challengeId) => {
  db.ref(`users-challenges/${userId}/${challengeId}`).set({
    userId,
    challengeId,
  });
  db.ref(`challenges-users/${challengeId}/${userId}`).set({
    userId,
    challengeId,
  });
  return;
};

// Method to verify if the user is subscribed to a specific challenge
export const verifyUserSubscribed = (userId, challengeId) =>
  db.ref(`users-challenges/${userId}`).orderByKey().equalTo(challengeId);

// Method to save contacts submitted from the contact form
export const registerBusinessContact = (contactData) =>
  db.ref(`contacts`).push(contactData);

export const updateChallenge = (challengeId, data) => {
  return db.ref("/challenges/" + challengeId + "/").update(data);
};

export const createNewClient = (clientId, name, lastname, email) => {
  let date = Date.now();
  let clientData = {
    date,
    name,
    lastname,
    email,
  };
  let updates = {};
  updates["/clients/" + clientId] = clientData;
  return db.ref().update(updates);
};

export const removeClient = (clientId) => {
  db.ref(`/clients/${clientId}`).remove();
};

export const getClients = () => db.ref("clients").once("value");

//this function should get info only by the id of that user
export const getClient = (id) => db.ref(`clients/${id}`).once("value");

// Get suscriptions by user id
export const getAllSuscriptions = () =>
  db.ref("suscriptions/").orderByChild("date").once("value");

export const createJobOffer = (
  userId,
  company,
  companyShortDescription,
  endDate,
  jobType,
  jobShortDescription,
  jobDescription,
  tags
) => {
  let newJobOfferKey = db.ref().child("job-offers").push().key;
  let date = Date.now();
  let data = {
    userId,
    company,
    companyShortDescription,
    logoFileName: "logo",
    endDate,
    jobType,
    jobShortDescription,
    jobDescription,
    isActive: true,
    display: false,
    tags,
    date,
  };
  let updates = {};
  updates["/job-offers/" + newJobOfferKey] = data;
  return {
    promess: db.ref().update(updates),
    id: newJobOfferKey,
  };
};

export const updateJobOffer = (jobOfferId, data) => {
  return db.ref("/job-offers/" + jobOfferId + "/").update(data);
};

// Get all  challenges
export const getJobOffers = () => db.ref("job-offers/").once("value");

export const getJobOffer = (jobOfferId) =>
  db.ref(`job-offers/${jobOfferId}`).once("value");

export const getSuscriptionsByChallenge = (challengeId) =>
  db.ref("challenge-suscriptions/" + challengeId).once("value");

export const getSuscriptionsByUser = (userId) =>
  db.ref("user-suscriptions/" + userId).once("value");

export const getAllSuscriptionsJobOffer = () =>
  db.ref("suscriptions/").once("value");

export const suscribeToChallenge = (challengeId, userId) => {
  let date = Date.now();
  let data = {
    userId,
    date,
    challengeId,
  };
  var newSuscriptionKey = db.ref().child("suscriptions").push().key;
  let updates = {};
  updates["/challenge-suscriptions/" + challengeId + "/" + newSuscriptionKey] =
    data;
  updates["/user-suscriptions/" + userId + "/" + newSuscriptionKey] = data;
  updates["/suscriptions/" + newSuscriptionKey] = data;
  return db.ref().update(updates);
};
