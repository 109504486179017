// The file where all the configuration goes that you have seen previously on your
//Firebase dashboard. In addition, Firebase itself will be instantiated in this file.
import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/database";
import "@firebase/storage";
import "@firebase/functions";

var config = {};
if (process.env.NODE_ENV === "production") {
  config = {
    apiKey: process.env.REACT_APP_API_KEY_PRODUCTION,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_PRODUCTION,
    databaseURL: process.env.REACT_APP_DATABASE_URL_PRODUCTION,
    projectId: process.env.REACT_APP_PROJECT_ID_PRODUCTION,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PRODUCTION,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PRODUCTION,
  };
} else {
  config = {
    apiKey: process.env.REACT_APP_API_KEY_DEVELOPMENT,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEVELOPMENT,
    databaseURL: process.env.REACT_APP_DATABASE_URL_DEVELOPMENT,
    projectId: process.env.REACT_APP_PROJECT_ID_DEVELOPMENT,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEVELOPMENT,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEVELOPMENT,
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

export { db, auth, storage, functions };
