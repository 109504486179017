import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Typography, Grid } from "@material-ui/core";
import UserList from "../../components/UserList";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

class ListClients extends React.Component {
  render() {
    let clients = this.props.clients;
    return (
      <Box display="flex" justifyContent="center" pl={5} pt={5}>
        <Grid item>
          <Typography gutterBottom component="h1" variant="h5">
            Clientes de BINNARIO:
          </Typography>
          {clients &&
            clients.length > 0 &&
            clients.map((client, i) => (
              <Grid key={i} item>
                <UserList
                  suscribedUser={client}
                  date={moment(client.date).format("LL")}
                  key={i}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    );
  }
}

ListClients.propTypes = {
  challenge: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  width: PropTypes.string,
  clients: PropTypes.object,
};

export default withRouter(ListClients);
