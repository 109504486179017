import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { db } from "../firebase";
import Grid from "@material-ui/core/Grid";
import ChallengeCard from "../components/challengeindex/ChallengeCard";
import { auth } from "../firebase/firebase";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  skeleton: {
    borderRadius: 32,
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 50,
    },
  },
}));

const ChallengeIndex = () => {
  const [allChallenges, setAllChallenges] = React.useState({});
  const [filteredChallenges, setFilteredChallenges] = React.useState({});
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          console.log(idTokenResult.claims, "admin info");
          setIsAdmin(user);
        });
      }
    });
    db.getChallenges()
      .then((snapshot) => {
        getChallenges(snapshot);
      })
      .catch((error) => error);
  }, []);

  const getChallenges = (snapshot) => {
    let allChallenges = [];
    let filteredChallenges = [];
    snapshot.forEach((challengeSnapshot) => {
      let challenge = challengeSnapshot.val();
      allChallenges.push({ ...challenge, id: challengeSnapshot.key });
      if (challenge.type !== "hidden") {
        filteredChallenges.push({ ...challenge, id: challengeSnapshot.key });
      }
    });
    setAllChallenges(allChallenges);
    setFilteredChallenges(filteredChallenges);
  };

  const classes = useStyles();
  const challenges = isAdmin ? allChallenges : filteredChallenges;

  console.log({ challenges });

  return (
    <div>
      <Grid container className={classes.grid} xs={12}>
        <Grid item xs={9} className={classes.centerGrid}>
          <Banner title={"Desafíos"} />
        </Grid>
        <Grid item xs={9} className={classes.centerGrid}>
          {challenges.length > 0 ? (
            challenges
              .reverse()
              .map((challenge, index) => (
                <ChallengeCard
                  key={index}
                  isAdmin={isAdmin}
                  challenge={challenge}
                />
              ))
          ) : (
            <>
              <Skeleton
                variant="rect"
                className={classes.skeleton}
                width={1000}
                height={350}
              />
              <Skeleton
                variant="rect"
                className={classes.skeleton}
                width={1000}
                height={350}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

ChallengeIndex.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  challenge: PropTypes.object,
  classes: PropTypes.object,
};

export default ChallengeIndex;
