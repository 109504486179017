import React from "react";
import PropTypes from "prop-types";
import * as ROUTES from "../../config/routes";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

class PasswordForgetLink extends React.Component {
  render() {
    return (
      <Typography
        variant="body2"
        align="center"
        onClick={() => this.props.link(ROUTES.PASSWORD_FORGET)}
      >
        <Link color="secondary">¿Olvidaste tu contraseña?</Link>
      </Typography>
    );
  }
}

PasswordForgetLink.propTypes = {
  link: PropTypes.func,
};

export default PasswordForgetLink;
