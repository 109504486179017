import React from "react";
import PropTypes from "prop-types";
import { Box, Container } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { db } from "../../firebase";
import { auth } from "../../firebase/firebase";
import Banner from "../Banner";
import JobOfferCard from "./JobOfferCard";

class JobOfferLanding extends React.Component {
  constructor() {
    super();
    this.state = {
      jobOffers: {},
      allJobOffers: {},
      nojobOffers: false,
      isLoading: true,
      hasError: false,
      isAdmin: false,
    };
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({ isAdmin: user });
        });
      }
    });

    await db
      .getJobOffers()
      .then((snapshot) => this.getAllJobOffers(snapshot))
      .catch((error) => error);
  }

  getAllJobOffers = (snapshot) => {
    let allJobOffers = [];
    snapshot.forEach((jobOfferSnapshot) => {
      let jobOffer = jobOfferSnapshot.val();
      allJobOffers.push({ ...jobOffer, id: jobOfferSnapshot.key });
    });
    this.setState({ allJobOffers });
    //if there are no challengues related to the course, then set state noChallenges: true
    if (Object.keys(allJobOffers).length === 0) {
      this.setState({ nojobOffers: true });
    }
  };

  render() {
    let isAdmin = this.state.isAdmin;
    return (
      <div>
        <Banner title={"Ofertas Laborales"} />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {this.state.allJobOffers.length > 0 ? (
              this.state.allJobOffers.reverse().map((jobOffer, index) => (
                <Box display="flex" key={index}>
                  <JobOfferCard isAdmin={isAdmin} jobOffer={jobOffer} />
                </Box>
              ))
            ) : (
              <>
                {/* to do: pass styles to make styles  */}
                <Skeleton
                  variant="rect"
                  width={1000}
                  height={350}
                  style={{
                    borderRadius: 32,
                    marginBottom: 20,
                  }}
                />
                <Skeleton
                  variant="rect"
                  width={1000}
                  height={350}
                  style={{
                    borderRadius: 32,
                    marginBottom: 50,
                  }}
                />
              </>
            )}
          </Box>
        </Container>
      </div>
    );
  }
}

JobOfferLanding.propTypes = {
  key: PropTypes.number,
};

export default JobOfferLanding;
