import React from "react";
import PropTypes from "prop-types";
import { Paragraph } from "../../styling/Styles";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import processChallenge from "../../helpers/processChallengeInfo";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  roundIcon: {
    fontSize: "0.4em",
    marginRight: "1%",
  },
  box: {
    border: `1px solid ${theme.palette.grey["500"]}`,
    padding: "10px",
    textAlign: "left",
    margin: "0em 0em 2em 0em",
    boxShadow: `1px 1px 1px ${theme.palette.grey["400"]}`,
    borderRadius: "4px",
    backgroundColor: theme.palette.grey["50"],
  },
  separatorInfo: {
    borderTop: "2px solid #251A6A",
    width: "85%",
  },
  separatorBlock: {
    borderTop: "2px solid #251A6A",
    width: "70%",
    align: "center",
  },
  marginTitle: {
    marginTop: theme.breakpoints.down("sm") ? "250px" : "0px",
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
    padding: "1em",
  },
  rightGridMargin: {
    marginTop: theme.breakpoints.down("sm") ? "40px" : "0px",
  },
});

class Richtext extends React.Component {
  constructor(props) {
    super(props);
    let variableWithInfo = props.variableWithInfo;
    //to do: change jobDesciption so a dynamic variable
    this.state = {
      jobDescription:
        variableWithInfo.jobDescription === undefined
          ? []
          : processChallenge(variableWithInfo.jobDescription),
    };
  }

  render() {
    // const { classes } = this.props;
    // const { variableWithInfo } = this.state;
    return (
      <Box p={2}>
        <Typography gutterBottom variant="h4" color="primary">
          Acerca del Cargo:
        </Typography>
        <Paragraph>
          {this.state.jobDescription.map((component) => {
            if (typeof component.tag === "string")
              return (
                <div
                  key={component.key}
                  dangerouslySetInnerHTML={{ __html: component.tag }}
                />
              );
            else return component.tag;
          })}
        </Paragraph>
      </Box>
    );
  }
}

Richtext.propTypes = {
  classes: PropTypes.object,
  variableWithInfo: PropTypes.object,
};

export default withStyles(styles)(Richtext);
