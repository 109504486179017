import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Grid,
  Divider,
} from "@material-ui/core";
import { db } from "../firebase";
import NotYetData from "../Data/ErrorData";
import NotYetView from "../components/NotYetView/NotYetView";
import Richtext from "../components/Richtext/Richtext";
import Footer from "../components/Footer";
import Banner from "../components/Banner/index";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  circularProgressGrid: {
    height: window.innerHeight - 100,
  },
  logo: {
    width: "100%",
    borderRadius: "10px",
    objectFit: "scale-down",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  mail: {
    color: theme.palette.secondary.main,
  },
});

class JobOffer extends React.Component {
  //the jobOffer contains its challenges and can create new challenges
  constructor() {
    super();
    this.state = {
      challenges: {},
      jobOffer: undefined,
      jobOfferError: false,
      noChallenges: false,
      isLoading: true,
      hasError: false,
      isAdmin: false,
    };
  }

  async componentDidMount() {
    //this function will get all the challenges with the right id from the job offer
    await db
      .getChallenges()
      .then((snapshot) => this.filterChallenges(snapshot))
      .catch((error) => error);
    //this function will get the jobOffer info
    try {
      const jobOffer = await db.getJobOffer(this.props.match.params.id);
      if (jobOffer.val() === null)
        this.setState({ jobOfferError: true, isLoading: false });
      else
        this.setState({
          jobOffer: { ...jobOffer.val() },
          isLoading: false,
        });
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }
  }

  //this function will only display challenges that have the same jobOffer id as the url id one
  filterChallenges = (snapshot) => {
    let challenges = [];
    snapshot.forEach((challengeSnapshot) => {
      let challenge = challengeSnapshot.val();
      //jobOfferId comes from the url thanks to Router
      let jobOfferId = this.props.match.params.id;
      let jobOfferIdFromChallenge = challenge.courseId;
      if (jobOfferId === jobOfferIdFromChallenge) {
        challenges.push({ ...challenge, id: challengeSnapshot.key });
      }
    });
    this.setState({ challenges });
    //if there are no challengues related to the jobOffer, then set state noChallenges: true
    if (Object.keys(challenges).length === 0) {
      this.setState({ noChallenges: true });
    }
  };

  render() {
    const { classes } = this.props;
    let jobOfferErrorText = NotYetData.errors.jobOfferNotFound;
    let ErrorText = NotYetData.errors.error;

    const stateOfJO = this.state.jobOffer?.isActive
      ? "Postulaciones Abiertas"
      : "Postulaciones Cerradas";

    if (this.state.isLoading) {
      return (
        <div>
          <Box
            display="flex"
            justifyContent="center"
            pt={15}
            className={classes.circularProgressGrid}
          >
            <CircularProgress size={100} />
          </Box>
        </div>
      );
    }
    if (this.state.jobOfferError) {
      return (
        <div>
          <NotYetView NotYetText={jobOfferErrorText} />
          <Footer />
        </div>
      );
    }
    if (this.state.hasError) {
      return (
        <div>
          <NotYetView NotYetText={ErrorText} />
          <Footer />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Banner title={"Binnario te invita al cargo de:"} />
        <Divider style={{ padding: 0 }} />
        {this.state.jobOffer && (
          <Container>
            <Grid xs={10} style={{ margin: "auto" }}>
              <Box
                pt={5}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Box display="flex">
                  <Box
                    maxWidth={150}
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <img
                      src={this.state.jobOffer.logoUrl}
                      alt="JobOfferLogo"
                      className={classes.logo}
                    />
                  </Box>
                  <Box
                    maxWidth={600}
                    p={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                      color="primary"
                    >
                      {this.state.jobOffer.company}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={
                        this.state.jobOffer.isActive
                          ? { fontWeight: "bold", color: "#42B5C3" }
                          : { fontWeight: "bold", color: "#D62839" }
                      }
                    >
                      {stateOfJO}
                    </Typography>
                    {this.state.jobOffer.isActive && (
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{ fontWeight: "bold" }}
                      >
                        Hasta el{" "}
                        {moment(this.state.jobOffer.endDate).format("LL")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  maxWidth={1100}
                  py={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Typography variant="h1" color="primary">
                    {this.state.jobOffer.jobType}
                  </Typography>
                </Box>
              </Box>
              <Grid container className={classes.gridCards}>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Box>
                    <Richtext variableWithInfo={this.state.jobOffer} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
        {this.state.jobOffer.isActive && (
          <>
            <Divider />
            <Box
              pt={6}
              pb={6}
              style={{ maxWidth: 1000, margin: "auto" }}
              display="flex"
              justifyContent="center"
            >
              <Typography align="center" variant="body1" color="primary">
                Si estás interesado en el cargo envianos tu CV y expectativa de
                renta a{" "}
                <strong className={classes.mail}>
                  cfernandez@binnario.ai{" "}
                </strong>
                , con el nombre:{" "}
                <strong>
                  {" "}
                  Cargo {this.state.jobOffer.jobType}{" "}
                  {this.state.jobOffer.company}{" "}
                </strong>
              </Typography>
            </Box>
          </>
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

JobOffer.propTypes = {
  jobOffer: PropTypes.object,
  classes: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }),
};

export default withStyles(styles)(withRouter(JobOffer));
