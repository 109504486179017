import React, { Component } from "react";
import PropTypes from "prop-types";
import * as ROUTES from "../../config/routes";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

class SignUpLink extends Component {
  render() {
    return (
      <Typography
        variant="body2"
        align="center"
        onClick={() => this.props.link(ROUTES.SIGN_UP)}
      >
        <Link color="secondary">No tienes una cuenta? Crear cuenta</Link>
      </Typography>
    );
  }
}

SignUpLink.propTypes = {
  link: PropTypes.func,
};

export default SignUpLink;
