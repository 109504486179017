import React, { Component } from "react";
import PropTypes from "prop-types";
import { TitleH1, TitleIH4 } from "../../styling/Styles";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Container, Box, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { db } from "../../firebase";
import { convertDateInMilliseconds } from "../../helpers/convertDateinMilliseconds";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "800px",
  },
  imageBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divider0: {
    marginTop: 15,
    backgroundColor: theme.palette.primary.main,
    height: "3px",
    borderRadius: "0.5em",
    marginRight: "30%",
  },
  icon: {
    fontSize: "0.6em",
    marginRight: "1%",
    color: theme.palette.primary.main,
  },
});

class ChallengeHeader extends Component {
  componentDidMount() {
    let end = this.props.challenge.endDate;
    this.checkStateofChallenge(end);
  }

  checkStateofChallenge = (end) => {
    const millisecondsEndDate = convertDateInMilliseconds(end);
    let todayDate = new Date();
    if (millisecondsEndDate - todayDate <= 0) {
      db.updateChallenge(this.props.challenge.id, {
        isActive: false,
      });
    } else {
      db.updateChallenge(this.props.challenge.id, {
        isActive: true,
      });
    }
  };

  render() {
    const { classes, challenge } = this.props;
    return (
      <Container className={classes.container}>
        <Box className={classes.imageBox}>
          <img
            style={{ width: "25%" }}
            src={challenge.logoUrl}
            alt="Company logo"
          />
        </Box>
        <TitleH1> {challenge.name} </TitleH1>
        <Box>
          <Typography gutterBottom variant="h5" color="primary">
            {challenge.shortDescription}
          </Typography>
          <Divider className={classes.divider0} />
          {challenge.isActive ? (
            <Box my={2}>
              <Typography gutterBottom variant="body2" color="secondary">
                Desafío Abierto hasta las 23.59.00 del{" "}
                {moment(challenge.endDate).format("LL")}
              </Typography>
              <Divider className={classes.divider0} />
            </Box>
          ) : (
            <Box my={2}>
              <Typography gutterBottom variant="h5" color="secondary">
                Desafío Cerrado
              </Typography>
              <Divider className={classes.divider0} />
            </Box>
          )}
          <TitleIH4> ${challenge.prizeMoney} USD</TitleIH4>
        </Box>
        <Box pt={2} display="flex">
          <Icon className={classes.icon}>fiber_manual_record</Icon>
          <Icon className={classes.icon}>fiber_manual_record</Icon>
          <Icon className={classes.icon}>fiber_manual_record</Icon>
        </Box>
      </Container>
    );
  }
}

ChallengeHeader.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
};

export default withStyles(styles)(ChallengeHeader);
