import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Background = styled.div`
  && {
.f4398ea3-4d89-47db-8e73-daf2eb73c170{fill:#42e8e0;}.b358e0c4-dfcc-4521-82ff-b39d018385c6{fill:#3dc8ce;}.aab21c75-b835-4e46-8536-8364c4c6c5cb{fill:#42b5c3;}.f7f688c2-c0b4-4a08-b0d0-de7c0d2352af{fill:#40dedb;}.a95b629d-2f32-4616-ad96-e93efa9524dc{fill:#251a6a;}.a9198a0c-b9d8-4f28-b807-6e551eafb26f{fill:#2a3e81;}.f19d5a9c-6775-424b-b38b-1dd07acaf58d{fill:#2d528d;}.b1397122-cbc6-4ca6-a1f4-381639fa92eb{fill:#c0bff6;}.a5e1afc9-ab14-419d-b387-a8de3d029bca{fill:#5a58ba;}.b30d45cb-44c5-4072-a2d4-b8c85161736d{fill:#aeadfb;}.b0f698d2-d91d-422a-80b7-a630f8900b4e{fill:#9391f9;}.ae6df3b7-b981-4f11-aee7-d0a9529a9f81{fill:#8a2ae3;}.f01344c1-322d-461e-bd3a-3a1c89f50213{fill:#fdd762;}.b535af86-c9bd-4dae-8fb5-da2df1926f79{fill:#e8a200;}.a7a8786c-1a4e-40b1-8518-0da0b63534b2{fill:#fff;}.e8092433-85ae-4b15-99af-e5cd90ac8611{fill:#f9bf30;}.a450fbd5-e8a9-40da-9754-3f792264a79b{fill:#f2b42a;}.a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba{fill:#262b78;}.b0cce670-d1e6-4d78-a3a0-a28be5366710{fill:#ffc2b0;}.b6531369-600b-4a78-b4a7-ac54ba3c1df2{fill:#ea9c92;}.a58fd634-7b52-4e14-afc6-404e8ba5ac76{fill:#31739f;}.aafe2fb1-1b2e-4152-93b6-94306ed10890{fill:#3176a1;}.ecc80f21-171b-4637-8289-6f1fe60fc07b{fill:#fcdcd4;}
}
`;

class Triumph extends React.Component {
  render() {
    return (
    <React.Fragment>
      <Background>
      <svg 
        xmlns='http://www.w3.org/2000/svg' 
        viewBox='0 0 161.43 200.58' 
        width={this.props.widthWindow * 0.30}
      >
    <defs />
    <g id='a8032fcc-a7ca-40f7-9f3e-348a448536d2' data-name='Escritorio 2D'>
        <path className='f4398ea3-4d89-47db-8e73-daf2eb73c170' d='M73,134.8l2.28-19.64a148.32,148.32,0,0,1,39.12,7.46L96,139.17A96.11,96.11,0,0,0,73,134.8Z'
        transform='translate(0 .58)' />
        <path className='b358e0c4-dfcc-4521-82ff-b39d018385c6' d='M73,127.43l2.28-19.65a149.14,149.14,0,0,1,39.12,7.46L96,131.8A95.62,95.62,0,0,0,73,127.43Z'
        transform='translate(0 .58)' />
        <polygon className='b358e0c4-dfcc-4521-82ff-b39d018385c6' points='73.03 86.36 95.5 87.37 95.96 90.74 95.96 132.38 78.47 125.76 73.03 128 73.03 86.36'
        />
        <polygon className='aab21c75-b835-4e46-8536-8364c4c6c5cb' points='114.43 74.18 114.43 115.82 95.96 132.38 95.96 90.74 114.43 74.18'
        />
        <path className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' d='M73,85.79l2.28-19.65a149.14,149.14,0,0,1,39.12,7.46L96,90.16A95.62,95.62,0,0,0,73,85.79Z'
        transform='translate(0 .58)' />
        <path className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' d='M96,91.05A96.12,96.12,0,0,0,73.1,86.68l-.07.61A96.12,96.12,0,0,1,96,91.67l18.47-16.56V74.5Z'
        transform='translate(0 .58)' />
        <path className='f4398ea3-4d89-47db-8e73-daf2eb73c170' d='M66.05,134.41c-15-.09-30,2.94-42,9.09L0,129.59c19.18-10.3,43.87-15.24,68.34-14.81Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M66.05,127.15c-15-.09-30,2.94-42,9.09L0,122.33c19.18-10.3,43.87-15.24,68.34-14.81Z'
        transform='translate(0 .58)' />
        <polygon className='a95b629d-2f32-4616-ad96-e93efa9524dc' points='66.06 127.73 66.06 113.36 68.34 93.72 68.34 108.1 66.06 127.73'
        />
        <polygon className='a95b629d-2f32-4616-ad96-e93efa9524dc' points='24.09 122.45 50.93 98.32 66.06 113.36 66.06 127.73 27.02 127.48 24.09 136.82 24.09 122.45'
        />
        <polygon className='a95b629d-2f32-4616-ad96-e93efa9524dc' points='0 108.54 0 122.91 24.09 136.82 24.09 122.45 0 108.54'
        />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M66.05,112.78c-15-.09-30,2.93-42,9.09L0,108C19.18,97.65,43.87,92.71,68.34,93.15Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M66.05,113.56c-15-.09-30,2.94-42,9.09L0,108.74v.42l24.09,13.91c11.92-6.16,27-9.18,42-9.09l2.29-19.63h0Z'
        transform='translate(0 .58)' />
        <path className='f4398ea3-4d89-47db-8e73-daf2eb73c170' d='M118.18,188.75c14.73-13.79,11.75-32.1-9-44.06a65.08,65.08,0,0,0-7.39-3.65l18.46-16.54a103.73,103.73,0,0,1,13,6.28c32.56,18.8,36.83,47.74,12.85,69.22Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M118.18,181.69c14.73-13.79,11.75-32.1-9-44.06a65.08,65.08,0,0,0-7.39-3.65l18.46-16.54a103.73,103.73,0,0,1,13,6.28c32.56,18.8,36.83,47.74,12.85,69.22Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M101.83,108.39V134l16.35,6.07s21.22-11.58,21.22-11.85-3.58-24.81-6.2-25.91S101.83,108.39,101.83,108.39Z'
        transform='translate(0 .58)' />
        <polygon className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' points='118.18 182.27 118.18 156.68 146.16 167.93 146.16 193.52 118.18 182.27'
        />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M146.16,151.3v41.64s14.61-12.31,15.27-28.65l0-26.73-17-2.46Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M118.18,156.11c14.73-13.8,11.75-32.11-9-44.07a65.08,65.08,0,0,0-7.39-3.65l18.46-16.54a103.73,103.73,0,0,1,13,6.28c32.56,18.81,36.83,47.75,12.85,69.22Z'
        transform='translate(0 .58)' />
        <path className='f19d5a9c-6775-424b-b38b-1dd07acaf58d' d='M146.16,168.34l-28-11.25v.66l28,11.25c10.26-9.19,15.34-19.74,15.26-30.28C161.29,149,156.2,159.34,146.16,168.34Z'
        transform='translate(0 .58)' />
        <path className='f19d5a9c-6775-424b-b38b-1dd07acaf58d' d='M127.36,138.9c.26-9.35-5.78-18.74-18.14-25.88a75.65,75.65,0,0,0-7.39-3.63V110a65.08,65.08,0,0,1,7.39,3.65C121.28,120.65,127.32,129.77,127.36,138.9Z'
        transform='translate(0 .58)' />
        <ellipse className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' cx='39.33' cy='106.39'
        rx='13.52' ry='7.8' />
        <path className='a5e1afc9-ab14-419d-b387-a8de3d029bca' d='M49.61,101.85H46.67c-.21-.14-.43-.28-.67-.42-4.81-2.78-12.61-2.78-17.42,0a7.74,7.74,0,0,0-.85.54L25,102v4.47h0c0,1.82,1.2,3.64,3.61,5,4.81,2.78,12.61,2.78,17.42,0,2.4-1.39,3.61-3.21,3.61-5h0Z'
        transform='translate(0 .58)' />
        <path className='b30d45cb-44c5-4072-a2d4-b8c85161736d' d='M36.87,99.36a17.58,17.58,0,0,0-8.29,2.08,7.74,7.74,0,0,0-.85.54L25,102v4.47c0,1.82,1.2,3.64,3.61,5a17.58,17.58,0,0,0,8.29,2.08Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M26.66,102H25v4.47a5.05,5.05,0,0,0,1.69,3.6Z'
        transform='translate(0 .58)' />
        <ellipse className='b0f698d2-d91d-422a-80b7-a630f8900b4e' cx='37.29' cy='102.42'
        rx='12.32' ry='7.11' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M46,106.88c-4.81,2.77-12.61,2.77-17.42,0-2.29-1.32-3.49-3-3.6-4.76-.13,1.91,1.07,3.85,3.6,5.3,4.81,2.78,12.61,2.78,17.42,0,2.53-1.46,3.72-3.4,3.6-5.31C49.48,103.85,48.28,105.56,46,106.88Z'
        transform='translate(0 .58)' />
        <path className='a5e1afc9-ab14-419d-b387-a8de3d029bca' d='M46.23,99.89l-2.13,0-.49-.31A13.94,13.94,0,0,0,31,99.6a7.32,7.32,0,0,0-.62.39h-2v1.54h0c0,1.32.87,2.64,2.62,3.65a13.94,13.94,0,0,0,12.64,0c1.74-1,2.62-2.33,2.62-3.65h0Z'
        transform='translate(0 .58)' />
        <path className='b30d45cb-44c5-4072-a2d4-b8c85161736d' d='M37,98.09a12.66,12.66,0,0,0-6,1.51,7.32,7.32,0,0,0-.62.39h-2v1.54c0,1.32.87,2.64,2.62,3.65a12.66,12.66,0,0,0,6,1.51Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M29.58,100H28.35v1.54a3.69,3.69,0,0,0,1.23,2.62Z'
        transform='translate(0 .58)' />
        <path className='ae6df3b7-b981-4f11-aee7-d0a9529a9f81' d='M43.61,103.54a13.94,13.94,0,0,1-12.64,0c-3.49-2-3.49-5.28,0-7.3a14,14,0,0,1,12.64,0C47.1,98.26,47.1,101.53,43.61,103.54Z'
        transform='translate(0 .58)' />
        <path className='f01344c1-322d-461e-bd3a-3a1c89f50213' d='M21,84.33a8.59,8.59,0,0,1-7.32-4.12L9,72.44A3,3,0,0,1,11.49,68h9.25v2.41H11.49a.54.54,0,0,0-.48.28.53.53,0,0,0,0,.55L15.7,79a6.12,6.12,0,0,0,5.34,3l8.06-.12,0,2.41-8.06.12Z'
        transform='translate(0 .58)' />
        <path className='f01344c1-322d-461e-bd3a-3a1c89f50213' d='M53.62,84.33h-.13l-8.05-.12,0-2.41,8.06.12a6.14,6.14,0,0,0,5.35-3l4.68-7.77a.53.53,0,0,0,0-.55.54.54,0,0,0-.48-.28H53.83V68h9.26a3,3,0,0,1,2.53,4.49l-4.68,7.77A8.59,8.59,0,0,1,53.62,84.33Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M61.11,75.25,58.88,79a6.14,6.14,0,0,1-5.35,3l-8.06-.12,0,2.41,8.05.12h.13a8.57,8.57,0,0,0,6.82-3.4A9.82,9.82,0,0,0,61.11,75.25Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M32.5,95.68a10.55,10.55,0,0,0,9.57,0c2.65-1.52,2.65-4,0-5.53a10.61,10.61,0,0,0-9.57,0C29.86,91.68,29.86,94.16,32.5,95.68Z'
        transform='translate(0 .58)' />
        <path className='e8092433-85ae-4b15-99af-e5cd90ac8611' d='M29.38,96.83c1.43-1.06,1.15-2.1,1.15-2.1L31.61,96A14.1,14.1,0,0,1,43,96L44,94.73s-.27,1,1.15,2.1a3.28,3.28,0,0,1,1,3.13h0c0,1.31-.86,2.62-2.59,3.62a13.85,13.85,0,0,1-12.54,0c-1.73-1-2.6-2.31-2.6-3.62C28.42,100,28,97.89,29.38,96.83Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M42.24,97.14s-2.56-1.52-2.56-1.84a.57.57,0,0,1,.14-.24A12,12,0,0,1,43,96L44,94.73s-.27,1,1.15,2.1a3.28,3.28,0,0,1,1,3.13h0a3.4,3.4,0,0,1-.78,2.1c0-.17.3-2.31-1.31-3S42.24,97.14,42.24,97.14Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M32.5,97.24a10.61,10.61,0,0,0,9.57,0c2.65-1.53,2.65-4,0-5.53a10.55,10.55,0,0,0-9.57,0C29.86,93.24,29.86,95.71,32.5,97.24Z'
        transform='translate(0 .58)' />
        <path className='e8092433-85ae-4b15-99af-e5cd90ac8611' d='M55,68.09c-.18,9.87-5.27,11.07-8,14.76-2.54,3.45-2.25,8.44-2.2,9.09V92h0a3.73,3.73,0,0,1-2.2,3.07,11.74,11.74,0,0,1-10.64,0,3.85,3.85,0,0,1-2.19-2.86h0V92h0s0-.05,0-.07c.05-.65.34-5.64-2.2-9.09-2.73-3.69-7.81-4.89-8-14.76Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M42.06,78.84s-.42,1.18-1.7,4.55-.68,11.91-.68,11.91l2.19.15a7.29,7.29,0,0,0,.74-.37A3.73,3.73,0,0,0,44.81,92h0v-.07c-.05-.65-.34-5.64,2.2-9.09,2.72-3.69,7.81-4.89,8-14.76H53.06Z'
        transform='translate(0 .58)' />
        <ellipse className='f01344c1-322d-461e-bd3a-3a1c89f50213' cx='37.29' cy='68.67'
        rx='17.71' ry='10.22' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M24.76,75.32c6.92,4,18.13,4,25.05,0,3.26-1.88,5-4.31,5.17-6.78.21,2.77-1.52,5.57-5.17,7.68-6.92,4-18.13,4-25,0-3.65-2.11-5.37-4.91-5.16-7.68C19.78,71,21.5,73.44,24.76,75.32Z'
        transform='translate(0 .58)' />
        <ellipse className='a450fbd5-e8a9-40da-9754-3f792264a79b' cx='37.29' cy='68.67'
        rx='14.86' ry='8.58' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M26.78,74.16a20.46,20.46,0,0,0,8.67,2.44,2.06,2.06,0,0,0,.44-2C34.35,70,35,60.38,35,60.38l-2.8-.33a18.18,18.18,0,0,0-5.37,2C21,65.38,21,70.81,26.78,74.16Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M47.8,62.79c-5.81-3.35-15.22-3.35-21,0-2.74,1.58-4.18,3.62-4.34,5.69-.18-2.33,1.27-4.68,4.34-6.45,5.8-3.35,15.21-3.36,21,0,3.07,1.77,4.51,4.12,4.33,6.44C52,66.41,50.53,64.37,47.8,62.79Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M44.56,75.58a24.71,24.71,0,0,1-14.55,0,5.66,5.66,0,0,1,.48-.31,15.06,15.06,0,0,1,13.59,0A5.66,5.66,0,0,1,44.56,75.58Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M29.77,91.94c0-.08,0-.22,0-.41A3.89,3.89,0,0,0,32,94.32a11.8,11.8,0,0,0,10.64,0,4,4,0,0,0,2.18-2.73,2.61,2.61,0,0,0,0,.35V92h0a3.73,3.73,0,0,1-2.2,3.07,11.74,11.74,0,0,1-10.64,0,3.85,3.85,0,0,1-2.19-2.86h0V92h0S29.77,92,29.77,91.94Z'
        transform='translate(0 .58)' />
        <ellipse className='aab21c75-b835-4e46-8536-8364c4c6c5cb' cx='90.53' cy='78.28'
        rx='8.44' ry='4.87' />
        <ellipse className='a5e1afc9-ab14-419d-b387-a8de3d029bca' cx='129.67'
        cy='119.44' rx='7.2' ry='12.04' transform='rotate(-86 129.486 119.412)'
        />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M95.86,11.74s-.25,5-.63,8.36-8.47-2.58-8.47-2.58V13.69Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M91.55,73.93a10.11,10.11,0,0,1-4.32,3.49,4.92,4.92,0,0,1-1.92.27.65.65,0,0,1-.52-.85,8,8,0,0,1,4-4.43C92.37,70.65,91.55,73.93,91.55,73.93Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M92.25,74.91a17.6,17.6,0,0,0-1,3.4,5.6,5.6,0,0,0,.8,3,.88.88,0,0,0,1.36.23,5,5,0,0,0,1.7-3.25c.24-2.61,0-3.07,0-3.07Z'
        transform='translate(0 .58)' />
        <path className='b0f698d2-d91d-422a-80b7-a630f8900b4e' d='M97.3,43.33a50.33,50.33,0,0,1,0,6.94C97,53.92,96,71,95.86,75.07c0,0,.09,1.24-2.31,1s-2.21-1.65-2.21-1.65-3.38,0-3.48-2S84.4,48.59,84.4,40.76Z'
        transform='translate(0 .58)' />
        <polygon className='b0cce670-d1e6-4d78-a3a0-a28be5366710' points='85.56 26.9 83.61 38.8 81.84 38.77 82.01 27.86 85.56 26.9'
        />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M86.38,15.78s-2.63-.07-3.54,3.73-1.63,8.55-.84,8.94a3.46,3.46,0,0,0,3.9-.2Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M85.59,15.94A2.16,2.16,0,0,1,87,15.86c2.24.65,10.65,3.11,12,4.3,1.6,1.37-2.4,9.07-2.35,11.59s.35,5.5.57,8.24.52,4.25-1.72,5.42a8.83,8.83,0,0,1-8.94-1c-2.87-2.18-2.35-3.68-2.23-5.27s1.1-8.44,1-10.39a7.31,7.31,0,0,1-1.76-2c-.75-1.28.58-3.62,1.13-5.15s-.31-3.27-.14-4.52A1.39,1.39,0,0,1,85.59,15.94Z'
        transform='translate(0 .58)' />
        <ellipse className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' cx='91.44' cy='18.97'
        rx='2.53' ry='3.19' transform='rotate(-82.7 91.773 19.258)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M89.18,18.68a2.34,2.34,0,0,0,4.52.58,2.34,2.34,0,0,0-4.52-.58Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M90.42,15.43s1.52-1.25,1.82-.62a19.41,19.41,0,0,1,.55,3.35l-3,.81.46-2.75S89.62,15.71,90.42,15.43Z'
        transform='translate(0 .58)' />
        <circle className='b0cce670-d1e6-4d78-a3a0-a28be5366710' cx='90.21' cy='12.19'
        r='4.5' transform='rotate(-27.89 91.372 12.47)' />
        <path className='b6531369-600b-4a78-b4a7-ac54ba3c1df2' d='M92.45,15.6c0,.14,0,.29.08.45-.3,1.56-2.65,2.25-2.65,2.25l.35-2.08s-.29-.24-.21-.48C91,15.44,92.12,15.18,92.45,15.6Z'
        transform='translate(0 .58)' />
        <circle className='b0cce670-d1e6-4d78-a3a0-a28be5366710' cx='90.21' cy='12.19'
        r='4.5' transform='rotate(-27.89 91.372 12.47)' />
        <polygon className='b0cce670-d1e6-4d78-a3a0-a28be5366710' points='101.3 31.83 100.8 43.88 99.04 44.07 97.89 33.21 101.3 31.83'
        />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M95.51,30.25C96.1,27.67,97.25,22,97.25,22l.49,5.66a15.51,15.51,0,0,0-1,4.11c0,1.48.15,3.12.28,4.77h0S94.93,32.82,95.51,30.25Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M98.71,19.91s1.41.54,2.21,4.87,1.39,8.66,0,9.22-2.61,1-3-.18-.67-6.73-.72-8.74S97,20.31,98.71,19.91Z'
        transform='translate(0 .58)' />
        <path className='a5e1afc9-ab14-419d-b387-a8de3d029bca' d='M91.34,74.38a6,6,0,0,1-1-.1h0l-.66-26.5Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M93.89,60.07l.94-1.23,9.86-5.7a1.41,1.41,0,0,0,.57-1.1V44l.57-.74-.53-.31h0a.35.35,0,0,0-.39,0L93.72,49.41a1.38,1.38,0,0,0-.57,1.1V59.3a.5.5,0,0,0,.21.47h0Z'
        transform='translate(0 .58)' />
        <path className='a58fd634-7b52-4e14-afc6-404e8ba5ac76' d='M100.71,55.44l.7-.41,3.85-6.68V44l.57-.74-.53-.31h0a.35.35,0,0,0-.39,0L93.72,49.41a1.28,1.28,0,0,0-.42.49Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M105.57,43.56l.25-.33-.53-.31h0a.35.35,0,0,0-.39,0l-.2.12Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M93.62,49.48a1.47,1.47,0,0,0-.47.94l1.1.63.52-.9Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M94.26,49.72l11.17-6.47c.32-.18.57,0,.57.43v8.79a1.35,1.35,0,0,1-.57,1.1L94.27,60c-.32.18-.58,0-.58-.43v-8.8A1.36,1.36,0,0,1,94.26,49.72Z'
        transform='translate(0 .58)' />
        <path className='aafe2fb1-1b2e-4152-93b6-94306ed10890' d='M101.19,51.47a1.87,1.87,0,0,1-.85,1.47c-.47.27-.85.05-.85-.49a1.87,1.87,0,0,1,.85-1.47C100.81,50.71,101.19,50.93,101.19,51.47Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M100.79,43.21a27.47,27.47,0,0,1,.39,3.71c-.07.9-.55,2.39-.92,2.36s0-3.63-.65-3.27-.71,1-1,.76.5-3.29.29-4.62S100.79,43.21,100.79,43.21Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M81.84,38.2a27.47,27.47,0,0,0-.39,3.71c.07.9.55,2.39.92,2.36s0-3.63.65-3.27.71,1,1,.76-.5-3.29-.29-4.62S81.84,38.2,81.84,38.2Z'
        transform='translate(0 .58)' />
        <path className='b6531369-600b-4a78-b4a7-ac54ba3c1df2' d='M88.48,12.58c-2.26.8-2.7.31-2.73.29s0-.28,0-.42A4.58,4.58,0,0,1,85.86,11l5.86-.79A6.39,6.39,0,0,1,88.48,12.58Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M85.55,8a6.68,6.68,0,0,1,5.89-2.18c2.16.34,4,1.8,4.37,5.15a7.71,7.71,0,0,1-2.08,6.12c-.08,0-.17-.07-.25-.12-.86-.57-1-.88-1-.88l-.19-1.71a.48.48,0,0,1,.43-.48.86.86,0,0,0,.63-.38,1,1,0,0,0-.72-1.69.75.75,0,0,1-.79-.57.41.41,0,0,0-.53-.27c-1.13.41-3.9,1.28-5.68.78C83.39,11.08,85,8.42,85.55,8Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M88.11,14.61l-1.37-.38a3.27,3.27,0,0,0,1.61,1.3c1,.27,1.11-.55,1.11-.55Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M123.74,141.41a2,2,0,0,0-2.29.35,11,11,0,0,0-2.16,5.29c0,1.7,1.35,1.59,2.17,1.18s3.23-3.89,3.37-4.57a2.37,2.37,0,0,0-.4-1.79C124.2,141.69,123.74,141.41,123.74,141.41Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M124.05,141.07l.07,1.47a.86.86,0,0,1-.42.77,1.63,1.63,0,0,1-1.09.17c-1.07-.17-1.17-.48-1.25-1.95S124.05,141.07,124.05,141.07Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M118.54,138.59a1.93,1.93,0,0,0-2.29.35,10.91,10.91,0,0,0-2.16,5.28c0,1.7,1.35,1.59,2.17,1.18s3.23-3.89,3.38-4.57a2.41,2.41,0,0,0-.41-1.79C119,138.86,118.54,138.59,118.54,138.59Z'
        transform='translate(0 .58)' />
        <path className='b535af86-c9bd-4dae-8fb5-da2df1926f79' d='M118.85,138.24l.07,1.47a.84.84,0,0,1-.42.77,1.63,1.63,0,0,1-1.09.17c-1.07-.17-1.17-.48-1.24-2S118.85,138.24,118.85,138.24Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M131.53,111.23c-.43-6.84-7.41,1-7.41,1l-4.78,4.68L115,121.65a4.26,4.26,0,0,0-1.07,3.41l1.74,13.27a1.32,1.32,0,0,0,1.31,1.13,4.12,4.12,0,0,0,.65-.06,1.61,1.61,0,0,0,1.43-1.72l-.31-12.4,9-5.45S132,118.07,131.53,111.23Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M136.72,113.84c-.42-6.84-7.4,1-7.4,1l-4.78,4.69-4.31,4.73a4.25,4.25,0,0,0-1.07,3.41l1.74,13.26a1.3,1.3,0,0,0,1.31,1.13,3.81,3.81,0,0,0,.64-.05,1.61,1.61,0,0,0,1.44-1.72L124,127.88l9.05-5.45S137.14,120.67,136.72,113.84Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M132.59,91.88c5.3,2.2,5.19,5.33,5.19,5.33a176.35,176.35,0,0,0-.68,19.14c.15,3.21-17,3.45-14.47-5.1,3-10.08,1.89-18.24,3.3-19.47S131.22,91.31,132.59,91.88Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M134.29,94.66c-.27,1.07-1.68,1.64-3.14,1.26s-2.42-1.55-2.15-2.63,1.69-1.64,3.15-1.26S134.57,93.58,134.29,94.66Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M132.05,96.29a4.09,4.09,0,0,1-1-.12A3.56,3.56,0,0,1,129.23,95a1.92,1.92,0,0,1,.81-3.15,3.54,3.54,0,0,1,2.17-.1c1.6.41,2.64,1.73,2.33,2.94a2,2,0,0,1-1.28,1.35A3.21,3.21,0,0,1,132.05,96.29Zm-.8-4.13a2.8,2.8,0,0,0-1,.19,1.42,1.42,0,0,0-.61,2.36,3.2,3.2,0,0,0,3.45.89,1.53,1.53,0,0,0,1-1h0c.24-.93-.64-2-2-2.32A3.59,3.59,0,0,0,131.25,92.16Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M130.47,91.64a14.54,14.54,0,0,1-.05,1.89c0,.53,1.82,1,2.3.55s.28-1,.4-2.77S130.47,91.64,130.47,91.64Z'
        transform='translate(0 .58)' />
        <path className='b6531369-600b-4a78-b4a7-ac54ba3c1df2' d='M133.12,91.31a3.74,3.74,0,0,1-2.7,2.3l0-1.51Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M134.54,87.07a4.08,4.08,0,1,1-5-2.92A4.08,4.08,0,0,1,134.54,87.07Z'
        transform='translate(0 .58)' />
        <path className='b6531369-600b-4a78-b4a7-ac54ba3c1df2' d='M131.53,88.25a12.67,12.67,0,0,1-3.8,1c-1.55,0-1.21-1.29-1.21-1.29l4.61-.67Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M127.27,88.5a7.31,7.31,0,0,1,3.67-.49,1.84,1.84,0,0,1,1,.62.71.71,0,0,0,.32.23c.3.07.8-1.2,1.39-1.22s1,.39.5,1.16-.32,1.65-.1,1.77,2.59-1.69.51-5.82-7.39-1.91-8.22-1.07c0,0-.87.76-1.57.08C124.8,83.76,124,89.69,127.27,88.5Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M123.34,90.94l-2.85-1.23a1.28,1.28,0,0,1-.69-.75L117,80.81l-1.31.23L118,91.18a1.69,1.69,0,0,0,.83,1.12L123.53,95Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M153,92.27l.65-.3a5.38,5.38,0,0,0,1.5-1l.45-.45a.88.88,0,0,0,.2-1h0a.49.49,0,0,0-.67-.24l-1.36.69s.21-.61.11-.77-1.16,1-1.65,1.84S153,92.27,153,92.27Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M141.61,95.53l2.44-.28a4.12,4.12,0,0,0,1.63-.53L152.21,91l.77,1.24-6.68,5.15a2.8,2.8,0,0,1-.67.39l-4,1.7Z'
        transform='translate(0 .58)' />
        <path className='b0cce670-d1e6-4d78-a3a0-a28be5366710' d='M115.68,81l-.24-.29a2.22,2.22,0,0,1-.54-1.16l-.17-1.19a1,1,0,0,1,.39-1l0,0a.61.61,0,0,1,.9.29l.59,1.42s-.19-.74,0-.82.5.85.31,1.36a2.06,2.06,0,0,0,0,1.15Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M129,90.9l-1-.29a2.57,2.57,0,0,0,1.24,1,.61.61,0,0,0,.85-.42Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M127.4,91.19s-4.76-.94-6.21-1.38-2.06,3.55-2,4.56,5.05,2.14,5.64,3.88S127.4,91.19,127.4,91.19Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M134.67,92.94a37.21,37.21,0,0,0,9.3,2.22.62.62,0,0,1,.51.42,6.78,6.78,0,0,1,.31,3.49,1.27,1.27,0,0,1-1.27,1c-1.82,0-5.74.17-6.38,1.87C136.31,104.18,134.67,92.94,134.67,92.94Z'
        transform='translate(0 .58)' />
        <rect className='b0f698d2-d91d-422a-80b7-a630f8900b4e' x='72' y='8.57'
        width='1.03' height='2.99' />
        <rect className='b0f698d2-d91d-422a-80b7-a630f8900b4e' x='73.03' y='10.36'
        width='1.03' height='2.99' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='117.34' y='35.53'
        width='1.33' height='3.85' transform='rotate(-125.06 118.151 37.751)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='118.47' y='33.12'
        width='1.33' height='3.85' transform='rotate(-125.06 119.275 35.344)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='119.59' y='30.71'
        width='1.33' height='3.85' transform='rotate(-125.06 120.404 32.934)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='120.72' y='28.3'
        width='1.33' height='3.85' transform='rotate(-125.06 121.526 30.522)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='37.7' y='36.26'
        width='1.03' height='2.99' transform='rotate(75.63 37.843 38.04)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='36.22' y='37.7'
        width='1.03' height='2.99' transform='rotate(75.62 36.365 39.494)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='34.74' y='39.15'
        width='1.03' height='2.99' transform='rotate(75.62 34.881 40.934)' />
        <rect className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' x='33.26' y='40.59'
        width='1.03' height='2.99' transform='rotate(75.63 33.409 42.368)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='136.91' y='64.9'
        width='1.03' height='2.99' transform='rotate(155.54 137.365 66.676)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='135.23' y='63.7'
        width='1.03' height='2.99' transform='rotate(155.53 135.687 65.483)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='133.56' y='62.49'
        width='1.03' height='2.99' transform='rotate(155.53 134.005 64.28)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='131.88' y='61.29'
        width='1.03' height='2.99' transform='rotate(155.53 132.329 63.08)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='56.24' y='20.17'
        width='1.77' height='5.15' transform='rotate(55.36 56.575 23.024)' />
        <rect className='b0f698d2-d91d-422a-80b7-a630f8900b4e' x='101.23' y='-0.84'
        width='1.03' height='2.99' transform='rotate(122.09 101.592 .94)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='116.45' y='52.62'
        width='1.03' height='1.12' transform='rotate(55.36 116.41 53.467)' />
        <rect className='a95b629d-2f32-4616-ad96-e93efa9524dc' x='129.86' y='20.51'
        width='1.33' height='1.44' transform='rotate(55.36 129.962 21.514)' />
        <rect className='b0f698d2-d91d-422a-80b7-a630f8900b4e' x='123.46' y='18.02'
        width='1.33' height='1.44' transform='rotate(55.36 123.573 19.02)' />
        <rect className='b0f698d2-d91d-422a-80b7-a630f8900b4e' x='74.19' y='33.81'
        width='1.03' height='1.12' transform='rotate(55.36 74.153 34.66)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M107.87,18.74l-.7-1.35a1.83,1.83,0,0,0,.83-1.05,2.59,2.59,0,0,0-.49-2,4.55,4.55,0,0,1,.35-5.77l1,1.09-.52-.55.53.55a3,3,0,0,0-.12,3.87,4,4,0,0,1,.66,3.22A3.31,3.31,0,0,1,107.87,18.74Z'
        transform='translate(0 .58)' />
        <path className='b0f698d2-d91d-422a-80b7-a630f8900b4e' d='M126.63,47.57l.6-1a1.47,1.47,0,0,0,1,.1,2,2,0,0,0,1.1-1.15,3.54,3.54,0,0,1,3.93-2.16L133,44.5l.14-.58-.14.58A2.32,2.32,0,0,0,130.43,46a3.09,3.09,0,0,1-1.83,1.77A2.64,2.64,0,0,1,126.63,47.57Z'
        transform='translate(0 .58)' />
        <path className='b0f698d2-d91d-422a-80b7-a630f8900b4e' d='M48.57,50.26l.37-2a2.47,2.47,0,0,0,1.74-.43,3.46,3.46,0,0,0,1.12-2.5,6.08,6.08,0,0,1,5.11-5.8l.21,2-.1-1,.11,1c-.3,0-3,.45-3.32,4a5.3,5.3,0,0,1-1.93,3.93A4.45,4.45,0,0,1,48.57,50.26Z'
        transform='translate(0 .58)' />
        <path className='f7f688c2-c0b4-4a08-b0d0-de7c0d2352af' d='M73.39,47.3l1.17.17a1.44,1.44,0,0,0,.29,1,2,2,0,0,0,1.48.58,3.55,3.55,0,0,1,3.5,2.82L78.66,52l.59-.09-.59.1a2.31,2.31,0,0,0-2.4-1.82,3.13,3.13,0,0,1-2.33-1A2.63,2.63,0,0,1,73.39,47.3Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M57.65,165.46c5.72,3.31,15,3.31,20.72,0s5.72-8.66,0-12-15-3.3-20.72,0S51.93,162.16,57.65,165.46Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M61,155.86a2.25,2.25,0,0,0-1.28,2.26,3.86,3.86,0,0,0,.19,1.1,1.86,1.86,0,0,0,2.5.77c1.37-.54,4.42-3.41,4.53-5.29C67.18,151.22,63.19,154.93,61,155.86Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M69.09,161.62a10.11,10.11,0,0,0-.17,2.38,2.58,2.58,0,0,0,2.53,1c1.41-.17,5.47-1.57,6.38-4.25,1.4-4.15-7.85.32-7.85.32Z'
        transform='translate(0 .58)' />
        <path className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' d='M73.06,129.5l.31,31.37A2.18,2.18,0,0,1,71.66,163l-.38.05a1.93,1.93,0,0,1-1.86-.77,2.35,2.35,0,0,1-.42-1.27l-.4-13.39-2-12-2.77,21.19a1.82,1.82,0,0,1-.76,1.4,2.15,2.15,0,0,1-1.26.34,2.31,2.31,0,0,1-1.67-.83,1.63,1.63,0,0,1-.4-1.13l1.09-12.75.41-16.26Z'
        transform='translate(0 .58)' />
        <path className='ecc80f21-171b-4637-8289-6f1fe60fc07b' d='M69,101.43l-.5,2.86a1.3,1.3,0,0,0,1.07,1.24c1.09.27,1.43-.43,1.43-.43l.49-3.22Z'
        transform='translate(0 .58)' />
        <ellipse className='ecc80f21-171b-4637-8289-6f1fe60fc07b' cx='71.21' cy='98.79'
        rx='4.65' ry='4.14' transform='rotate(-63.44 71.666 99.078)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M69.13,104.34c-.08,0-4.62-3-6.3.1-2.74,5-2.58,21.07-1.72,23.15a9.75,9.75,0,0,0,4.2,4.15,8.39,8.39,0,0,0,4.08,1.15c1.42,0,4.14-1.25,4-3.1a141.48,141.48,0,0,1,1-22.72S73.56,106.13,69.13,104.34Z'
        transform='translate(0 .58)' />
        <path className='a5e1afc9-ab14-419d-b387-a8de3d029bca' d='M66.6,135.69l-2.77,21.19a1.82,1.82,0,0,1-.76,1.4l-.23.13c.57-.43,2.25-18.22,2.56-20.19A3.56,3.56,0,0,1,66.6,135.69Z'
        transform='translate(0 .58)' />
        <polygon className='ecc80f21-171b-4637-8289-6f1fe60fc07b' points='83.66 105.86 86.13 97.77 84.82 97.23 81.91 103.57 83.66 105.86'
        />
        <polygon className='ecc80f21-171b-4637-8289-6f1fe60fc07b' points='58.77 99.48 60.62 91.06 62.09 91.08 61.54 98.06 58.77 99.48'
        />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M74.43,107.07s4.44.89,7.26-4.28a.7.7,0,0,1,1.17-.12,3.62,3.62,0,0,1,.74,2.88c-1.37,5-8.83,6.17-10.17,9.09S74.43,107.07,74.43,107.07Z'
        transform='translate(0 .58)' />
        <path className='a9b9ea5a-3c0f-44aa-b5b1-5ae35b3adfba' d='M66.24,103.29s-3.51.27-4-2.77a23,23,0,0,1-.36-3,.61.61,0,0,0-.69-.6c-.84.11-2.14.36-2.32.92-.26.84-.76,5,2.71,11.31C63.85,113.26,66.24,103.29,66.24,103.29Z'
        transform='translate(0 .58)' />
        <path className='ecc80f21-171b-4637-8289-6f1fe60fc07b' d='M60.61,90.51l0-1a2,2,0,0,1,.29-1l1-1.6.26-.32a.74.74,0,0,1,1-.17l.22.15a.91.91,0,0,1,.43.83l-.5,1.37.64-.12a.21.21,0,0,1,.26.18h0a.23.23,0,0,1-.14.25l-1,.42-.94,1.33Z'
        transform='translate(0 .58)' />
        <path className='ecc80f21-171b-4637-8289-6f1fe60fc07b' d='M86.16,97.24l.3-.92a2,2,0,0,0,.05-1l-.37-1.83L86,93.1a.74.74,0,0,0-.86-.49l-.26.06a.9.9,0,0,0-.68.64v1.46l-.55-.33a.22.22,0,0,0-.31.08h0a.22.22,0,0,0,0,.28l.84.75.49,1.77Z'
        transform='translate(0 .58)' />
        <path className='a95b629d-2f32-4616-ad96-e93efa9524dc' d='M72.35,113.19c-.4,1.84.73,16.16-3,19.7,1.42,0,4.14-1.25,4-3.1a114.32,114.32,0,0,1,.34-15.7c1.45-1.88,5.44-3.07,7.93-5.48C81.52,108.66,72.75,111.36,72.35,113.19Z'
        transform='translate(0 .58)' />
        <path className='a7a8786c-1a4e-40b1-8518-0da0b63534b2' d='M67.49,103.53l.63-.65A5.23,5.23,0,0,0,70,104.13a10.59,10.59,0,0,0,1.5.31l.4,1.12S68.59,105.32,67.49,103.53Z'
        transform='translate(0 .58)' />
        <path className='a9198a0c-b9d8-4f28-b807-6e551eafb26f' d='M72,100.27a.5.5,0,0,0,.46-.15c.22-.21.3-1.36.86-1.46.87-.15,2.63,1.21,3.35-1.71.37-1.52-.3-2-.16-2.9,0,0,0,.44-3.91-.51s-5.79,2.35-6.18,4,0,4.54,2.59,5.39a5.4,5.4,0,0,0,2.57-1.26c0-.21-.73-.32-.53-.88A.76.76,0,0,1,72,100.27Z'
        transform='translate(0 .58)' />
        <polygon className='a5e1afc9-ab14-419d-b387-a8de3d029bca' points='150.68 143.58 143.48 139.43 136.29 143.58 143.48 147.74 150.68 143.58'
        />
        <polygon className='b0f698d2-d91d-422a-80b7-a630f8900b4e' points='143.48 143.88 143.48 139.2 152.08 134.24 152.08 138.92 143.48 143.88'
        />
        <polygon className='b30d45cb-44c5-4072-a2d4-b8c85161736d' points='143.48 139.2 134.89 134.24 134.89 138.92 143.48 143.88 143.48 139.2'
        />
        <polygon className='b1397122-cbc6-4ca6-a1f4-381639fa92eb' points='152.08 134.24 143.48 129.28 134.89 134.24 143.48 139.2 152.08 134.24'
        />
    </g>
</svg>

</Background>
  </React.Fragment>
    )
  }
}
Triumph.propTypes = {
  widthWindow: PropTypes.string
};

export default Triumph;
