import React from "react";
import PropTypes from "prop-types";
import { db } from "../../firebase";
import { functions } from "../../firebase/firebase";

import {
  TextField,
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const INITIAL_STATE = {
  email: "",
  error: "",
  open: true,
  hasResult: false,
  resultText: {},
  dataInfo: {},
};

class AddNewClient extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...INITIAL_STATE };
  }

  redirect = (link) => {
    this.props.history.push(link);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  AddNewClient = () => {
    const email = this.state.email;
    //change te create first admin!! , addNewClientRole
    var addNewClientRole = functions.httpsCallable("addNewClientRole");
    addNewClientRole({
      email: email,
    })
      .then((result) => {
        console.log(result);
        this.setState({
          dataInfo: result.data,
          hasResult: true,
        });
        this.addToDDBB();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addToDDBB = async () => {
    let email = this.state.email;
    let users = await db.onceGetUsers();
    users.forEach((user) => {
      if (user.val().email === email) {
        db.createNewClient(
          user.key,
          user.val().name,
          user.val().lastname,
          user.val().email
        );
      }
    });
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.AddNewClient();
  };

  render() {
    const { email } = this.state;
    let dataInfo = this.state.dataInfo;
    console.log("dataInfo", dataInfo);
    return (
      <div>
        <Box pt={5}>
          <Typography gutterBottom component="h1" variant="h5">
            Crear Rol de Cliente:
          </Typography>
          <Box
            container
            spacing={12}
            onSubmit={this.onSubmit}
            noValidate
            autoComplete="off"
          >
            <Box>
              <TextField
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="email"
                onChange={this.onChange}
                value={email}
              />
            </Box>
          </Box>
          <Box pt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.onSubmit}
            >
              Crear Rol
            </Button>
          </Box>
          {this.state.hasResult && (
            <Dialog open={this.state.open}>
              {/* <DialogTitle>{"Rol de profesor otorgado!"}</DialogTitle> */}
              <DialogContent>
                {/* {dataInfo.message && (
                  <DialogContentText>
                    {dataInfo.message}
                    Recuerda que el usuario debe cerrar sesión y volver a
                    abrirla para obtener los beneficios de su nuevo rol.
                  </DialogContentText>
                )} 
                {dataInfo.errorInfo.message && (
                  <DialogContentText>
                    {dataInfo.errorInfo.message}
                    Recuerda que el usuario debe cerrar sesión y volver a
                    abrirla para obtener los beneficios de su nuevo rol.
                  </DialogContentText>
                )} */}
                <DialogContentText>
                  Éxito rol de cliente otorgado!, Recuerda que el usuario debe
                  cerrar sesión y volver a abrirla para obtener los beneficios
                  de su nuevo rol.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                  Ok!
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </div>
    );
  }
}

AddNewClient.propTypes = {
  history: PropTypes.object,
};

export default AddNewClient;
