import React from "react";
import PropTypes from "prop-types";
import { functions } from "../../firebase/firebase";
import {
  TextField,
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { db } from "../../firebase";

const INITIAL_STATE = {
  email: "",
  error: "",
  open: true,
  hasResult: false,
};

class RemoveClient extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...INITIAL_STATE };
  }

  redirect = (link) => {
    this.props.history.push(link);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  removeClientRole = () => {
    const email = this.state.email;
    var removeClientRole = functions.httpsCallable("removeClientRole");
    removeClientRole({
      email: email,
    })
      .then((result) => {
        console.log(result);
        this.setState({ hasResult: true });
        this.addToDDBB();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addToDDBB = async () => {
    let email = this.state.email;
    let users = await db.onceGetUsers();
    users.forEach((user) => {
      if (user.val().email === email) {
        db.removeClient(user.key);
      }
    });
  };
  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.removeClientRole();
  };

  render() {
    const { email } = this.state;
    return (
      <div>
        <Box pt={5}>
          <Typography gutterBottom component="h1" variant="h5">
            Eliminar Rol de Cliente:
          </Typography>
          <Box
            container
            spacing={12}
            onSubmit={this.onSubmit}
            noValidate
            autoComplete="off"
          >
            <Box>
              <TextField
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="email"
                onChange={this.onChange}
                value={email}
              />
            </Box>
          </Box>
          <Box pt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.onSubmit}
            >
              Eliminar
            </Button>
          </Box>
          {this.state.hasResult && (
            <Dialog open={this.state.open}>
              <DialogTitle>{"Éxito!"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Rol de Cliente eliminado!, desde refrescar la página para
                  comprobar que el profesor ya fue eliminado.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} autoFocus>
                  Ok!
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </div>
    );
  }
}

RemoveClient.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.string,
  email: PropTypes.string,
  history: PropTypes.string,
};

export default RemoveClient;
