import styled from "styled-components";

export const Background = styled.div`
  && {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    background: -moz-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: -webkit-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: -webkit-gradient(
      radial,
      -10.01% 21.12%,
      0,
      -10.01% 21.12%,
      1931.13,
      color-stop(0, rgba(66, 232, 224, 1)),
      color-stop(0.0346, rgba(63, 211, 213, 1)),
      color-stop(0.1192, rgba(57, 166, 188, 1)),
      color-stop(0.2097, rgba(51, 126, 166, 1)),
      color-stop(0.3048, rgba(46, 93, 147, 1)),
      color-stop(0.406, rgba(43, 65, 132, 1)),
      color-stop(0.5153, rgba(40, 44, 121, 1)),
      color-stop(0.6368, rgba(38, 29, 112, 1)),
      color-stop(0.7801, rgba(36, 21, 107, 1)),
      color-stop(1, rgba(36, 18, 106, 1))
    );
    background: -o-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: radial-gradiwhent(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
  }
`;

// Containers and blocks
// Centers everything and whitens text
export const WhiteCentered = styled.div`
  && {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    height: 80%;
  }
`;

// H1 size 70 pt
export const TitleH1 = styled.h1`
  font-size: 2.2em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
`;

// H1 size 70 pt
export const TitleH2 = styled.h2`
  font-size: 1.5em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
`;

// big title for user account
export const BigTitle = styled.h1`
  font-size: 3em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  max-height: 50%;
  max-width: 100%;
  margin: 5px 0;
`;
// H3 30 pt
export const TitleH3 = styled.h3`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
`;

// H4 25 pt
export const TitleH4 = styled.h4`
  font-size: 0.8em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
`;

// H5 14 pt
export const TitleH5 = styled.h5`
  font-size: 0.6em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
`;

// Bold

// H1 tamaño 70 pt
export const TitleNH1 = styled.h1`
  font-size: 2.2em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: bold;
`;
// H3 30 pt
export const TitleNH3 = styled.h3`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin: 2% 0% 4% 0%;
`;

// H3 30 pt sin margen //para el crear cuenta
// Only on create account
export const TitleNH2M = styled.h2`
  font-size: 2em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: normal;
`;

// H4 25 pt
export const TitleNH4 = styled.h4`
  font-size: 0.8em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: bold;
`;

// H5 14 pt
export const TitleNH5 = styled.h5`
  font-size: 0.6em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: bold;
`;

// Italic

// H1 tamaño 70 pt
export const TitleIH1 = styled.h1`
  font-size: 2.2em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-weight: normal;
`;
// H3 30 pt
export const TitleIH3 = styled.h3`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-weight: normal;
`;

// H4 25 pt
export const TitleIH4 = styled.h4`
  font-size: 0.8em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-weight: normal;
  margin: 2% 0% 2% 0%;
`;

// H5 14 pt
export const TitleIH5 = styled.h5`
  font-size: 0.6em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-style: italic;
`;

// Paragraphs

// 25 pt
export const Paragraph = styled.div`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: left;
  line-height: 2;
  padding: 1em;
  word-break: break-word;
  margin: 0%;
  white-space: pre-line;
`;

export const LightParagraph = styled.div`
  font-size: 0.7em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: lighter;
  text-align: left;
`;

// List
export const ListItem = styled.li`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  padding: 1em;
  margin: 0%;
`;

// Challenge info titles

// Major title
export const TitleD = styled.h3`
  font-size: 1em;
  color: #42b5c3;
  font-family: "Lato", sans-serif;
  /*text-decoration: underline;*/
`;

// Section title
export const TitleDH3 = styled.h3`
  font-size: 1em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  /*text-decoration: underline;*/
  margin: 2% 0% 0% 4%;
`;

//  Linking for breadcrumbs and navbar

// H2
export const Linking = styled.a`
  font-size: 16px;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
  cursor: pointer;
`;
// H2
export const LinkingN = styled.a`
  font-size: 1.3em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  cursor: pointer;
`;

// H2
export const LinkingNH3 = styled.a`
  font-size: 1.3em;
  color: #2b3e7f;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
  cursor: pointer;
`;

export const LinkForgotPw = styled.a`
  font-family: "Lato", sans-serif;
  font-size: 12px;
  cursor: pointer;
  color: #2b3e7f;
  margin: 1em 0;
`;

// White letter titles

// H4 25 pt
export const TitleWhiteNH1 = styled.h1`
  font-size: 2.2em;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
`;

export const TitleWhiteP3 = styled.p`
  font-size: 1.4em;
  color: #ffffff;
  font-family: "Lato", sans-serif;
`;

export const ParagraphWhite = styled.p`
  color: #ffffff;
  font-family: "Lato", sans-serif;
`;

export const PSignin = styled.p`
  color: #e2e2e5;
  font-size: 12px;
  max-width: 20em;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  margin: 0px;
`;

export const TitleWhiteH4 = styled.h4`
  font-size: 1.3em;
  color: ##ffffff;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
`;

export const MyHR = styled.hr`
  height: 0.25rem;
  width: 10%;
  margin: 0;
  background: tomato;
  border: none;
  transition: 0.5s ease-in-out;
  position: absolute;
`;

// H3 25 pt
export const LinkingNH3NavBar = styled.a`
  font-size: 0.9em;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
  margin-left: 7%;
  text-decoration: auto;
  cursor: pointer;
  &&:hover {
    color: #42b5c3; //CDCCF8  2b3e7f
  }
`;

// H5 14 pt
export const LinkingNH5NavBar = styled.a`
  font-size: 0.6em;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: Bold;
  margin-top: 3%;
  cursor: pointer;
  //position: fixed;
  &&:hover {
    color: #42b5c3;
  }
`;

// Errors
export const ErrorContainer = styled.div`
  && {
    text-align: center;
    color: #e6423a;
    margin-top: 1em;
  }
`;

// Buttons

export const SignOutButton = styled.div` && {
  order: 2;
  background: ${(props) =>
    props.primary
      ? "transparent"
      : "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);"};
  color: ${(props) =>
    props.primary
      ? "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);e"
      : "white"};
  font-size: 1em;
  text-align: center;
  font-weight: Bold;
  padding: 0.75em; //ancho y largo
  border: 3px solid '#CDCCF8' ;
  border-radius: 6px;
  outline: none !important;
  cursor: pointer;
  margin-top: 1em;
  align-self: flex-end;
  &&:hover {
    background: #2B3E7F;
    color: white //CDCCF8  2b3e7f
}  `;

export const SignUpButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.primary ? "transparent" : "#CDCCF8")};
  color: ${(props) => (props.primary ? "#CDCCF8" : "white")};
  font-size: 1em;
  font-weight: Bold;
  padding: 0.75em 1.3em; //ancho y largo
  border: 3px solid white;
  border-radius: 23px;
  outline: none !important;
  cursor: pointer;
  //margin-top: 1em;
  &&:hover {
    background: white;
    color: #cdccf8; //CDCCF8  2b3e7f
  }
`;

export const SaveButton = styled.div` && {
  order: 2;
  background: ${(props) =>
    props.primary
      ? "transparent"
      : "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);"};
  color: ${(props) =>
    props.primary
      ? "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);e"
      : "white"};
  font-size: 1em;
  text-align: center;
  font-weight: Bold;
  padding: 0.75em; //ancho y largo
  border: 3px solid '#CDCCF8' ;
  border-radius: 6px;
  outline: none !important;
  cursor: pointer;
  margin-top: 1em;
  align-self: flex-end;
  &&:hover {
    background: #2B3E7F;
    color: white //CDCCF8  2b3e7f
}  `;

export const MainButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) =>
    props.primary
      ? "transparent"
      : "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);"};
  color: ${(props) =>
    props.primary
      ? "-webkit-linear-gradient(0deg, rgba(233, 233, 253, 1) 0%, rgba(213, 213, 250, 1) 33.17%, rgba(198, 197, 247, 1) 70.88%, rgba(192, 191, 246, 1) 100%);e"
      : "white"};
  font-size: 1em;
  font-weight: Bold;
  padding: 0.75em 1.3em; //ancho y largo
  border: 3px solid "#CDCCF8";
  border-radius: 23px;
  outline: none !important;
  cursor: pointer;

  &&:hover {
    background: white;
    color: #cdccf8; //CDCCF8  2b3e7f
  }
`;

// Footer

export const Footer = styled.div`
   {
    height: 6em;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    //padding: 1rem;
    text-align: center;
    background: -moz-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: -webkit-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: -webkit-gradient(
      radial,
      -10.01% 21.12%,
      0,
      -10.01% 21.12%,
      1931.13,
      color-stop(0, rgba(66, 232, 224, 1)),
      color-stop(0.0346, rgba(63, 211, 213, 1)),
      color-stop(0.1192, rgba(57, 166, 188, 1)),
      color-stop(0.2097, rgba(51, 126, 166, 1)),
      color-stop(0.3048, rgba(46, 93, 147, 1)),
      color-stop(0.406, rgba(43, 65, 132, 1)),
      color-stop(0.5153, rgba(40, 44, 121, 1)),
      color-stop(0.6368, rgba(38, 29, 112, 1)),
      color-stop(0.7801, rgba(36, 21, 107, 1)),
      color-stop(1, rgba(36, 18, 106, 1))
    );
    background: -o-radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
    background: radial-gradient(
      -10.01% 21.12%,
      circle farthest-corner,
      rgba(66, 232, 224, 1) 0%,
      rgba(63, 211, 213, 1) 3.46%,
      rgba(57, 166, 188, 1) 11.92%,
      rgba(51, 126, 166, 1) 20.97%,
      rgba(46, 93, 147, 1) 30.48%,
      rgba(43, 65, 132, 1) 40.6%,
      rgba(40, 44, 121, 1) 51.53%,
      rgba(38, 29, 112, 1) 63.68%,
      rgba(36, 21, 107, 1) 78.01%,
      rgba(36, 18, 106, 1) 100%
    );
  }
`;
