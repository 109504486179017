export const challengeExplanation = {
  shortDescription: "Ingresa una breve descripción del desafío",
  prizeMoney: "Indica el premio total de la competencia",
  endDate: "Indica la fecha en la cuál se cerrará el desafío",
  logo: "Sube el logo de la empresa que organiza el desafío",
  termsAndConditions:
    "Sube el archivo con los términos y condiciones del desafío. Debe ser un archivo PDF",
  submissionExample:
    "Sube el archivo con el ejemplo de entrega que deberán subir los participantes. Debe ser un archivo CSV",
  description:
    "Describe en qué consistirá el desafío: el problema, el desafío y lo que se quiere predecir",
  prizesDescription:
    "Explica cuál será el premio: ¿Individual o compartido? ¿Cuál es el total y cuánto recibirá el primer, segundo y tercer lugar?",
  rulesAndEvaluation:
    "Define como será el método de evaluación y el puntaje que mostrará el ranking, \n" +
    "si el método de evaluación es una fórmula, se recomienda subir una imagen de esta a Drive o Dropbox y poner el enlace, \n" +
    "cada parámetro debe ser explicado. También deben ser explicadas las reglas para \n" +
    "calificar como ganador, los requisitos mínimos y que debe presentar el posible candidato para ganar",
  instructions:
    "Explica las instrucciones sobre el formato de entrega de los resultados, por ejemplo, \n" +
    "el tipo de archivo que se entregará, qué filas y columnas debe tener y cómo es su estructura",
  faq:
    "Aclara las posibles preguntas frecuentes que podrían ocurrir, tales como: ¿De dónde obtengo los datos? \n" +
    "¿Cómo se protege la propiedad intelectual del ganador? ¿Se puede participar como equipo? Recomendamos \n" +
    "Puedes agregar un link a Slack, plataforma oficial de tu Universidad o un canal de comunicación oficial del desafío, etc.",
  calendar:
    "Especifica las fechas de lanzamiento del desafío, periodo del concurso, fechas de selección de grupo finalista, etc",
  dataDescription:
    "Describe detalladamente cada parámetros de la base de datos",
  dataDownload:
    "Para que los usuarios de Binnario School puedan descargar la data, ingresa a esta carpeta, https://drive.google.com/drive/folders/1FytpVhN-WX0ean16PNPnKuuH9OmaTy8g?usp=sharing, y crea una carpeta nueva con el formato NombreDesafio/NombreCurso/NombreProfesor, ahi puedes agregar la data descargable. Recuerda que el link debe ser público",
  tags: "Escribe los tags con los cuales se relaciona el desafío, estos son utilizados posteriormente para hacer analítica de datos",
};
