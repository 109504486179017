import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
const Image = styled.img`
  && {
    width: 12em;
  }
`;

class BinnarioImg extends React.Component {
  render() {
    if (this.props.white) return <Image src="/images/LogoBinarioBlanco.png" />;
    return <Image src="/images/LogoBinarioAzul.png" />;
  }
}

BinnarioImg.propTypes = {
  white: PropTypes.bool,
};
export default BinnarioImg;
