export const convertDateInMilliseconds = (end) => {
  let convertEndDate = [];
  convertEndDate = end.substring(0, 10).split("-");
  let endDateString =
    convertEndDate[1] +
    "/" +
    convertEndDate[2] +
    "/" +
    convertEndDate[0] +
    " 23:59:00";
  var date = new Date(endDateString);
  var millisecondsDate = date.getTime();
  return millisecondsDate;
};
