import React, { Component } from "react";
import PropTypes from "prop-types";
import * as ROUTES from "../../config/routes";
import { auth, db } from "../../firebase";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "react-day-picker/lib/style.css";
import { TitleNH2M, MainButton } from "../../styling/Styles";
import SignInLink from "../signin/SignInLink";
import countriesOfResidency from "./countriesOfResidency.json";

const styles = (theme) => ({
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      border: "none",
      borderColor: theme.palette.secondary.main,
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
      margin: "1em 20%",
    },
  },
  textField: {
    width: "100%",
  },
  input: {
    backgroundColor: theme.palette.grey["100"],
  },
  inversedRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
  },
  subscriptionContainer: {},
  subscriptionLabel: {
    fontSize: "0.8em",
    color: theme.palette.primary.main,
    fontFamily: "Lato, sans-serif",
  },
  verificationInstructions: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
});

const INITIAL_STATE = {
  id: "",
  name: "",
  lastname: "",
  username: "",
  email: "",
  dateOfBirth: `${new Date().getFullYear() - 24}-${
    new Date().getMonth() >= 9
      ? new Date().getMonth() + 1
      : "0" + (new Date().getMonth() + 1)
  }-${
    new Date().getDate() >= 9
      ? new Date().getDate()
      : "0" + new Date().getDate()
  }`, // If date is less than 10, the format fails yyyy-MM-dd turns into yyyy-M-dd. Same for day
  countryOfResidency: "Chile",
  profession: "",
  companyOrSchool: "",
  checkMail: true,
  passwordOne: "",
  passwordTwo: "",
  error: null,
  firstTimeFilling: true,
};

class SignUpForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { ...INITIAL_STATE };
  }

  redirect = (link) => {
    this.props.history.push(link);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onSubmit = () => {
    if (this.state.firstTimeFilling) this.setState({ firstTimeFilling: false });
    let errors =
      this.state.name === "" ||
      this.state.lastname === "" ||
      this.state.username === "" ||
      this.state.email === "" ||
      this.state.profession === "" ||
      this.state.companyOrSchool === "" ||
      this.state.passwordOne === "" ||
      this.state.passwordTwo === "" ||
      this.state.passwordOne.length < 8 ||
      this.state.passwordTwo.length < 8 ||
      this.state.passwordOne !== this.state.passwordTwo;
    if (!errors) {
      const { username, email, passwordOne } = this.state;
      const { history } = this.props;

      auth
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then((authUser) => {
          authUser.user.updateProfile({
            displayName: this.state.name + " " + this.state.lastname,
          });
          // Create a user in your own accessible Firebase Database too
          db.addingUserInfo(
            authUser.user.uid,
            this.state.name,
            username,
            email,
            this.state.lastname,
            this.state.dateOfBirth,
            this.state.countryOfResidency,
            this.state.profession,
            this.state.companyOrSchool,
            this.state.checkMail
          )
            .then(() => {
              authUser.user
                .sendEmailVerification()
                .then(() => {
                  this.setState({ ...INITIAL_STATE });
                  history.push(ROUTES.CONFIRMATION_PAGE);
                })
                .catch((error) => error);
            })

            .catch((error) => {
              this.setState({ error });
            });
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onDateChange = (day) => {
    this.setState({ dateOfBirth: day });
  };

  render() {
    const { classes } = this.props;

    const {
      name,
      lastname,
      username,
      email,
      dateOfBirth,
      countryOfResidency,
      profession,
      companyOrSchool,
      checkMail,
      passwordOne,
      passwordTwo,
      error,
      firstTimeFilling,
    } = this.state;

    //var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    return (
      <Grid
        container
        spacing={32}
        className={classes.formContainer}
        onSubmit={this.onSubmit}
        noValidate
        autoComplete="off"
      >
        <Grid item xs={12}>
          <TitleNH2M>Crear cuenta</TitleNH2M>
          <SignInLink link={this.redirect} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="name"
            label="Nombre"
            type="text"
            name="name"
            value={name}
            autoComplete="name"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={!firstTimeFilling && name === ""}
            helperText={
              !firstTimeFilling && name === "" && "No puede estar vacío"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="lastname"
            label="Apellido"
            type="text"
            name="lastname"
            value={lastname}
            autoComplete="lastname"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={!firstTimeFilling && lastname === ""}
            helperText={
              !firstTimeFilling && lastname === "" && "No puede estar vacío"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="username"
            label="Nombre de usuario"
            type="text"
            name="username"
            value={username}
            autoComplete="username"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={!firstTimeFilling && username === ""}
            helperText={
              !firstTimeFilling && username === "" && "No puede estar vacío"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="countryOfResidency"
            select
            label="Seleccionar país de residencia"
            name="countryOfResidency"
            value={countryOfResidency}
            onChange={this.handleChange("countryOfResidency") && this.onChange}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="filled"
          >
            {countriesOfResidency.countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="profession"
            label="Profesión o carrera"
            type="text"
            name="profession"
            value={profession}
            autoComplete="profession"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={!firstTimeFilling && profession === ""}
            helperText={
              !firstTimeFilling && profession === "" && "No puede estar vacío"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="companyOrSchool"
            label="Empresa o Universidad"
            type="text"
            name="companyOrSchool"
            value={companyOrSchool}
            autoComplete="companyOrSchool"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={!firstTimeFilling && companyOrSchool === ""}
            helperText={
              !firstTimeFilling &&
              companyOrSchool === "" &&
              "No puede estar vacío"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="email"
            label="Email"
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={
              !firstTimeFilling &&
              (email === "" || !re.test(String(email).toLowerCase()))
            }
            helperText={
              !firstTimeFilling &&
              ((email === "" && "No puede estar vacío") ||
                (!re.test(String(email).toLowerCase()) &&
                  "Debes ingresar un email válido"))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            type="date"
            InputProps={{ className: classes.input }}
            id="dateOfBirth"
            label="Fecha de nacimiento"
            name="dateOfBirth"
            value={dateOfBirth}
            margin="normal"
            variant="filled"
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="passwordOne"
            label="Contraseña"
            type="password"
            name="passwordOne"
            value={passwordOne}
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={
              !firstTimeFilling &&
              (passwordOne === "" ||
                passwordOne.length < 8 ||
                passwordOne !== passwordTwo)
            }
            helperText={
              !firstTimeFilling &&
              ((passwordOne === "" && "No puede estar vacío") ||
                (passwordOne.length < 8 &&
                  "La contraseña debe tener al menos 8 caracteres"))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textField}
            InputProps={{ className: classes.input }}
            id="passwordTwo"
            label="Confirmar contraseña"
            type="password"
            name="passwordTwo"
            value={passwordTwo}
            margin="normal"
            variant="filled"
            onChange={this.onChange}
            error={
              !firstTimeFilling &&
              (passwordTwo === "" ||
                passwordTwo.length < 8 ||
                passwordOne !== passwordTwo)
            }
            helperText={
              !firstTimeFilling &&
              ((passwordTwo === "" && "No puede estar vacío") ||
                (passwordTwo.length < 8 &&
                  "La contraseña debe tener al menos 8 caracteres") ||
                (passwordOne !== passwordTwo &&
                  "Las contraseñas deben ser iguales"))
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.subscriptionContainer}>
          <Grid container justifyContent="center">
            <FormControlLabel
              classes={{ label: classes.subscriptionLabel }}
              control={
                <input
                  type="checkbox"
                  value="checkMail"
                  checked={checkMail}
                  onChange={() => this.setState({ checkMail: !checkMail })}
                />
              }
              label="Suscríbete para estar al tanto de todas las novedades y desafíos en Binnario"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <p className={classes.verificationInstructions}>
              Al crear tu cuenta te llegará un correo para verificar tu email,
              debes hacerlo para ingresar nuevamente
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <MainButton primary variant="contained" onClick={this.onSubmit}>
              Crear cuenta
            </MainButton>
          </Grid>
        </Grid>
        {error && <p>{error.message}</p>}
      </Grid>
    );
  }
}

SignUpForm.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(styles)(SignUpForm);
