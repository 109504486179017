import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../firebase/firebase";
import { db } from "../firebase";
import { Box, Typography, Container } from "@material-ui/core";
import AddNewClient from "../components/Admin/AddNewClient";
import RemoveClient from "../components/Admin/RemoveClient";
import ListClients from "../components/Admin/ListClients";
import ErrorData from "../Data/ErrorData";
import ErrorComponent from "../components/ErrorComponent/ErrorComponent";
//import Footer from "../components/Footer";

const styles = () => ({
  centerBox: {
    margin: "0 auto",
  },
});

class AdminPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isAdmin: false,
      email: "",
      error: "",
      open: true,
      hasResult: false,
      clients: {},
    };
  }

  async componentDidMount() {
    let allClients = await db.getClients();
    let clients = [];
    allClients.forEach((client) => {
      let thisClient = client.val();
      if (thisClient !== null) {
        clients.push({ ...thisClient, id: thisClient.key });
      }
      this.setState({ clients });
    });

    //this function is to verify if the current user is an Admin or not
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({ isAdmin: user });
        });
      }
    });
  }

  redirect = (link) => {
    this.props.history.push(link);
  };

  render() {
    const { classes } = this.props;
    let clients = this.state.clients;
    let isAdmin = this.state.isAdmin;
    let NotAnAdminText = ErrorData.admin.notAnAdmin;
    let NotLogged = ErrorData.client.NotLogged;

    if (!auth.currentUser) {
      return (
        <div>
          <ErrorComponent ErrorText={NotLogged} />
          {/* <Footer /> */}
        </div>
      );
    }
    if (isAdmin) {
      return (
        <div>
          <Container>
            <Box pt={8} width="50%" className={classes.centerBox}>
              <Typography align="center" variant="body1">
                Bienvenido Admin!, aquí puedes otorgar rol de profesor con un
                email en específico, recuerda que debes esperar un poco ya que
                el servidor externo se demora en reaccionar la primera vez :)
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box>
                  <AddNewClient history={history} />
                </Box>
                <Box>
                  <RemoveClient />
                </Box>
              </Box>
              <ListClients clients={clients} />
            </Box>
          </Container>
          {/* <Footer /> */}
        </div>
      );
    } else
      return (
        <div>
          <ErrorComponent ErrorText={NotAnAdminText} />
          {/* <Footer /> */}
        </div>
      );
  }
}

AdminPage.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.string,
  email: PropTypes.string,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(AdminPage);
