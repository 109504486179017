import React from 'react';
import PropTypes, { func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { TitleH3 } from "../../styling/Styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  root: {
    maxWidth: 350,
  },
  text: {
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    marginLeft: '10%',
    fontSize: '1em',
  },
  title: {
    fontWeight: 'bold',
    marginLeft: '7%',
    color: '#41B5C3',
    fontSize: '1em',
  },
  box: {
    padding: '10px',
    textAlign: 'left',
    backgroundColor: theme.palette.grey['50'],
    borderRadius: '10px',
  },
})

class ChallengeMenu extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.root}>
          <Grid item container xs={12}>
            <TitleH3>Información</TitleH3>
          </Grid>
          <Grid item container xs={12}>
            <Paper elevation={5}>
              <List component="nav" className={classes.box} >
                <p className={classes.title}>Descripción General</p>
                <ListItem button onClick={() => this.props.scrollToItem('description')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Descripción" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('prizesDescription')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Premio" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('evaluation')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Reglas y método de evaluación" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('instructions')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Instrucciones de entrega" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('faq')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Preguntas frecuentes" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('calendar')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Calendario" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('terms')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Términos y condiciones" />
                </ListItem>
                <p className={classes.title} >Datos</p>
                <ListItem button onClick={() => this.props.scrollToItem('data-description')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Descripción de los datos" />
                </ListItem>
                <ListItem button onClick={() => this.props.scrollToItem('data-download')}>
                  <ListItemText classes={{ primary: classes.text, }} primary="Descarga de los datos" />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

ChallengeMenu.propTypes = {
  classes: PropTypes.object,
  scrollToItem: func
}

export default withStyles(styles)(ChallengeMenu);
