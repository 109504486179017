import React from "react";
import { Grid, Hidden, Icon, withWidth } from "@material-ui/core";
import { TitleH1, TitleH2, Paragraph, ListItem } from "../../styling/Styles";
import GirlMacbook from "./GirlMacbook";
import Triumph from "./Triumph";
import ComputerSVG from "../svgicons/landing/svgLandingPage";
import PropTypes from "prop-types";

class DescriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { widthWindow: 0, heightWindow: 0 };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      widthWindow: window.innerWidth,
      heightWindow: window.innerHeight,
    });
  };

  render() {
    const { widthWindow } = this.state;
    const { width } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <TitleH1>¿Qué es un desafío de Data Science?</TitleH1>
                <Paragraph>
                  Existe una multiplicidad de data disponible en distintas áreas
                  de todas las empresas, pero sólo una mínima parte de esta
                  información es explotada en beneficio del negocio.
                </Paragraph>
                <Paragraph>
                  Los desafíos son una forma inteligente, eficiente y rápida de
                  utilizar esta data para el desarrollo de modelos predictivos.
                  Un desafío de Data Science convoca a una comunidad de expertos
                  que ayudan a obtener resultados más precisos que aportan al
                  negocio.
                </Paragraph>
                <Paragraph>Es una muy buena alternativa para:</Paragraph>
                <ul style={{ margin: 0 }}>
                  <ListItem>
                    Encontrar soluciones a problemas complejos con cientos de
                    expertos en Data Science y Machine Learning
                  </ListItem>
                  <ListItem>
                    Obtener nuevas perspectivas y soluciones a tu desafío
                  </ListItem>
                  <ListItem>
                    Descubrir las últimas y mejores técnicas de Machine Learning
                  </ListItem>
                  <ListItem>
                    Encontrar al mejor equipo de Data Scientists para tu
                    compañía
                  </ListItem>
                </ul>
              </Grid>
              <Grid item lg={6}>
                <div style={{ padding: "5em 0 5em 4em" }}>
                  <GirlMacbook widthWindow={widthWindow} />
                </div>
              </Grid>
            </Grid>
          </Grid>

          {width === "xl" || width === "lg" || width === "md" ? (
            <Grid container spacing={8}>
              <Grid order={1} item md={6}>
                <div style={{ padding: "4em 4em 0 0" }}>
                  <ComputerSVG widthWindow={widthWindow} />
                </div>
              </Grid>
              <Grid item md={6}>
                <TitleH1>¿Por qué con BINNARIO?</TitleH1>
                <Paragraph>
                  BINNARIO apoya a las empresas en la configuración de sus
                  desafíos, de manera que cientos de Data Scientist busquen
                  soluciones mediante distintos métodos y algoritmos de
                  solución.
                </Paragraph>
                <Paragraph>
                  La plataforma permite a los equipos subir entregas
                  periódicamente, evaluando su precisión en tiempo real, gracias
                  a los datos proporcionados por la empresa.
                </Paragraph>
                <Paragraph>
                  BINNARIO acompaña a las empresas durante todo el proceso,
                  incluyendo la integración del algoritmo desarrollado por el
                  equipo ganador, logrando resultados reales para tu negocio.
                </Paragraph>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <TitleH1>¿Por qué con BINNARIO?</TitleH1>
                <Paragraph>
                  BINNARIO apoya a las empresas en la configuración de sus
                  desafíos, de manera que cientos de Data Scientist busquen
                  soluciones mediante distintos métodos y algoritmos de
                  solución.
                </Paragraph>
                <Paragraph>
                  La plataforma permite a los equipos subir entregas
                  periódicamente, evaluando su precisión en tiempo real, gracias
                  a los datos proporcionados por la empresa.
                </Paragraph>
                <Paragraph>
                  BINNARIO acompaña a las empresas durante todo el proceso,
                  incluyendo la integración del algoritmo desarrollado por el
                  equipo ganador, logrando resultados reales para tu negocio.
                </Paragraph>
              </Grid>
              <Grid item xs={12}>
                <div style={{ padding: "4em 4em 0 0" }}>
                  <ComputerSVG widthWindow={widthWindow} />
                </div>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <TitleH1>¿Qué es BINNARIO?</TitleH1>
                <Paragraph>
                  BINNARIO es la primera comunidad de expertos en Data Science
                  de Latinoamérica que resuelve desafíos de las organizaciones
                </Paragraph>
                <Grid container>
                  <Grid item xs={6}>
                    <Paragraph>
                      <b style={{ fontSize: "1.6em" }}>+1100</b> <br />
                      Equipos
                    </Paragraph>
                  </Grid>
                  <Grid item xs={6}>
                    <Paragraph>
                      <b style={{ fontSize: "1.6em" }}>24</b> <br />
                      Países participantes
                    </Paragraph>
                  </Grid>
                  <Grid item xs={6}>
                    <Paragraph>
                      <b style={{ fontSize: "1.6em" }}>+US$ 150.000</b> <br />
                      En horas dedicadas por desafío
                    </Paragraph>
                  </Grid>
                  <Grid item xs={6}>
                    <Paragraph>
                      <b style={{ fontSize: "1.6em" }}>+800</b> <br />
                      Predicciones por desafío
                    </Paragraph>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <div style={{ padding: "4em 0 0 4em" }}>
                  <Triumph widthWindow={widthWindow} />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TitleH1>¿Qué necesitamos?</TitleH1>
                <Paragraph>
                  Todo desafío necesita un problema bien definido, datos para
                  analizar y entrenar los algoritmos, y un premio monetario para
                  los Data Scientist. El equipo de BINNARIO en cada uno de estos
                  puntos:
                </Paragraph>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Grid container direction="column" alignItems="center">
                  <Icon style={{ fontSize: "5em", color: "#2B3E7F" }}>
                    extension
                  </Icon>
                  <TitleH2>Un desafío</TitleH2>
                  <Paragraph style={{ textAlign: "center" }}>
                    Trabajaremos juntos para poder definir claramente qué clase
                    de problema necesitas resolver en tu organización.
                  </Paragraph>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container direction="column" alignItems="center">
                  <Icon style={{ fontSize: "5em", color: "#2B3E7F" }}>
                    multiline_chart
                  </Icon>
                  <TitleH2>Los datos</TitleH2>
                  <Paragraph style={{ textAlign: "center" }}>
                    Una vez definido el problema, junto con nuestro equipo de
                    Data Scientists buscaremos, limpiaremos y procesaremos la
                    data necesaria con el fin de dise ar el mejor desafío, así
                    nuestra comunidad de científicos podrá modelar las mejores
                    soluciones para tu empresa.
                  </Paragraph>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container direction="column" alignItems="center">
                  <Icon style={{ fontSize: "5em", color: "#2B3E7F" }}>
                    monetization_on
                  </Icon>
                  <TitleH2>Un premio</TitleH2>
                  <Paragraph style={{ textAlign: "center" }}>
                    Para poder lanzar un desafío es necesario el compromiso de
                    Data Scientists, esto muchas veces significa aprender nuevos
                    enfoques y crear complejos algoritmos, es por esto que se
                    requiere de financiamiento para compensar a los creadores de
                    las mejores soluciones.
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column">
              <TitleH1>¿Cuánto tiempo toma?</TitleH1>
              <Hidden smDown>
                <img
                  src="./images/Timeline.png"
                  height="366"
                  style={{ margin: "4em 0" }}
                />
              </Hidden>
              <Hidden mdUp>
                <div>
                  <img src="./images/Timeline.png" width="300" />
                </div>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item>
                <TitleH1>
                  ¿Qué tipos de problemas podríamos resolver juntos?
                </TitleH1>
              </Grid>
              <Grid item>
                <ul>
                  <ListItem>En Ventas y Marketing</ListItem>
                  <ListItem>En Finanzas y Seguros</ListItem>
                  <ListItem>En Manufactura</ListItem>
                  <ListItem>En Medicina</ListItem>
                  <ListItem>En Ciencia Medioambiental</ListItem>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
DescriptionComponent.propTypes = {
  width: PropTypes.string,
};

export default withWidth()(DescriptionComponent);
