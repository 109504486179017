import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import SignInForm from "../components/signin/SignInForm";
import Logo from "../styling/Logo";

const SignInPage = ({ history }) => {
  return (
    <Box>
      <Logo />
      <SignInForm history={history} />
    </Box>
  );
};

export default withRouter(SignInPage);

SignInPage.propTypes = {
  history: PropTypes.object,
};
