import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import OnlyAuthAccount from "../../userProfile/OnlyAuthAccount";
import { auth } from "../../firebase/firebase.js";

const styles = () => ({
  avatar: {
    //margin: 10,
    marginLeft: "50%",
    cursor: "pointer",
  },
});

class AvatarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container justifyContent="center" alignItems="center">
          <Avatar
            onClick={this.handleOpen}
            alt="Profile Picture"
            src={
              auth.currentUser &&
              (auth.currentUser.photoURL || "../images/avatarBinnario.png")
            }
            className={classes.avatar}
          />
        </Grid>
        {auth.currentUser && (
          <OnlyAuthAccount open={this.state.open} onClose={this.handleClose} />
        )}
      </React.Fragment>
    );
  }
}

AvatarComponent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AvatarComponent);
