import React from "react";
import PropTypes from "prop-types";
import sampleInfoChallenges from "../../../text/sample-info-challenges.js";
import { TitleH3, Paragraph, TitleH1 } from "../../../styling/Styles";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../../../firebase/firebase.js";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import processChallenge from "../../../helpers/processChallengeInfo.js";

const styles = (theme) => ({
  roundIcon: {
    fontSize: "0.4em",
    marginRight: "1%",
  },
  box: {
    border: `1px solid ${theme.palette.grey["500"]}`,
    padding: "10px",
    textAlign: "left",
    margin: "0em 0em 2em 0em",
    boxShadow: `1px 1px 1px ${theme.palette.grey["400"]}`,
    borderRadius: "4px",
    backgroundColor: theme.palette.grey["50"],
  },
  separatorInfo: {
    borderTop: "2px solid #251A6A",
    width: "85%",
  },
  separatorBlock: {
    borderTop: "2px solid #251A6A",
    width: "100%",
    align: "center",
  },
  marginTitle: {
    marginTop: theme.breakpoints.down("sm") ? "250px" : "0px",
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
    padding: "1em",
  },
  rightGridMargin: {
    marginTop: theme.breakpoints.down("sm") ? "40px" : "0px",
  },
});

class ChallengeInfo extends React.Component {
  constructor(props) {
    super(props);
    let challenge = props.challenge;
    this.state = {
      challenge: challenge,
      description:
        challenge.description === undefined
          ? []
          : processChallenge(challenge.description),
      dataDescription:
        challenge.dataDescription === undefined
          ? []
          : processChallenge(challenge.dataDescription),
      prizesDescription:
        challenge.prizesDescription === undefined
          ? []
          : processChallenge(challenge.prizesDescription),
      faq: challenge.faq === undefined ? [] : processChallenge(challenge.faq),
      calendar:
        challenge.calendar === undefined
          ? []
          : processChallenge(challenge.calendar),
      instructions:
        challenge.instructions === undefined
          ? []
          : processChallenge(challenge.instructions),
      rulesAndEvaluation:
        challenge.rulesAndEvaluation === undefined
          ? []
          : processChallenge(challenge.rulesAndEvaluation),
      infochallenges: sampleInfoChallenges,
    };
  }

  render() {
    const { classes } = this.props;
    const { challenge } = this.state;
    this.props.scrollTo !== "" &&
      document
        .getElementById(this.props.scrollTo)
        .scrollIntoView({ behavior: "smooth" });
    return (
      <Grid item container xs={12}>
        <Grid item container xs={12}>
          <Grid container item xs={6} md={6} justifyContent="center">
            <Hidden smDown>
              <TitleH1 className={classes.marginTitle}>
                <div>Descripción</div>
                <div>General</div>
              </TitleH1>
            </Hidden>
            <Hidden mdUp>
              <Grid item container xs={11}>
                <TitleH1>Descripción General</TitleH1>
              </Grid>
            </Hidden>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={5}
            className={classes.rightGridMargin}
          >
            <div>
              <TitleH3 className={classes.leftAlign} id="description">
                01 Descripción
              </TitleH3>
              <Paragraph>
                {this.state.description.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="prizesDescription">
                02 Premios
              </TitleH3>
              <Paragraph>
                {this.state.prizesDescription.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="evaluation">
                03 Reglas y método de evaluación
              </TitleH3>
              <Paragraph>
                {this.state.rulesAndEvaluation.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="instructions">
                04 Instrucciones sobre el formato de entrega de los resultados
              </TitleH3>
              <Paragraph>
                {this.state.instructions.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
              <Paragraph>
                <b>
                  Para descargar el archivo de ejemplo haz click{" "}
                  <a
                    href={challenge.submissionExampleUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>
                </b>
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="faq">
                05 Preguntas Frecuentes
              </TitleH3>
              <Paragraph>
                {this.state.faq.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="calendar">
                06 Calendario
              </TitleH3>
              <Paragraph>
                {this.state.calendar.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="terms">
                07 Términos y condiciones
              </TitleH3>
              <Paragraph>
                Para conocer los términos y condiciones de la convocatoria
                descarga el archivo{" "}
                <a
                  href={challenge.termsAndConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aquí
                </a>
              </Paragraph>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <div className={classes.separatorInfo}></div>
        </Grid>
        <Grid item container xs={12}>
          <Grid container item xs={6} md={6} justifyContent="center">
            <Hidden smDown>
              <TitleH1 className={classes.marginTitle}>Datos</TitleH1>
            </Hidden>
            <Hidden mdUp>
              <Grid item container xs={11}>
                <TitleH1>Datos</TitleH1>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item container xs={10} md={5}>
            <div>
              <TitleH3 className={classes.leftAlign} id="data-description">
                08 Descripción de los datos
              </TitleH3>
              <Paragraph>
                {this.state.dataDescription.map((component) => {
                  if (typeof component.tag === "string")
                    return (
                      <div
                        key={component.key}
                        dangerouslySetInnerHTML={{ __html: component.tag }}
                      />
                    );
                  else return component.tag;
                })}
              </Paragraph>
            </div>
            <div className={classes.separatorBlock}></div>
            <div>
              <TitleH3 className={classes.leftAlign} id="data-download">
                09 Descarga de los datos
              </TitleH3>
              {auth.currentUser ? (
                <Paragraph>
                  Puedes descargar los datos{" "}
                  <a
                    href={challenge.dataDownloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    acá
                  </a>
                </Paragraph>
              ) : (
                <Paragraph>
                  Debes iniciar sesión para acceder a los datos.
                </Paragraph>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChallengeInfo.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
  scrollTo: PropTypes.string,
};

export default withStyles(styles)(ChallengeInfo);
