import React, { Component } from "react";
import PropTypes from "prop-types";
import { auth } from "../firebase";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import SignInLink from "./signin/SignInLink";
import Box from "@material-ui/core/Box";
import { ErrorContainer } from "../styling/Styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const codeToMessage = {
  "auth/invalid-email": "Email inválido",
  "auth/missing-android-pkg-name": "Error interno",
  "auth/missing-continue-uri": "Error interno",
  "auth/missing-ios-bundle-id": "Error interno",
  "auth/invalid-continue-uri": "Error interno",
  "auth/unauthorized-continue-uri": "Error interno",
  "auth/user-not-found": "Usuario no encontrado",
  "auth/invalid-dynamic-link-domain": "Error interno",
};

class PasswordForgetForm extends Component {
  state = {
    email: "",
    error: null,
    sent: false,
  };

  onSubmit = (event) => {
    const { email } = this.state;
    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({ sent: true });
      })
      .catch((error) => {
        this.setState({ error: codeToMessage[error.code] });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { email, error } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        {this.state.sent ? (
          <Box pt={2}>
            <Typography align="center" color="primary" variant="h5">
              <strong>
                {" "}
                Te hemos enviado un correo con instrucciones para cambiar tu
                contraseña{" "}
              </strong>
            </Typography>
          </Box>
        ) : (
          <Box md={6}>
            <Typography
              align="center"
              gutterBottom
              component="h1"
              color="primary"
            >
              <strong> Recuperar contraseña </strong>
            </Typography>
            <Typography align="center" color="primary" variant="body1">
              Ingresa tu email y te enviaremos un link para recuperar tu
              contraseña
            </Typography>
            <Box pt={1}>
              <SignInLink
                link={(link) => {
                  this.props.history.push(link);
                }}
              />
            </Box>
            <Grid
              container
              className={classes.form}
              onSubmit={this.onSubmit}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                margin="normal"
                label="Email"
                autoComplete="email"
                fullWidth
                autoFocus
                required
                className="signin"
                name="email"
                value={email}
                onChange={this.onChange}
                type="email"
                id="email"
                htmlFor="email"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                value="submit"
                className={classes.submit}
                onClick={this.onSubmit}
              >
                Cambiar Clave
              </Button>
            </Grid>
            <ErrorContainer>{error}</ErrorContainer>
          </Box>
        )}
      </Container>
    );
  }
}

PasswordForgetForm.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(PasswordForgetForm);
