import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SignUpForm from "../components/signup/SignUpForm"
import Logo from '../styling/Logo';

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  }
});

class SignUpPage extends Component {
  render() {
    const { history, classes } = this.props
    return (
      <div>
        <Logo/>
        <div className={classes.container}>
          <SignUpForm history={history} />
        </div>
      </div>
    )
  }
}

SignUpPage.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(withRouter(SignUpPage));
