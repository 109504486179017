import React from "react";
import PropTypes from "prop-types";
import ChallengeHeader from "../components/challenge/ChallengeHeader";
import ChallengeMenu from "../components/challenge/ChallengeMenu";
import ChallengeTabs from "../components/challenge/ChallengeTabs";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Hidden, CircularProgress, Paper } from "@material-ui/core";
import { db } from "../firebase";
import Footer from "../components/Footer";

const styles = (theme) => ({
  container: {
    padding: "0 2em",
  },
  leftColumnContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    margin: "0 2em",
  },
  rightColumnContainer: {
    display: "flex",
    flex: "3",
    flexDirection: "column",
    margin: "0 2em",
  },
  fab: {
    overflow: "hidden",
    margin: "0",
    top: "auto",
    right: "6em",
    position: "fixed",
    textAlign: "center",
    fontSize: "0.1em",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabIcon: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: "white",
    },
  },
  gridMargin: {
    marginTop: "40px",
  },
  rootGrid: {
    marginTop: "40px",
  },
  rootErrorPaper: {
    padding: "30px",
    marginTop: "10px",
  },
  circularProgressGrid: {
    height: window.innerHeight - 100,
  },
});

class Challenge extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: 0,
      scrollTo: "",
      challenge: undefined,
      isLoading: true,
      hasError: false,
    };
  }
  async componentDidMount() {
    try {
      const challenge = await db.getChallenge(this.props.match.params.id);
      if (challenge.val() === null)
        await this.setState({ hasError: true, isLoading: false });
      else
        await this.setState({
          challenge: { ...challenge.val(), id: challenge.key },
          isLoading: false,
        });
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }
  }

  changeTab = (event, activeTab) => this.setState({ activeTab });
  scrollToUndefined = () => this.setState({ scrollTo: "" });
  scrollToItem = async (scrollTo) => {
    await this.setState({ activeTab: 0 });
    if (this.state.activeTab === 0) this.setState({ scrollTo });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.isLoading && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.circularProgressGrid}
          >
            <CircularProgress size={100} />
          </Grid>
        )}
        {this.state.hasError && (
          <Grid item container xs={12} justifyContent="center">
            <Paper className={classes.rootErrorPaper}>
              <h1>Ocurrió un error cargando el desafío</h1>
            </Paper>
          </Grid>
        )}
        {this.state.challenge && (
          <Grid container justifyContent="center" className={classes.rootGrid}>
            <Grid item container xs={10} justifyContent="center">
              <Grid item container md={4}>
                <Hidden mdDown>
                  <ChallengeMenu scrollToItem={this.scrollToItem} />
                </Hidden>
              </Grid>
              <Grid item container xs={12} lg={8} justifyContent="flex-end">
                <Grid container justifyContent="center" alignItems="center">
                  <ChallengeHeader challenge={this.state.challenge} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.gridMargin}>
              <ChallengeTabs
                activeTab={this.state.activeTab}
                changeTab={this.changeTab}
                scrollTo={this.state.scrollTo}
                scrollToUndefined={this.scrollToUndefined}
                scrollToItem={this.scrollToItem}
                challenge={this.state.challenge}
              />
            </Grid>
          </Grid>
        )}
        <Footer />
      </div>
    );
  }
}

Challenge.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withStyles(styles)(Challenge);
