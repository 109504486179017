import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const now = new Date();
const thisYear = now.getFullYear();
const days = [...Array(31).keys()];
const months = [...Array(12).keys()];
const years = [thisYear, thisYear + 1, thisYear + 2];
const monthToDays = {
  0: 31,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
};

const monthToName = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};

const styles = (theme) => ({
  textInput: {
    borderColor: `${theme.palette.primary.light} !important`,
    borderWidth: "1px",
    borderRadius: "10px",
  },
  dateField: {
    marginRight: "1em",
  },
});
class DatePicker extends React.Component {
  // Set inital parameters
  state = {
    dateDay: this.props.dateDay || new Date().getDate(),
    dateMonth: this.props.dateMonth || new Date().getMonth(),
    dateYear: this.props.dateYear || new Date().getFullYear(),
  };

  componentDidMount() {
    if (this.props.dateDay) {
      this.setState({ dateDay: this.props.dateDay });
    }
    if (this.props.dateMonth) {
      this.setState({ dateMonth: this.props.dateMonth });
    }
    if (this.props.dateYear) {
      this.setState({ dateYear: this.props.dateYear });
    }
  }

  monthTotalDays = (month, year) => {
    // Febraury
    if (month === 1) {
      // Check if the current year is a leap year
      let isLeapYear = year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
      if (isLeapYear) return 29;
      return 28;
    }
    return monthToDays[month];
  };

  generateDate = (year, month, day) => {
    return new Date(year, month, day);
  };

  onDateFieldChange = (event) => {
    const name = event.target.name;
    let dateDay = this.state.dateDay;
    let dateMonth = this.state.dateMonth;
    let dateYear = this.state.dateYear;

    if (name === "dateYear") {
      dateYear = event.target.value;
      this.setState({
        dateYear,
      });
    }

    if (name === "dateMonth") {
      let checkDay = this.state.dateDay;
      dateMonth = event.target.value;
      const monthTotalDays = this.monthTotalDays(
        dateMonth,
        this.state.dateYear
      );
      if (checkDay > monthTotalDays) checkDay = monthTotalDays;
      this.setState({
        dateMonth,
        dateDay: checkDay,
      });
    }

    if (name === "dateDay") {
      const month = this.state.dateMonth;
      let checkDay = event.target.value;
      const monthTotalDays = this.monthTotalDays(month, this.state.dateYear);
      if (checkDay > monthTotalDays) checkDay = monthTotalDays;
      dateDay = checkDay;
      this.setState({
        dateMonth: month,
        dateDay,
      });
    }

    this.props.onChange(this.generateDate(dateYear, dateMonth, dateDay));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TextField
          id="day"
          name="dateDay"
          select
          variant="outlined"
          className={classes.dateField}
          label="Día"
          value={this.state.dateDay}
          onChange={this.onDateFieldChange}
          InputProps={{
            classes: {
              notchedOutline: classes.textInput,
            },
          }}
        >
          {days.map((day) => {
            // Day plus 1 because the array of days starts with 0
            return (
              <MenuItem
                key={day + 1}
                value={day + 1}
                disabled={
                  day + 1 >
                  this.monthTotalDays(this.state.dateMonth, this.state.dateYear)
                }
              >
                {day + 1}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="month"
          name="dateMonth"
          select
          variant="outlined"
          className={classes.dateField}
          label="Mes"
          value={this.state.dateMonth}
          onChange={this.onDateFieldChange}
          InputProps={{
            classes: {
              notchedOutline: classes.textInput,
            },
          }}
        >
          {months.map((month) => {
            return (
              <MenuItem key={month} value={month}>
                {monthToName[month]}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="year"
          name="dateYear"
          select
          variant="outlined"
          className={classes.dateField}
          label="Año"
          value={this.state.dateYear}
          onChange={this.onDateFieldChange}
          InputProps={{
            classes: {
              notchedOutline: classes.textInput,
            },
          }}
        >
          {years.map((year) => {
            return (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
    );
  }
}

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dateDay: PropTypes.number,
  dateMonth: PropTypes.number,
  dateYear: PropTypes.number,
};

export default withStyles(styles)(DatePicker);
