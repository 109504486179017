import React from "react";
import Footer from "../components/Footer";
import CreateJobOfferForm from "../components/JobOffers/CreateJobOfferForm";

class CreateJobOffer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CreateJobOfferForm />
        <Footer />
      </React.Fragment>
    );
  }
}

export default CreateJobOffer;
