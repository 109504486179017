import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const Background = styled.div`
  && {
    .aa38e89a-0498-4059-add2-def8efd25706{fill:#42e8e0;opacity:0.36;}.fca4d874-a131-47bc-aad7-fca60d49d362{fill:#42b5c3;}.e79ba694-1844-4048-9978-a650f3538d85{fill:#c9c8fc;}.a6c93442-e3f8-4ffd-8c10-b4773719af25{fill:#3dc8ce;}.e0f334b8-51a1-4f02-8b0e-d7324000dc64{fill:#e4e4fe;}.e767fcf4-6218-4914-ad59-0fdb4b1eed2d{fill:#fff;}.b7179b59-ee50-4312-8412-231a7736343d{fill:#173d7a;}.b5554f9c-4056-4298-a862-286a6311167b{fill:#2a3e81;}.bdcec331-4e1f-4102-8b75-4c3f2ff483c2{fill:#274f89;}.e0366275-3e0b-402e-8969-f805c8cad581{fill:#c0bff6;}.b2838d86-d2f2-4a5e-9f75-ef4349b8f176{fill:#262b78;}.aae4131c-fdda-4c3c-a942-2995b2e4e761{fill:#5793ce;}.aee4cbf5-08ca-46aa-8b74-b18af08a622b{fill:#40dedb;}.e57a76c5-043a-4ec9-ab30-9a4cecbc11f2{fill:#d4ecff;}.bb6076d6-bfc8-4f51-a86b-8d9dccc107a2{fill:#9391f9;}.b2e74428-ed8f-4a1d-b71b-2a211b3e7afa{fill:#ffc2b0;}.a7bf1390-3f0b-412c-8ddb-f0d7fbee7fdb{fill:#ea9c92;}.be56601e-e15b-49a5-a24b-08c3ad6fdbed{fill:#5a58ba;}.b5479636-846f-491a-ad5a-ec8364e29f1c{fill:#396caa;}.a3aafb29-72a2-404a-a08d-20ed25fff3aa{fill:#55659a;}
}
`;



class GirlMacbook extends React.Component {
  render() {
    return (
    <React.Fragment>
      <Background>
      <svg 
        xmlns='http://www.w3.org/2000/svg' 
        viewBox='0 0 290.58 319.38' 
        width={this.props.widthWindow * 0.30}
      >
    <defs />
    <g id='bf8be3d9-eb31-42fe-8966-7e58acb46637' data-name='Escritorio 2D'>
        <path className='aa38e89a-0498-4059-add2-def8efd25706' d='M141.53,316.76,12.24,242.11a4.36,4.36,0,0,1,0-7.55l122.94-71L272.73,243a4.36,4.36,0,0,1,0,7.55L158,316.76A16.51,16.51,0,0,1,141.53,316.76Z'
        transform='translate(-.4 .4)' />
        <path className='fca4d874-a131-47bc-aad7-fca60d49d362' d='M137.8,123.87c0-8.21-5.77-11.56-12.89-7.46L4,186.09a7.8,7.8,0,0,0-3.55,6.12c0,2.26,1.58,3.18,3.54,2l120.93-69.67c3.21-1.85,5.81-.34,5.81,3.36v13.52c2.34-.14,4.7-.14,7.08-.1Z'
        transform='translate(-.4 .4)' />
        <path className='fca4d874-a131-47bc-aad7-fca60d49d362' d='M3.94,218.58l120.92-44.07c7.12-4.1,12.91-14.11,12.92-22.32V141.37c-2.38,0-4.74,0-7.08.1l0,14.8a12.87,12.87,0,0,1-5.82,10.07L3.94,210.4A7.85,7.85,0,0,0,.4,216.53C.4,218.79,2,219.71,3.94,218.58Z'
        transform='translate(-.4 .4)' />
        <polygon className='fca4d874-a131-47bc-aad7-fca60d49d362' points='0.92 219.14 148.02 304.26 272.56 229.99 282.44 215.24 281.42 220.47 278.76 206.79 47.58 215.6 6.84 212.98 0.92 219.14'
        />
        <polygon className='e79ba694-1844-4048-9978-a650f3538d85' points='5.79 193.46 5.75 211.56 147.93 293.82 151.77 276.56 5.79 193.46'
        />
        <polygon className='a6c93442-e3f8-4ffd-8c10-b4773719af25' points='280.61 200.97 133.74 116 1.32 193 1.05 194.89 148.14 279.99 280.61 200.97'
        />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M234.19,244.2l34.53-20.49a12.94,12.94,0,0,0,5.19-9.63l0-2.71c0-2.19-.92-3.61-2.33-4l-68,37.82-55.36,29.94.06,18.31Z'
        transform='translate(-.4 .4)' />
        <path className='fca4d874-a131-47bc-aad7-fca60d49d362' d='M153.24,276.78,7.3,192.35l-3.09-1.79-2.83-1.64a7.09,7.09,0,0,0-.94,3.29,2.51,2.51,0,0,0,1,2.27h0l147.05,85.07Z'
        transform='translate(-.4 .4)' />
        <polygon className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' points='146.18 303.2 148.02 304.26 148.36 303.68 147.21 302.78 146.18 303.2'
        />
        <polygon className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' points='146.23 278.88 148.11 279.97 147.64 278.94 146.23 278.88'
        />
        <path className='a6c93442-e3f8-4ffd-8c10-b4773719af25' d='M151.07,271.18,272,201.51c7.11-4.1,12.9-.76,12.89,7.45l0,2.72c0,8.21-5.8,18.22-12.91,22.32L151,303.67c-2,1.13-3.54.21-3.54-2A7.82,7.82,0,0,1,151,295.5L272,225.83a12.88,12.88,0,0,0,5.83-10.07l0-2.71c0-3.71-2.6-5.22-5.81-3.37L151.06,279.36c-1.95,1.12-3.54.21-3.53-2.05A7.83,7.83,0,0,1,151.07,271.18Z'
        transform='translate(-.4 .4)' />
        <path className='a6c93442-e3f8-4ffd-8c10-b4773719af25' d='M147.48,301.63A7.82,7.82,0,0,1,151,295.5L272,225.83a12.88,12.88,0,0,0,5.83-10.07l0-2.71c0-2.43-1.11-3.9-2.79-4.12a4.88,4.88,0,0,1,.86,3l0,2.72A12.87,12.87,0,0,1,270,224.71L149.09,294.38a7.83,7.83,0,0,0-3.55,6.13c0,1.71.91,2.64,2.21,2.51A3.41,3.41,0,0,1,147.48,301.63Z'
        transform='translate(-.4 .4)' />
        <path className='a6c93442-e3f8-4ffd-8c10-b4773719af25' d='M147.53,277.31a7.83,7.83,0,0,1,3.54-6.13L272,201.51c3.06-1.77,5.88-2.14,8.09-1.35-2.36-2-6-2.11-10,.23L149.13,270.06a7.83,7.83,0,0,0-3.54,6.13c0,1.71.91,2.64,2.2,2.51A3.38,3.38,0,0,1,147.53,277.31Z'
        transform='translate(-.4 .4)' />
        <path className='b7179b59-ee50-4312-8412-231a7736343d' d='M285.94,75.73,155.47.4a5.3,5.3,0,0,0-4.42-.54h0l-.24.09-.06,0-4.71,1.8,4.23,2.08-.06.23L129,100.8c-1.07,4.85,1.06,10.5,4.76,12.63l127.33,73.51-.45,5.4,5.15-2a7.24,7.24,0,0,0,3.69-5.25L290.7,88.36C291.76,83.52,289.63,77.86,285.94,75.73Z'
        transform='translate(-.4 .4)' />
        <path className='b5554f9c-4056-4298-a862-286a6311167b' d='M285.94,75.73,155.47.4a5.3,5.3,0,0,0-4.42-.54h0l-.24.09-.06,0-4.71,1.8,4.23,2.08-.06.23-14,63.75,63.84,49.74,90-36.63A10.17,10.17,0,0,0,285.94,75.73Z'
        transform='translate(-.4 .4)' />
        <path className='bdcec331-4e1f-4102-8b75-4c3f2ff483c2' d='M281,90.6,291,86.37c.18-3.84-1.41-7.82-4-9.94L271.21,83Z'
        transform='translate(-.4 .4)' />
        <path className='e0366275-3e0b-402e-8969-f805c8cad581' d='M256.07,191.83,125.6,116.5c-3.69-2.13-5.82-7.78-4.76-12.62L142.1,7.14c1.06-4.84,4.91-7,8.6-4.91L281.18,77.56c3.69,2.13,5.82,7.78,4.75,12.62l-21.25,96.74C263.62,191.76,259.76,194,256.07,191.83Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M282.43,77.1,152,1.77a5.16,5.16,0,0,0-5.14-.22,5.35,5.35,0,0,1,3.88.68L281.18,77.56c3.69,2.13,5.82,7.78,4.75,12.62l-21.25,96.74a7.34,7.34,0,0,1-3.47,5.13c2.2-.48,4-2.48,4.72-5.59l21.26-96.73C288.25,84.88,286.12,79.23,282.43,77.1Z'
        transform='translate(-.4 .4)' />
        <path className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' d='M253.24,184.63l-122.7-70.81c-3.38-1.95-5.33-7.12-4.36-11.55l19.45-88.52c1-4.43,4.5-6.44,7.88-4.49L276.22,80.07c3.37,2,5.32,7.12,4.35,11.55l-19.45,88.52C260.15,184.57,256.62,186.58,253.24,184.63Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M253.24,184.63l-122.7-70.81c-3.38-1.95-5.33-7.12-4.36-11.55l19.45-88.52c1-4.43,4.5-6.44,7.88-4.49L276.22,80.07c3.37,2,5.32,7.12,4.35,11.55l-19.45,88.52C260.15,184.57,256.62,186.58,253.24,184.63Z'
        transform='translate(-.4 .4)' />
        <path className='aae4131c-fdda-4c3c-a942-2995b2e4e761' d='M254.31,184.2l-122.7-70.81c-3.38-2-5.33-7.13-4.36-11.55L146.7,13.31a6.8,6.8,0,0,1,3.09-4.66c-1.94.5-3.55,2.33-4.16,5.1l-19.45,88.52c-1,4.43,1,9.6,4.36,11.55l122.7,70.81a4.69,4.69,0,0,0,4.79.17A4.91,4.91,0,0,1,254.31,184.2Z'
        transform='translate(-.4 .4)' />
        <path className='b7179b59-ee50-4312-8412-231a7736343d' d='M257.87,195.11,240,192.75,124.91,126.29c-3.69-2.14-10.13-1.87-14.4.59L28.94,174l-10.79-1.42v6.12h0c-.07,1.41.74,2.77,2.48,3.78l130.47,75.33c3.69,2.13,10.14,1.86,14.4-.6l88.84-51.27c2.4-1.39,3.59-3.17,3.52-4.85h0Z'
        transform='translate(-.4 .4)' />
        <path className='b5554f9c-4056-4298-a862-286a6311167b' d='M171.37,153.11l-8.18-4.72H73.27L28.94,174l-10.79-1.42v6.12h0c-.07,1.41.74,2.77,2.48,3.78l130.47,75.33a13.58,13.58,0,0,0,7.43,1.39Z'
        transform='translate(-.4 .4)' />
        <path className='b5554f9c-4056-4298-a862-286a6311167b' d='M22.94,173.17l-4.79-.63v6.12h0c-.07,1.41.74,2.77,2.48,3.78l2.3,1.33Z'
        transform='translate(-.4 .4)' />
        <path className='bdcec331-4e1f-4102-8b75-4c3f2ff483c2' d='M152.42,258.38a16.7,16.7,0,0,0,12.24-.76V244H152.42Z'
        transform='translate(-.4 .4)' />
        <path className='e0366275-3e0b-402e-8969-f805c8cad581' d='M151.11,251.66,20.64,176.33c-3.69-2.13-3.23-5.85,1-8.32l88.84-51.27c4.27-2.46,10.71-2.73,14.4-.6l130.48,75.33c3.69,2.13,3.22,5.85-1,8.31l-88.84,51.28C161.25,253.52,154.8,253.79,151.11,251.66Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M254.35,197.55l-88.84,51.27c-4.26,2.47-10.71,2.73-14.4.6L20.64,174.09a4.84,4.84,0,0,1-2.3-2.51c-.57,1.75.16,3.52,2.3,4.75l130.47,75.33c3.69,2.13,10.14,1.86,14.4-.6l88.84-51.28c2.87-1.65,4-3.87,3.33-5.8A6.94,6.94,0,0,1,254.35,197.55Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M76.13,151.39,70.23,148c-.48-.27-.42-.75.13-1.07l7-4a2.09,2.09,0,0,1,1.85-.08l5.9,3.41c.47.28.41.75-.13,1.07l-7,4A2.06,2.06,0,0,1,76.13,151.39Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M85.25,156.65l-5.91-3.4c-.47-.28-.41-.76.14-1.07l7-4a2.05,2.05,0,0,1,1.85-.07l5.91,3.41c.47.27.41.75-.14,1.06l-7,4A2.05,2.05,0,0,1,85.25,156.65Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M94.36,161.92l-5.9-3.41c-.47-.27-.42-.75.13-1.07l7-4a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-7,4A2.06,2.06,0,0,1,94.36,161.92Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M107,157.57l-5.9-3.41c-.48-.27-.42-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,107,157.57Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M116.09,162.83l-5.91-3.4c-.47-.28-.41-.76.14-1.07l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.28.41.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,116.09,162.83Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M125.2,168.1l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.91,3.41c.47.27.41.75-.14,1.06L127.05,168A2.06,2.06,0,0,1,125.2,168.1Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M134.32,173.36l-5.9-3.41c-.48-.27-.42-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,134.32,173.36Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M143.44,178.62l-5.91-3.4c-.47-.28-.41-.76.14-1.07l5.85-3.38a2.09,2.09,0,0,1,1.85-.08l5.9,3.41c.47.28.41.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,143.44,178.62Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M152.55,183.89l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,152.55,183.89Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M161.67,189.15l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,161.67,189.15Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M170.79,194.41l-5.91-3.4c-.47-.28-.41-.76.14-1.07l5.85-3.38a2.07,2.07,0,0,1,1.84-.08l5.91,3.41c.47.28.41.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,170.79,194.41Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M179.9,199.68l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.37a2,2,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,179.9,199.68Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M189,204.94l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38A2.06,2.06,0,0,1,191,197l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2,2,0,0,1,189,204.94Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M120.4,155.08l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.06L122.25,155A2.06,2.06,0,0,1,120.4,155.08Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M111.15,149.74l-5.9-3.41c-.48-.28-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06L113,149.66A2.06,2.06,0,0,1,111.15,149.74Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M101.88,144.39l-12.71-7.34c-.47-.28-.42-.76.13-1.07l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l12.71,7.34c.48.28.42.76-.13,1.07l-5.85,3.38A2.06,2.06,0,0,1,101.88,144.39Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M108.36,137.88l-10.32-6c-.47-.27-.41-.75.14-1.07l5.85-3.37a2,2,0,0,1,1.84-.08l10.32,6c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,108.36,137.88Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M129.52,160.34l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,129.52,160.34Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M138.64,165.61l-5.91-3.41c-.47-.28-.41-.75.14-1.07l5.85-3.38a2,2,0,0,1,1.84-.07l5.91,3.4c.47.28.41.76-.13,1.07l-5.85,3.38A2.09,2.09,0,0,1,138.64,165.61Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M147.75,170.87l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,147.75,170.87Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M156.87,176.13l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,156.87,176.13Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M166,181.4,160.08,178c-.47-.28-.41-.75.14-1.07l5.85-3.38a2,2,0,0,1,1.84-.07l5.91,3.4c.47.28.41.76-.14,1.07l-5.84,3.38A2.06,2.06,0,0,1,166,181.4Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M175.1,186.66l-5.9-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07L177,186.58A2.06,2.06,0,0,1,175.1,186.66Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M184.22,191.92l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.42.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,184.22,191.92Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M193.34,197.19l-5.91-3.41c-.47-.28-.41-.75.14-1.07l5.84-3.38a2.05,2.05,0,0,1,1.85-.07l5.91,3.4c.47.28.41.76-.14,1.07l-5.84,3.38A2.06,2.06,0,0,1,193.34,197.19Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M202.45,202.45l-5.9-3.41c-.47-.27-.42-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,202.45,202.45Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M218.66,211.81l-12.88-7.44c-.47-.27-.42-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l12.88,7.44c.47.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,218.66,211.81Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M236.41,201.56l-10.87-6.28c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08L244.24,197c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,236.41,201.56Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M145.49,159.31l-5.91-3.41c-.47-.27-.41-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.27.41.75-.14,1.07l-5.85,3.38A2,2,0,0,1,145.49,159.31Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M136.14,153.92l-5.9-3.41c-.48-.27-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06L138,153.84A2.06,2.06,0,0,1,136.14,153.92Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M126.8,148.52l-5.9-3.4c-.48-.28-.42-.76.13-1.07l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.28.41.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,126.8,148.52Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M117.46,143.13l-5.91-3.41c-.47-.27-.41-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.27.41.75-.14,1.07l-5.85,3.37A2,2,0,0,1,117.46,143.13Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M154.6,164.58l-5.9-3.41c-.48-.28-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07l-5.85,3.38A2.06,2.06,0,0,1,154.6,164.58Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M163.72,169.84l-5.9-3.41c-.48-.27-.42-.75.13-1.07L163.8,162a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,163.72,169.84Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M172.83,175.1l-5.9-3.41c-.47-.27-.41-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.27.41.75-.14,1.07L174.68,175A2.05,2.05,0,0,1,172.83,175.1Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M182,180.37l-5.9-3.41c-.48-.28-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07l-5.85,3.38A2.06,2.06,0,0,1,182,180.37Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M191.07,185.63l-5.91-3.41c-.47-.27-.41-.75.14-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,191.07,185.63Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M200.18,190.89l-5.9-3.41c-.47-.27-.41-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.28.41.75-.14,1.07L202,190.82A2.05,2.05,0,0,1,200.18,190.89Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M209.3,196.16l-5.9-3.41c-.48-.28-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07l-5.85,3.38A2.06,2.06,0,0,1,209.3,196.16Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M218.42,201.42,212.51,198c-.47-.27-.41-.75.14-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,218.42,201.42Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M227.53,206.68l-5.9-3.41c-.47-.27-.41-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.28.41.75-.14,1.07l-5.85,3.38A2.05,2.05,0,0,1,227.53,206.68Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M140.68,146.29l-5.9-3.4c-.47-.28-.41-.76.13-1.07l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.28.41.75-.14,1.07l-5.85,3.38A2.05,2.05,0,0,1,140.68,146.29Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M131.61,141.06l-5.9-3.41c-.47-.28-.41-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07L133.46,141A2.06,2.06,0,0,1,131.61,141.06Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M122.54,135.82l-5.9-3.41c-.47-.27-.42-.75.13-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,122.54,135.82Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M113.47,130.58l-5.9-3.41c-.48-.27-.42-.75.13-1.06l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,113.47,130.58Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M122.8,125.2l-5.9-3.41c-.48-.28-.42-.75.13-1.07L119,119.6a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06l-1.93,1.12A2.06,2.06,0,0,1,122.8,125.2Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M134.64,132l-5.91-3.41c-.47-.27-.41-.75.14-1.07l1.93-1.11a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07L136.49,132A2.06,2.06,0,0,1,134.64,132Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M143.67,137.25l-5.9-3.41c-.48-.28-.42-.75.13-1.07l1.93-1.12a2.05,2.05,0,0,1,1.85-.07l5.91,3.41c.47.27.41.75-.14,1.06l-1.93,1.12A2.06,2.06,0,0,1,143.67,137.25Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M152.7,142.46l-5.9-3.41c-.47-.27-.41-.75.13-1.06l1.94-1.12a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-1.94,1.12A2.05,2.05,0,0,1,152.7,142.46Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M161.74,147.68l-5.91-3.41c-.47-.27-.41-.75.14-1.07l1.93-1.12a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06l-1.93,1.12A2.06,2.06,0,0,1,161.74,147.68Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M212.17,176.79l-5.9-3.4c-.48-.28-.42-.76.13-1.07l1.93-1.12a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07L214,176.72A2.05,2.05,0,0,1,212.17,176.79Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M221.2,182l-5.9-3.41c-.47-.27-.42-.75.13-1.07l1.94-1.11a2,2,0,0,1,1.84-.08l5.91,3.41c.47.27.41.75-.13,1.07l-1.94,1.11A2.06,2.06,0,0,1,221.2,182Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M230.24,187.22l-5.91-3.4c-.47-.28-.41-.76.14-1.07l1.93-1.12a2.05,2.05,0,0,1,1.85-.07l5.9,3.4c.48.28.42.76-.13,1.07l-1.93,1.12A2.05,2.05,0,0,1,230.24,187.22Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M239.27,192.44l-5.9-3.41c-.48-.27-.42-.75.13-1.07l1.93-1.11a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.27.41.75-.14,1.07l-1.93,1.11A2.06,2.06,0,0,1,239.27,192.44Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M149.8,151.56l-5.9-3.41c-.48-.28-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,149.8,151.56Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M158.92,156.82,153,153.41c-.47-.27-.41-.75.14-1.07L159,149a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,158.92,156.82Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M168,162.08l-5.9-3.4c-.47-.28-.41-.76.13-1.07l5.85-3.38a2.06,2.06,0,0,1,1.85-.08l5.91,3.41c.47.28.41.75-.14,1.07L169.88,162A2.05,2.05,0,0,1,168,162.08Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M177.15,167.35l-5.9-3.41c-.48-.27-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06L179,167.27A2.06,2.06,0,0,1,177.15,167.35Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M186.27,172.61l-5.91-3.41c-.47-.27-.41-.75.14-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,186.27,172.61Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M195.38,177.87l-5.9-3.4c-.47-.28-.41-.76.13-1.07l5.85-3.38a2.09,2.09,0,0,1,1.85-.08l5.91,3.41c.47.28.41.75-.14,1.07l-5.85,3.38A2.05,2.05,0,0,1,195.38,177.87Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M204.5,183.14l-5.9-3.41c-.48-.27-.42-.75.13-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l5.9,3.41c.48.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,204.5,183.14Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M213.62,188.4,207.71,185c-.47-.27-.41-.75.14-1.07l5.85-3.37a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.47.27.41.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,213.62,188.4Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M222.73,193.66l-5.9-3.4c-.47-.28-.41-.76.13-1.07l5.85-3.38a2.09,2.09,0,0,1,1.85-.08l5.9,3.41c.48.28.42.75-.13,1.07l-5.85,3.38A2.05,2.05,0,0,1,222.73,193.66Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M97.68,152.21l-17.39-10c-.47-.27-.41-.75.14-1.07l5.85-3.38a2.05,2.05,0,0,1,1.85-.07l17.38,10c.48.27.42.75-.13,1.06l-5.85,3.38A2.06,2.06,0,0,1,97.68,152.21Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M209.68,216.87l-17.39-10c-.47-.27-.41-.75.14-1.07l5.85-3.37a2,2,0,0,1,1.84-.08l17.39,10c.47.27.42.75-.13,1.07l-5.85,3.37A2.06,2.06,0,0,1,209.68,216.87Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M173.22,207.45l-5.9-3.41c-.48-.28-.42-.75.13-1.07l7-4a2.06,2.06,0,0,1,1.85-.08l5.9,3.41c.48.27.42.75-.13,1.07l-7,4A2.06,2.06,0,0,1,173.22,207.45Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M107,169.22l-9.44-5.45c-.48-.27-.42-.75.13-1.07l7-4a2.05,2.05,0,0,1,1.85-.07l9.44,5.45c.47.27.41.75-.14,1.06l-7,4A2.05,2.05,0,0,1,107,169.22Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M164.44,202.38,155,196.92c-.48-.27-.42-.75.13-1.06l7-4a2.05,2.05,0,0,1,1.85-.07l9.44,5.45c.47.27.41.75-.14,1.07l-7,4A2.09,2.09,0,0,1,164.44,202.38Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M120.17,176.82l-9.44-5.45c-.48-.28-.42-.76.13-1.07l7-4a2.06,2.06,0,0,1,1.85-.08l9.44,5.45c.47.27.41.75-.13,1.07l-7,4A2.06,2.06,0,0,1,120.17,176.82Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M119.74,166.24l41.05,23.69a.57.57,0,0,1,0,1.07l-7.11,4.1a2,2,0,0,1-1.84,0l-41-23.7Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M198.7,217l6.58,3.8c.38.22.34.61-.11.87l-2.85,1.65a1.7,1.7,0,0,1-1.52.06l-6.57-3.8c-.39-.22-.34-.61.1-.87l2.85-1.65A1.7,1.7,0,0,1,198.7,217Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M189.51,211.68l6.57,3.8c.39.22.34.62-.1.87L193.12,218a1.65,1.65,0,0,1-1.51.06L185,214.27c-.39-.23-.34-.62.11-.88l2.85-1.64A1.71,1.71,0,0,1,189.51,211.68Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M180.39,206.42l6.58,3.8c.39.22.34.61-.11.87L184,212.74a1.7,1.7,0,0,1-1.52.06l-6.57-3.8c-.39-.22-.34-.61.11-.87l2.85-1.65A1.65,1.65,0,0,1,180.39,206.42Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M194.54,208.78l6.58,3.79c.39.23.34.62-.11.88l-2.85,1.64a1.71,1.71,0,0,1-1.52.07l-6.57-3.8c-.39-.22-.34-.62.11-.88l2.85-1.64A1.65,1.65,0,0,1,194.54,208.78Z'
        transform='translate(-.4 .4)' />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='230.16 106.47 181.05 78.12 181.68 74.93 230.79 103.28 230.16 106.47'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='212.57 111.86 178.29 92.07 178.92 88.88 213.2 108.67 212.57 111.86'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='209.32 102.22 179.67 85.1 180.3 81.9 209.96 99.03 209.32 102.22'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='228.78 113.45 213.95 104.89 214.58 101.69 229.41 110.26 228.78 113.45'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='175.82 50.27 160.99 41.71 161.63 38.52 176.45 47.08 175.82 50.27'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='174.21 57.69 159.38 49.13 160.02 45.94 174.84 54.5 174.21 57.69'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='246.99 160.59 232.16 152.03 232.79 148.84 247.62 157.4 246.99 160.59'
        />
        <polygon className='aee4cbf5-08ca-46aa-8b74-b18af08a622b' points='170.99 72.53 156.17 63.97 156.8 60.77 171.63 69.33 170.99 72.53'
        />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M203.43,74.42a1.85,1.85,0,0,1-2.67.82,2.32,2.32,0,0,1-.95-2.91,1.85,1.85,0,0,1,2.66-.82A2.32,2.32,0,0,1,203.43,74.42Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M209.82,78.11a1.85,1.85,0,0,1-2.67.82,2.32,2.32,0,0,1-1-2.91,1.85,1.85,0,0,1,2.67-.82A2.32,2.32,0,0,1,209.82,78.11Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M216.2,81.8a1.84,1.84,0,0,1-2.66.82,2.32,2.32,0,0,1-1-2.91,1.85,1.85,0,0,1,2.67-.82A2.32,2.32,0,0,1,216.2,81.8Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M222.59,85.49a1.86,1.86,0,0,1-2.67.82A2.32,2.32,0,0,1,219,83.4a1.84,1.84,0,0,1,2.66-.83A2.34,2.34,0,0,1,222.59,85.49Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M86,258.18s-3.75-1.89-6,.93-5.6,9.46-5.7,13.95,3.55,4.19,5.73,3.12,8.52-10.27,8.9-12.07-.45-4.25-1.06-4.72S86,258.18,86,258.18Z'
        transform='translate(-.4 .4)' />
        <path className='e57a76c5-043a-4ec9-ab30-9a4cecbc11f2' d='M86.77,257.28l.17,3.88a2.18,2.18,0,0,1-1.1,2,4.29,4.29,0,0,1-2.89.45c-2.82-.45-3.08-1.26-3.28-5.14S86.77,257.28,86.77,257.28Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M72.22,250.71s-3.75-1.89-6,.93-5.59,9.46-5.69,13.95,3.55,4.19,5.72,3.13,8.53-10.28,8.91-12.08-.45-4.25-1.06-4.72S72.22,250.71,72.22,250.71Z'
        transform='translate(-.4 .4)' />
        <path className='e57a76c5-043a-4ec9-ab30-9a4cecbc11f2' d='M73,249.81l.18,3.88a2.2,2.2,0,0,1-1.1,2,4.26,4.26,0,0,1-2.89.45c-2.83-.45-3.08-1.26-3.28-5.14S73,249.81,73,249.81Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M106.5,178.49c-1.11-18.06-19.56,2.66-19.56,2.66L74.32,193.52,63,206a11.2,11.2,0,0,0-2.82,9l4.6,35a3.43,3.43,0,0,0,3.45,3,12.79,12.79,0,0,0,1.7-.15,4.28,4.28,0,0,0,3.8-4.54l-.84-32.75,23.87-14.39S107.61,196.55,106.5,178.49Z'
        transform='translate(-.4 .4)' />
        <path className='b2838d86-d2f2-4a5e-9f75-ef4349b8f176' d='M120.22,185.37c-1.1-18.06-19.55,2.66-19.55,2.66L88.05,200.4,76.68,212.89a11.2,11.2,0,0,0-2.83,9l4.6,35a3.45,3.45,0,0,0,3.45,3,12.79,12.79,0,0,0,1.71-.15,4.28,4.28,0,0,0,3.8-4.54l-.82-31.81,23.85-15.33S121.33,203.43,120.22,185.37Z'
        transform='translate(-.4 .4)' />
        <path className='bb6076d6-bfc8-4f51-a86b-8d9dccc107a2' d='M109.31,127.41c14,5.79,13.71,14.06,13.71,14.06s-2.74,30.3-1.81,50.54c.39,8.47-44.95,9.11-38.19-13.46,8-26.62,5-48.16,8.72-51.41S105.7,125.91,109.31,127.41Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M113.81,134.74c-.73,2.83-4.45,4.33-8.31,3.34s-6.39-4.1-5.66-6.94,4.45-4.34,8.31-3.35S114.54,131.9,113.81,134.74Z'
        transform='translate(-.4 .4)' />
        <path className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' d='M108.21,138.14l-2.57,2.79a1.73,1.73,0,0,0-.44,1.34l1.77,15.3a1.66,1.66,0,0,1-.33,1.2L101.1,166a.5.5,0,0,1-.87-.16l-3-9.48a1.6,1.6,0,0,1,0-1.1l5.3-13.89a1.75,1.75,0,0,0,.08-1l-.78-3.68Z'
        transform='translate(-.4 .4)' />
        <path className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' d='M107.89,139.05a10.2,10.2,0,0,1-2.55-.33,9.27,9.27,0,0,1-4.91-3A5.35,5.35,0,0,1,99.2,131c.82-3.19,4.91-4.91,9.12-3.82s7,4.56,6.14,7.75a5.33,5.33,0,0,1-3.38,3.56A8.42,8.42,0,0,1,107.89,139.05Zm-2.13-10.9c-2.6,0-4.77,1.21-5.27,3.15a4.06,4.06,0,0,0,1,3.58,8.47,8.47,0,0,0,9.11,2.34,4,4,0,0,0,2.58-2.65h0c.64-2.48-1.68-5.23-5.18-6.13A9.09,9.09,0,0,0,105.76,128.15Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M103.71,126.77a34.57,34.57,0,0,1-.13,5c-.07,1.39,4.81,2.69,6.07,1.43s.75-2.57,1.06-7.31S103.71,126.77,103.71,126.77Z'
        transform='translate(-.4 .4)' />
        <path className='a7bf1390-3f0b-412c-8ddb-f0d7fbee7fdb' d='M110.71,125.89s-1.2,4.39-7.13,6.08l.09-4Z'
        transform='translate(-.4 .4)' />
        <circle className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' cx='104.04' cy='117.41'
        r='10.76' transform='rotate(-75.59 104.098 117.87)' />
        <path className='a7bf1390-3f0b-412c-8ddb-f0d7fbee7fdb' d='M106.51,117.81s-5.93,2.54-10,2.57-3.2-3.41-3.2-3.41l12.18-1.76Z'
        transform='translate(-.4 .4)' />
        <path className='be56601e-e15b-49a5-a24b-08c3ad6fdbed' d='M95.25,118.48s5.17-2.2,9.7-1.28a4.7,4.7,0,0,1,2.73,1.62,1.61,1.61,0,0,0,.83.6c.8.19,2.12-3.15,3.68-3.22s2.6,1,1.32,3.08-.84,4.34-.25,4.67,7.87-4.76,2.36-15.68-20.55-4.73-22.72-2.52c0,0-2.3,2-4.16.22C88.74,106,86.65,121.63,95.25,118.48Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M115.17,163.61v8.19a3.3,3.3,0,0,1-1.09,2.46L97.26,189.6l1.91,2.92,23.18-15.19a4.52,4.52,0,0,0,1.84-3.19L125.77,159Z'
        transform='translate(-.4 .4)' />
        <path className='bb6076d6-bfc8-4f51-a86b-8d9dccc107a2' d='M121.83,137.37s5.11,7.68,7,27.88c0,0-8.72,2.82-13.67,1.22S121.83,137.37,121.83,137.37Z'
        transform='translate(-.4 .4)' />
        <path className='be56601e-e15b-49a5-a24b-08c3ad6fdbed' d='M114.24,147.08s-2,10.69-2.11,15.72a3.21,3.21,0,0,0,2.11,3.1h0Z'
        transform='translate(-.4 .4)' />
        <path className='bdcec331-4e1f-4102-8b75-4c3f2ff483c2' d='M109.88,200.25l-3.72-.49-24-13.86a3.35,3.35,0,0,0-3,.13l-17,9.81-2.25-.3v2.24h0a.87.87,0,0,0,.52.79l27.2,15.7a3.32,3.32,0,0,0,3-.12l18.52-10.69a1.23,1.23,0,0,0,.74-1h0Z'
        transform='translate(-.4 .4)' />
        <path className='b5479636-846f-491a-ad5a-ec8364e29f1c' d='M91.84,191.49l-1.7-1H71.39l-9.24,5.33-2.25-.3v2.24h0a.87.87,0,0,0,.52.79l27.2,15.7a2.82,2.82,0,0,0,1.55.3Z'
        transform='translate(-.4 .4)' />
        <path className='aae4131c-fdda-4c3c-a942-2995b2e4e761' d='M60.9,195.68l-1-.14v2.24h0a.87.87,0,0,0,.52.79l.48.28Z'
        transform='translate(-.4 .4)' />
        <path className='bdcec331-4e1f-4102-8b75-4c3f2ff483c2' d='M87.89,214.4a3.44,3.44,0,0,0,2.55-.16v-3.8H87.89Z'
        transform='translate(-.4 .4)' />
        <path className='e0f334b8-51a1-4f02-8b0e-d7324000dc64' d='M87.62,212l-27.2-15.71c-.77-.44-.68-1.22.21-1.73l18.52-10.69a3.34,3.34,0,0,1,3-.12l27.2,15.7c.77.45.67,1.22-.22,1.73L90.62,211.91A3.33,3.33,0,0,1,87.62,212Z'
        transform='translate(-.4 .4)' />
        <path className='a3aafb29-72a2-404a-a08d-20ed25fff3aa' d='M87.16,209.66,64.54,196.6c-.64-.37-.56-1,.18-1.44l9.93-5.58a2.78,2.78,0,0,1,2.49-.11l22.62,13.06c.64.37.56,1-.18,1.44l-9.93,5.58A2.75,2.75,0,0,1,87.16,209.66Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M80.21,183.4l-.29,1.87a13.94,13.94,0,0,0,.13,4.82l.33,1.65a2.34,2.34,0,0,0,1.87,1.83h0a1.29,1.29,0,0,0,1.5-1.12l.45-4s1,1.35,1.52,1.35-.55-4-1.67-6.29S80.21,183.4,80.21,183.4Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M89.43,153.57l-2.92,5.77a11.41,11.41,0,0,0-1.2,4.39l-1.26,19.76-3.84-.09-1.75-22.19a8.25,8.25,0,0,1,.1-2.05l2-11.26Z'
        transform='translate(-.4 .4)' />
        <path className='b7179b59-ee50-4312-8412-231a7736343d' d='M86.7,213.55l-2.28-3-24-13.89A3.32,3.32,0,0,1,59,194l0-19.64-1.38-1.8,1.3-.75h0a.88.88,0,0,1,.94.06L87.1,187.63a3.32,3.32,0,0,1,1.39,2.66l0,21.38a1.21,1.21,0,0,1-.51,1.14h0Z'
        transform='translate(-.4 .4)' />
        <path className='b5479636-846f-491a-ad5a-ec8364e29f1c' d='M70.11,202.29l-1.7-1-9.36-16.25V174.38l-1.38-1.8,1.3-.75h0a.88.88,0,0,1,.94.06L87.1,187.63a2.83,2.83,0,0,1,1,1.19Z'
        transform='translate(-.4 .4)' />
        <path className='aae4131c-fdda-4c3c-a942-2995b2e4e761' d='M58.3,173.38l-.62-.8,1.3-.75h0a.88.88,0,0,1,.94.06l.48.28Z'
        transform='translate(-.4 .4)' />
        <path className='bdcec331-4e1f-4102-8b75-4c3f2ff483c2' d='M87.35,187.8a3.47,3.47,0,0,1,1.13,2.29l-2.65,1.53-1.27-2.21Z'
        transform='translate(-.4 .4)' />
        <path className='b5554f9c-4056-4298-a862-286a6311167b' d='M85.81,188.38,58.63,172.64c-.77-.45-1.4,0-1.4,1.05l0,21.38a3.35,3.35,0,0,0,1.39,2.67l27.18,15.74c.77.44,1.39,0,1.39-1.06l0-21.38A3.32,3.32,0,0,0,85.81,188.38Z'
        transform='translate(-.4 .4)' />
        <path className='b2e74428-ed8f-4a1d-b71b-2a211b3e7afa' d='M99.17,192.52l-.43.91A6,6,0,0,1,96.46,196l-2.7,1.66a2.66,2.66,0,0,1-2.75.06l-.09,0a1.63,1.63,0,0,1-.23-2.5l2.82-2.9s-1.59,1.22-2,.73,1.54-2.11,3-2.17a5.65,5.65,0,0,0,2.81-1.18Z'
        transform='translate(-.4 .4)' />
        <path className='bb6076d6-bfc8-4f51-a86b-8d9dccc107a2' d='M92,127.1S81.68,138.35,77.5,148.67a21.12,21.12,0,0,0,12.2,8.88C98.35,160.05,92,127.1,92,127.1Z'
        transform='translate(-.4 .4)' />
        <path className='be56601e-e15b-49a5-a24b-08c3ad6fdbed' d='M87.75,156.88l1.68-16.2-4,15.18C86.18,156.22,86.94,156.56,87.75,156.88Z'
        transform='translate(-.4 .4)' />
        <path className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' d='M99.77,124.82,97,124.06a6.66,6.66,0,0,0,3.26,2.62c1.94.53,2.24-1.11,2.24-1.11Z'
        transform='translate(-.4 .4)' />
        <circle className='e767fcf4-6218-4914-ad59-0fdb4b1eed2d' cx='112.66' cy='120.78'
        r='1.55' />
    </g>
</svg>

</Background>
  </React.Fragment>
    )
  }
}
GirlMacbook.propTypes = {
  widthWindow: PropTypes.string
};

export default GirlMacbook;
