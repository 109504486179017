import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressBarStyle = styled.div`
position: relative;
height: 30px;
width: 200;
border-radius: 50px;
border; 1px solid #333
`;

const Filler = styled.div`
background: #2b3e7f;
height: 30px;
border-radius: inherit;
transition: width .2s ease-in;
`;

class ProgressBar extends React.Component {

  render() {
    const {uploadPercentage} = this.props;

    return (
     <ProgressBarStyle>
     <Filler style={{ width: `${uploadPercentage}%`}} />
     </ProgressBarStyle>
    )
  }
}

ProgressBar.propTypes = {
  uploadPercentage: PropTypes.number,
}

export default ProgressBar;
