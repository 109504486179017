import React from "react";
import PropTypes from "prop-types";
import { auth } from "../../firebase";
import * as ROUTES from "../../config/routes";
import Grid from "@material-ui/core/Grid";
import SignUpLink from "../signup/SignUpLink";
import PasswordForgetLink from "./PasswordForgetLink";
import { ErrorContainer } from "../../styling/Styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/styles";

const codeToMessage = {
  "auth/invalid-email": "Email Inválido",
  "auth/user-disabled": "Usuario Deshabilitado",
  "auth/user-not-found": "Usuario Inexistente",
  "auth/wrong-password": "Contraseña Incorrecta",
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignInForm extends React.Component {
  state = {
    email: "",
    password: "",
    error: null,
  };

  redirect = (link) => {
    this.props.history.push(link);
  };

  onSubmit = (event) => {
    const { email, password } = this.state;
    const { history } = this.props;
    auth
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        if (authUser.user.emailVerified) {
          history.push(ROUTES.LANDING);
        } else {
          authUser.user
            .sendEmailVerification()
            .then(() => this.setState({ notVerified: true }))
            .catch((error) => error);
        }
      })
      .catch((error) => {
        this.setState({ error: codeToMessage[error.code] });
      });
    event.preventDefault();
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { email, password, error } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" color="secondary" variant="h5">
            Iniciar Sesión
          </Typography>
          <Grid
            container
            className={classes.form}
            onSubmit={this.onSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              variant="outlined"
              margin="normal"
              label="Email"
              autoComplete="email"
              fullWidth
              autoFocus
              required
              className="signin"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              id="email"
              htmlFor="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              label="Password"
              type="password"
              autoComplete="current-password"
              required
              fullWidth
              className="signin"
              name="password"
              value={password}
              onChange={this.onChange}
              id="password"
              htmlFor="password"
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordar"
            />
            <ErrorContainer>{error}</ErrorContainer>
            {this.state.notVerified && (
              <div>
                <ErrorContainer>
                  Tu email no está verificado, revisa tu correo y sigue el link
                  para verificarlo
                </ErrorContainer>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              id="submit"
              value="Iniciar sesión" //LO Q SE ESCRIBE
              className={classes.submit}
              onClick={this.onSubmit}
            >
              Iniciar Sesión
            </Button>
          </Grid>
        </div>
        <Box
          pt={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <PasswordForgetLink link={this.redirect} />
          </Box>
          <Box pt={1}>
            <SignUpLink link={this.redirect} />
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="body2" color="primary" align="center">
            <strong>
              {"Copyright © "}
              <Link color="primary" href="https://www.binnario.ai">
                www.binnario.ai
              </Link>
              {"  "}
              {new Date().getFullYear()}
              {"."}
            </strong>
          </Typography>
        </Box>
      </Container>
    );
  }
}

SignInForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default withStyles(styles)(SignInForm);
