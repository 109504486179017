import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { auth } from "../firebase/firebase";
import { db } from "../firebase";
import * as ROUTES from "../config/routes";
import ErrorData from "../Data/ErrorData";
import NotYetView from "../components/NotYetView/NotYetView";
import Footer from "../components/Footer";

const styles = () => ({
  mainBox: {
    width: "70%",
    margin: "0 auto",
  },
  suscribedUserBox: {
    width: "300px",
    margin: "0 auto",
  },
  middle: {
    margin: "0 auto",
  },
  button: {
    width: "250px",
    margin: "0 auto",
  },
  circularProgressGrid: {
    height: window.innerHeight - 100,
  },
});

class SuscribeToCourse extends React.Component {
  constructor() {
    super();
    this.state = {
      suscriptions: {},
      challenge: undefined,
      suscribedUsers: {},
      isUserSuscribed: true,
      isLoading: true,
      challengeError: false,
      adminError: false,
      hasError: false,
      adminTryingToSuscribeError: false,
    };
  }
  async componentDidMount() {
    let challengeId = this.props.match.params.id;
    try {
      const challenge = await db.getChallenge(challengeId);
      console.log(challenge.val());
      //if challenge doesnt exist
      if (challenge.val() === null) {
        this.setState({ challengeError: true, isLoading: false });
      }
      if (challenge.val().emailPreference) {
        let matchEmail = auth.currentUser.email.search(
          challenge.val().emailPreference
        );
        // it will always return a negative number when the search doesnt match
        if (matchEmail == -1) {
          this.setState({ challengeError: true, isLoading: false });
        } else {
          this.setState({
            challenge: { ...challenge.val() },
            isLoading: false,
          });
        }
      }
      //admin cannot suscribe to its challenge
      // if (isAdmin)
      //   this.setState({ adminTryingToSuscribeError: true, isLoading: false });
      else {
        this.setState({
          challenge: { ...challenge.val() },
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }

    try {
      let suscriptionsBychallenge = await db.getSuscriptionsByChallenge(
        this.props.match.params.id
      );
      if (suscriptionsBychallenge.val() === null)
        //if there is no one suscribed yet
        this.setState({ isUserSuscribed: false, isLoading: false });
      else {
        await db
          .getSuscriptionsByChallenge(challengeId)
          .then((snapshot) => this.getSuscriptions(snapshot))
          .catch((error) => error);
      }
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }
  }

  getSuscriptions = (snapshot) => {
    let suscribedUsers = [];
    let suscriptions = [];
    snapshot.forEach((snapshot) => {
      suscriptions.push(snapshot.val());
    });
    suscriptions.forEach(async (suscription) => {
      suscribedUsers.push(suscription.userId);
      this.setState({ suscribedUsers, isLoading: false });
    });
    suscribedUsers.forEach(async (suscribedUser) => {
      let userId = auth.currentUser.uid;
      if (userId === suscribedUser) {
        this.setState({ isUserSuscribed: true, isLoading: false });
      } else {
        this.setState({ isUserSuscribed: false, isLoading: false });
      }
    });
  };

  onSubmit = async () => {
    const { history } = this.props;
    let challengeId = this.props.match.params.id;
    let userId = auth.currentUser.uid;
    if (!this.state.isUserSuscribed) {
      await db.suscribeToChallenge(challengeId, userId);
      await history.push(ROUTES.LANDING);
    }
  };

  render() {
    const { classes } = this.props;
    let NotLoggedText = ErrorData.errors.NotLogged;
    let alreadySuscribedText = ErrorData.errors.alreadySuscribed;
    let challengeErrorText = ErrorData.errors.challenge;
    let ErrorText = ErrorData.errors.error;
    let adminTryingToSuscribeErrorText =
      ErrorData.errors.adminTryingToSuscribeError;

    if (this.state.isLoading) {
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.circularProgressGrid}
          >
            <CircularProgress size={100} />
          </Grid>
        </div>
      );
    }
    if (!auth.currentUser) {
      return (
        <div>
          <NotYetView NotYetText={NotLoggedText} />
          <Footer />
        </div>
      );
    }
    if (this.state.challengeError) {
      return (
        <div>
          <NotYetView NotYetText={challengeErrorText} />
          <Footer />
        </div>
      );
    }
    if (this.state.adminTryingToSuscribeError) {
      return (
        <div>
          <NotYetView NotYetText={adminTryingToSuscribeErrorText} />
          <Footer />
        </div>
      );
    }
    if (this.state.hasError) {
      return (
        <div>
          <NotYetView NotYetText={ErrorText} />
          <Footer />
        </div>
      );
    }
    if (this.state.isUserSuscribed) {
      return (
        <div>
          <Box
            display="flex"
            justifyContentContent="center"
            flexDirection="column"
          >
            <NotYetView NotYetText={alreadySuscribedText} />
            <Box pt={2} pb={16} display="flex" justifyContentContent="center">
              <Button
                size="large"
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => this.props.history.push(ROUTES.LANDING)}
              >
                Volver a Inicio
              </Button>
            </Box>
          </Box>
          <Footer />
        </div>
      );
    }
    return (
      <div>
        <Box
          display="flex"
          justifyContentContent="center"
          py={10}
          className={classes.mainBox}
        >
          {this.state.challenge && (
            <div>
              <Box display="flex" justifyContentContent="center" pb={2}>
                <img
                  src={this.state.challenge.logoUrl}
                  alt="LogoCompania"
                  className={classes.logo}
                  width="200px"
                />
              </Box>
              <Box maxWidth={800} display="flex" flexDirection="column">
                <Typography
                  gutterBottom
                  align="center"
                  color="primary"
                  variant="body1"
                >
                  <strong> Binnario</strong> te invita a participar del desafio
                </Typography>
                <Typography
                  gutterBottom
                  align="center"
                  color="primary"
                  variant="h3"
                >
                  <strong>{this.state.challenge.name}</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="center"
                  color="primary"
                  variant="body1"
                >
                  {this.state.challenge.shortDescription}
                </Typography>
              </Box>
              <Box display="flex" justifyContentContent="center" pt={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={this.onSubmit}
                  className={classes.button}
                >
                  Inscribirme
                </Button>
              </Box>
            </div>
          )}
        </Box>
        <Footer />
      </div>
    );
  }
}

SuscribeToCourse.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
  challengeId: PropTypes.number,
  variant: PropTypes.string.isRequired,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default withStyles(styles)(withRouter(SuscribeToCourse));
