import React from "react";
import PropTypes from "prop-types";
import { db } from "../firebase";
import { auth } from "../firebase/firebase";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NotYetData from "../Data/ErrorData";
import NotYetView from "../components/NotYetView/NotYetView";
import Footer from "../components/Footer";

const styles = () => ({
  middle: {
    margin: "0 auto",
  },
  button: {
    width: "250px",
    margin: "0 auto",
  },
  circularProgressGrid: {
    height: window.innerHeight - 100,
  },
});

class ChallengeInvitation extends React.Component {
  constructor() {
    super();
    this.state = {
      challenges: {},
      challenge: undefined,
      isLoading: true,
      hasError: false,
    };
  }

  async componentDidMount() {
    try {
      const challenge = await db.getChallenge(this.props.match.params.id);

      if (challenge.val() === null)
        this.setState({ hasError: true, isLoading: false });
      else
        this.setState({
          challenge: { ...challenge.val() },
          isLoading: false,
        });
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }
  }

  render() {
    let challengeId = this.props.match.params.id;
    console.log(this.state.challenge, ";challe");
    const { classes } = this.props;
    let NotLogged = NotYetData.errors.NotLogged;

    if (!auth.currentUser) {
      return (
        <div>
          <NotYetView NotYetText={NotLogged} />
          <Footer />
        </div>
      );
    }
    return (
      <div>
        {this.state.isLoading && (
          <Box display="flex" justifyContent="center" pt={15}>
            <CircularProgress size={100} />
          </Box>
        )}
        {this.state.hasError && (
          <Box display="flex" justifyContent="center" pt={15}>
            <p>Ocurrió un error cargando el link de la oferta de trabajo</p>
          </Box>
        )}
        {this.state.challenge && (
          <div>
            <Box display="flex" justifyContent="center" pt={20} pb={10}>
              {this.state.challenge && (
                <div>
                  <Typography
                    gutterBottom
                    align="center"
                    color="primary"
                    variant="body1"
                  >
                    Comparte este link para invitar al desafío
                  </Typography>
                  <Box display="flex" justifyContent="center" pb={2}>
                    <img
                      src={this.state.challenge.logoUrl}
                      alt="LogoBinnarioSchool"
                      className={classes.logo}
                      width="200px"
                    />
                  </Box>
                  <Typography
                    gutterBottom
                    align="center"
                    color="secondary"
                    variant="body1"
                  >
                    <strong> {this.state.challenge.name}</strong>
                  </Typography>
                  <Typography
                    gutterBottom
                    align="center"
                    color="primary"
                    variant="h5"
                  >
                    <strong>
                      www.binnario.ai/challenge_suscription/{challengeId}
                    </strong>
                  </Typography>
                </div>
              )}
            </Box>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

ChallengeInvitation.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
  challengeId: PropTypes.number,
  variant: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default withStyles(styles)(ChallengeInvitation);
