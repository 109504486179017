import React from 'react';
import { db } from '../firebase';
import processChallenge from '../helpers/processChallengeInfo';

class TextParser extends React.Component {
  state = {
    components: [],
  }

  componentDidMount(){
    db.getChallenge('-LYYXAsuRghYCPE7sRh_')
    .then(snapshot => {
      const components = processChallenge(snapshot.val().description);
      this.setState({components});
    })
    .catch(error => error)
  }

  render() {
    return(
      <div>
      {this.state.components.map((component) => {
        if(typeof(component.tag) === 'string')
          return <div key={component.key} dangerouslySetInnerHTML={{__html: component.tag}}/>
        else
          return component.tag
      })}
      </div>
    )
  }
}

export default TextParser;
