
const infoChallenges = {

  infoChallenge1: {
    desc: "El área de Logística y Supply Chain de Walmart Chile convoca a Data Scientists o empresas afines a participar del desafío de mejorar la asignación de productos a tiendas para una campaña de mercadería general de Walmart Chile, basada en la estimación de demanda de cada producto. Se espera que los participantes entreguen como solución un algoritmo o modelo matemático que sea capaz de disminuir el error entre el valor real y el pronosticado de la venta de cada producto antes de ser liquidado. \n\nLa asignación de productos a tiendas es un proceso que consiste en definir el número de productos a enviar a cada una de las tiendas de Walmart Chile para un período específico (duración de la campaña) para: maximizar las ventas al precio definido, evitar los quiebres de stock, minimizar los saldos en tienda al final de la campaña y minimizar los “remates” a un precio inferior al precio de campaña (productos liquidados). \n\nSe entiende como mercadería general a cualquier producto minorista que no sea alimentos, comestibles o consumibles, por ejemplo, vestuario, electrodomésticos, juguetes, deporte, artículos de cama, entre otros. \n\nLos participantes deberán entregar sus predicciones de venta a precio completo (antes de liquidar) para 11 productos en 88 tiendas, para la campaña de invierno de mercadería general que duró desde el 01 de abril hasta el 18 de julio del 2018. \n\nLos datos para este desafío estarán disponibles para descargar en la sección de descarga de datos y considerarán:\n\n\t- Datos históricos de ventas e inventarios previos a la campaña mencionada.\n\n\t- Más de 600 SKUs de distintas categorías de productos.\n\n- Se indicarán los 11 SKUs a predecir sus ventas.\n\n",
    prize:
      "1er lugar: USD 20.000, (USD 10.000 al momento de ser seleccionado ganador y USD 10.000 al dejar operativo el modelo en formato piloto para su uso en una campaña real)\n\n2do Lugar: USD 3.000 (en un solo pago)\n\n3er Lugar: USD 2.000 (en un solo pago)\n\nEl premio será entregado cuando se valide el modelo, set de datos, metodología y que cumpla con los requisitos mínimos descritos en la sección de método de evaluación.",
    evaluationPart1: "Los resultados de las predicciones de venta (estimaciones de demanda) para la campaña entregados por los participantes, serán comparados con lo que realmente fue vendido de los 11 SKUs analizados, determinando un ranking de los participante en función del error del resultado entregado.\n\n Los 11 SKUs para los cuales se debe estimar la demanda son los siguientes: 3, 4, 14, 40, 66, 70, 168, 173, 316, 415, 475. Es importante notar que no todos los productos se venden en todas las tiendas.\n\nPara determinar el error de las ventas predichas por cada participante, se utilizará el volume weighted MAPE, detallado a continuación:",
    evaluationImage: "/images/mapeFormula.jpeg",
    evaluationPart2: "Donde: \n- d = {1, 2, 3, ..., n} días de duración de la promoción\n- n = 109\n- t = {1, 2, 3, ..., T} tiendas\n- T = 88\n- p = {1, 2, 3, …, 11} SKUs a evaluar\n- Vp,t,d = Ventas del producto p en tienda t para día d\n- Pp,t,d = Venta predicha del producto p en tienda t para día d\n- Wp,t,d = Peso otorgado a cada producto (cantidad de unidades vendidas del producto p en tienda t en día d\n\n- Se considerarán únicamente los días que:\n - No se genere un quiebre de stock\n- Se venda al precio completo de campaña (sin liquidar)\n\nEn base al ranking de los participantes se pre-seleccionará a un grupo de finalistas, quienes deberán presentar sus modelos a un jurado compuesto por el equipo de Innovación de Logística y Supply Chain junto con expertos en Data Science de Walmart Chile e Innspiral, en donde se deben mostrar tanto los elementos técnicos del modelo entregado como una “narrativa” que permita entender las lógicas y enfoques detrás de esos resultados. \n\nSegún la evaluación realizada por el jurado se definirán los tres ganadores del concurso.\n\nSe valorará que se consideren variables adicionales de las entregadas en la base de datos en la plataforma.\n\nRequisitos mínimos para ser ganador:\n1. Presentar un “error” no superior al 30%. Es decir, su modelo permite predecir las ventas con un volume weighted MAPE no superior a 30%.\n2. El modelo presentado debe ser entregado con una explicación que permita ser utilizado por ejecutivos de Walmart.\n3. El interés y la factibilidad por parte del o los ganadores de realizar un piloto que permita validar el modelo en la operación real\n4. Evidenciar que el modelo utilizado fue construido utilizando la data entregada y/o con data pública.\n5. Estar presente en Chile al momento de presentar el modelo al jurado finalista y en la etapa posterior de ejecución.\n6. Tener más de 18 años cumplidos al 1 de enero de 2019",
    instructions: "Los participantes deberán entregar un archivo (.csv) con su predicción de demanda diaria para cada producto por tienda por la duración de la campaña, para cada uno de los 11 SKUs. Esto deberá ser completado en la tabla adjunta en este link.\n\nCada entrega deberá seguir el siguiente formato de nombre de archivo, indicando el nombre del participante, fecha de entrega y versión: Nombre_YYYYMMDD_Vx.csv",
    fqa: "De dónde obtengo los datos? \nR: Los datos estarán disponibles para su descarga en la plataforma www.binnario.com\n\nEn qué formato serán entregados los datos? \nR: Los datos serán entregado en archivos .csv.\n\nPuedo correr el algoritmo de solución en la plataforma?\nR: No, solo se podrá subir los archivos referentes a los resultados de los modelos. Se recomienda tener el modelo en máquinas propias.\n\nCómo se protege la propiedad intelectual de los datos?\nR: Esto se describe en las Políticas de Privacidad de Binnario.\n\nPuedo participar solo o como equipo?\nR: Los postulantes pueden ser personas naturales (individualmente o como equipo de trabajo) o empresas.\n\nQuiénes pueden participar?\nR: Personas naturales o jurídicas, nacionales o extranjeras, que sean capaces de resolver el desafío propuesto y que hayan creado un perfil en la plataforma www.binnario.com\n\nSlack\n También puedes realizar tus consultas en el canal de Slack: https://binnario-innspiral.slack.com/ \n\nOtras consultas\n Correo electrónico: openinnovation@binnario.com",
    calendar: "1) Período de concurso: 11 de enero de 2019 al 28 de febrero de 2019\n2) Selección grupo finalista y notificación: primera semana de marzo de 2019\n3) Presentaciones al jurado: tercera semana de marzo 2019\n4) Notificación de resultados: tercera semana de marzo 2019",
    termsAndConditions: "/documents/BasesConvocatoriaAbiertaAnalíticaPredictivaWalmartChile.pdf",
    submissionExample: "/documents/EjemploEntrega.csv",
    dataTables: [
      {
        name: "Data",
        columns: [
          { name: 'Fecha (Índice)' },
          { name: 'Local (Índice)' },
          { name: 'Item (Índice)' },
          { name: 'Unidades: Unidades vendidas' },
          { name: 'Venta: En valor (p*q)' },
          { name: 'Inventario: Unidades (On_hand)' },
        ]
      },
      {
        name: "Local",
        columns: [
          { name: 'Local (Índice)' },
          { name: 'Región' },
          { name: 'Grupo (Tamaño del local: 1 es el más grande a 7 el más pequeño)' },
          { name: 'Comuna' },
        ]
      },
      {
        name: "Item",
        columns: [
          { name: 'Item (Índice)' },
          { name: 'Capacidad (Cantidad de unidades que caben en una caja)' },
          { name: 'L1 (departamento)' },
          { name: 'L2 (categoría)' },
          { name: 'L3 (subcategoría)' },
          { name: 'L4 (fineline)' },
        ]
      },
      {
        name: "Campaña",
        columns: [
          { name: 'Item (Índice)' },
          { name: 'Local (Índice)' },
          { name: 'Id_campaña' },
          { name: 'Fecha_inicio' },
          { name: 'Fecha_termino' },
        ]
      },
      {
        name: "Precios_promocionales: Son los precios completos de los productos de los que se debe predecir la venta.",
        columns: [
          { name: 'Item' },
          { name: 'Precio Promocional' },
        ]
      },
    ],
    ddbb: "base de datos para descargar, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    example: "Los resultados de las asignaciones deberán ser entregadas en un formato .csv con la estructura que se indica en el archivo adjunto",
  }
};

export default infoChallenges;
