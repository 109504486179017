import React from 'react';

const styleToTag = {
  BOLD: '<span style="font-weight: bold;">',
  ITALIC: '<span style="font-style: italic;">',
  UNDERLINE: '<span style="text-decoration: underline;">',
  LINK: '<span style="color: #3b5998; text-decoration: underline;">',
}

const entityToTagClose = {
  image: '<img/>',
  LINK: '</a>',
}

const typeToTagOpen = {
  'unordered-list-item': '<li style="margin-left: 2em;">',
  'ordered-list-item': '<span style="font-family: inherit; margin: 0 2em;">',
  'code-block': '<span style="margin-left: 2em; font-family: \'Inconsolata\', monospace;">',
  'header-one': '<h1>',
  'header-three': '<h3>',
  'header-five': '<h5>',
  'unstyled': '<div>',
  'atomic': '<div>',
}

const typeToTagClose = {
  'unordered-list-item': '</li>',
  'ordered-list-item': '</span>',
  'code-block': '</span>',
  'header-one': '</h1>',
  'header-three': '</h3>',
  'header-five': '</h5>',
  'unstyled': '</div>',
  'atomic': '</div>',
}

export default function processChallenge(challengeData) {
  let components = [];
  let orderedListCounter = 1;
    //updating new rules of eslint, ('no prototype-builtins')
    //if(block.text.trim() === '' && !block.hasOwnProperty('entityRanges')) old version

  challengeData.blocks.forEach((block) => {
    let entityRanges = Object.prototype.hasOwnProperty.call(block, 'entityRanges');
    let inlineStyleRanges = Object.prototype.hasOwnProperty.call(block, 'inlineStyleRanges');
    let closingTag = '</div>';
  
    if(block.text.trim() === '' && !entityRanges){
      components.push({key: block.key, tag: <br key={block.key}/>, type: block.type});
    }
    else {
      let stringComponent = '';
      if (block.type === 'ordered-list-item'){
        stringComponent += typeToTagOpen[block.type] + `${orderedListCounter}. `;
        closingTag = typeToTagClose[block.type];
        orderedListCounter++;
      }
      else {
        orderedListCounter = 1;
        stringComponent += typeToTagOpen[block.type];
        closingTag = typeToTagClose[block.type];
      }
      for (let i in block.text) {
        let closeInline = false;
        let closeEntity = false;
        // Assign inline styles such as bold, italic, code, etc
        //updateing new rules of eslint, ('no prototype-builtins')
        //if(block.hasOwnProperty('inlineStyleRanges')) old version
        if(inlineStyleRanges) {
          block.inlineStyleRanges.forEach((inlineStyle) => {
            if (parseInt(i) === inlineStyle.offset) {
              stringComponent += styleToTag[inlineStyle.style]
            }
            if (parseInt(i) === inlineStyle.offset + inlineStyle.length - 1){
              if(!closeInline){ // Can be already closed. It can happen when the text has 2 or more styles
                closeInline = true;
                stringComponent += block.text[i];
                stringComponent += '</span>';
              }
            }
          })
        }
        // Assign entities such as links and images 
        //updateing new rules of eslint, ('no prototype-builtins')
        //if  (block.hasOwnProperty('entityRanges')) old version
        if(entityRanges)
         {
          block.entityRanges.forEach((entity) => {
            let type = challengeData.entityMap[entity.key].type;
            if(parseInt(i) === entity.offset) {
              if(type === 'image') {
                stringComponent += '<img style="width: 100%" src="' + challengeData.entityMap[entity.key].data.src + '" alt="inserted image"/>';
              }
              if(type === 'LINK') {
                stringComponent += '<a href="' + challengeData.entityMap[entity.key].data.url + '">';
              }
            }
            if (parseInt(i) === entity.offset + entity.length - 1){
              if(!closeEntity) { // Can be already closed. It can happen when the text has 2 or more entities
                closeEntity = true;
                if(!closeInline) // Check if the inline tag has been closed so the character (block.text[i]) is not duplicated
                  stringComponent += block.text[i];
                stringComponent += entityToTagClose[type];
              }
            }
          })
        }
        if(!closeInline && !closeEntity)
          stringComponent += block.text[i];
      }
      stringComponent += closingTag;
      components.push({key: block.key, tag: stringComponent, type: block.type});
    }
  });
  return components;
}
