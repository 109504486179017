import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Hidden,
  Box,
  Typography,
} from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "../../Dialog/Dialog";
import DialogsData from "../../../Data/DialogsData";
import RolesContext from "../../../session/RolesContext";
import { db } from "../../../firebase";
import { auth } from "../../../firebase/firebase";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  table: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "1em",
  },
  text: {
    fontWeight: "normal",
    //fontSize: '25px',
    color: theme.palette.primary.main,
    fontSize: "1em",
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    marginLeft: "7%",
    color: theme.palette.secondary.main,
    fontSize: "1em",
    textAlign: "center",
  },
  box: {
    border: "1px solid grey",
    padding: "10px",
    textAlign: "left",
    backgroundColor: theme.palette.grey["50"],
    boxShadow: `1px 1px 1px ${theme.palette.grey["400"]}`,
    borderRadius: "4px",
  },
  linkColor: {
    color: "#31B5C4",
  },
});

class SubmissionList extends React.Component {
  state = {
    userSubmissions: [],
    noSubmissionsYet: false,
    isLoading: true,
    noSubmissionsOnChallenge: false,
    selectedSubmission: null,
    newSelectedSubmission: null,
    submissionChanged: false,
    challengeId: null,
  };

  async componentDidMount() {
    if (this.props.challenge.type !== "old-binnario") {
      try {
        let userSubmissions = await db.getUserSubmissions(auth.currentUser.uid);
        if (userSubmissions.val() === null)
          this.setState({ noSubmissionsYet: true, isLoading: false });
        else {
          await db
            .getUserSubmissions(auth.currentUser.uid)
            .then((snapshot) => this.getSubmissionList(snapshot))
            .catch((error) => error);
        }
      } catch (error) {
        this.setState({ hasError: true, isLoading: false });
      }
    }
  }

  getSubmissionList = (snapshot) => {
    let userSubmissions = [];
    snapshot.forEach((snapshot) => {
      if (snapshot.val().winner === true) {
        this.setState({ selectedSubmission: snapshot.key });
      }
      let challengeId = snapshot.val().challengeId;
      if (challengeId === this.props.challenge.id) {
        this.setState({ challengeId });
        if (
          snapshot.val().scoreMinor === undefined &&
          snapshot.val().scoreMayor === undefined
        ) {
          let addEmptyScore = { scoreMinor: "-", scoreMayor: "-" };
          db.updateUserSubmission(
            auth.currentUser.uid,
            snapshot.key,
            addEmptyScore
          );
          db.updateChallengeSubmission(
            challengeId,
            snapshot.key,
            addEmptyScore
          );
          db.updateSubmission(snapshot.key, addEmptyScore);
        }
        userSubmissions.push({ ...snapshot.val(), submissionId: snapshot.key });
      } else {
        this.setState({ noSubmissionsOnChallenge: true, isLoading: false });
      }
    });
    this.setState({ userSubmissions, isLoading: false });
  };

  setSubmissionState(event) {
    if (this.props.challenge.isActive) {
      this.setState({ newSelectedSubmission: event.target.value });
      let newWinner = { winner: true };
      let notWinnerAnymore = { winner: false };
      db.updateUserSubmission(
        auth.currentUser.uid,
        event.target.value,
        newWinner
      );
      db.updateUserSubmission(
        auth.currentUser.uid,
        this.state.selectedSubmission,
        notWinnerAnymore
      );
      db.updateChallengeSubmission(
        this.state.challengeId,
        event.target.value,
        newWinner
      );
      db.updateChallengeSubmission(
        this.state.challengeId,
        this.state.selectedSubmission,
        notWinnerAnymore
      );
      db.updateSubmission(event.target.value, newWinner);
      db.updateSubmission(this.state.selectedSubmission, notWinnerAnymore);
      this.setState({ submissionChanged: true });
    }
  }

  closeDialog = () => {
    this.setState({ submissionChanged: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box pb={30}>
        {this.props.challenge.isActive ? (
          <Dialog
            open={this.state.submissionChanged}
            closeDialog={this.closeDialog}
            dialogTitle={DialogsData.updateWinnerSubmission.dialogTitle}
            dialogContent={DialogsData.updateWinnerSubmission.dialogContent}
            buttonContent={DialogsData.updateWinnerSubmission.buttonContent}
            refresh={true}
          />
        ) : null}
        <RadioGroup
          name="setSubmissionState"
          value={this.state.newSelectedSubmission}
          onChange={(event) => this.setSubmissionState(event)}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <Hidden xsDown>
                  <TableCell className={classes.title}>#</TableCell>
                </Hidden>
                <TableCell className={classes.title}>Entrega</TableCell>
                <TableCell className={classes.title}>Puntaje</TableCell>
                <Hidden xsDown>
                  <TableCell className={classes.title}>Fecha</TableCell>
                </Hidden>
                <TableCell className={classes.title}>Seleccionada</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.challenge &&
                this.state.userSubmissions.reverse().map((row, i) => {
                  let score = Number(row.scoreMinor).toFixed(4);
                  if (row.scoreMinor === "-") {
                    score = "-";
                  }
                  return (
                    <TableRow key={row.linuxDate}>
                      <Hidden xsDown>
                        <TableCell className={classes.text}>{i + 1} </TableCell>
                      </Hidden>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.text}
                      >
                        <Typography align="center" variant="body2">
                          <a
                            className={classes.linkColor}
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Entrega
                          </a>
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.text}>
                        {row.scoreMinor !== undefined ? score : "-"}
                        {this.props.challenge.resultsFormat === "percentage"
                          ? "%"
                          : ""}
                      </TableCell>
                      <Hidden xsDown>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.text}
                        >
                          {moment(row.date).format("LLLL")}
                        </TableCell>
                      </Hidden>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.text}
                      >
                        <FormControlLabel
                          value={row.submissionId.toString()}
                          checked={
                            this.state.selectedSubmission === row.submissionId
                          }
                          control={<Radio />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </RadioGroup>
      </Box>
    );
  }
}

SubmissionList.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
  challengeId: PropTypes.string.isRequired,
};

SubmissionList.contextType = RolesContext;

export default withStyles(styles)(SubmissionList);
