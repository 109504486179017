export default {
  contactUs: {
    dialogTitle: "¡Mensaje de contacto enviado con éxito!",
    dialogContent:
      "Nos alegra que te hayas contactado con nosotros, apenas podamos responderemos tu mensaje.",
    buttonContent: "Aceptar",
  },
  courseCreate: {
    dialogTitle: "¡Curso creado con éxito!",
    buttonContent: "Aceptar",
  },
  create: {
    dialogTitle: "¡Curso creado con éxito!",
    dialogContent:
      "Éxito rol de profesor otorgado!, Recuerda que el usuario debe cerrar sesión y volver a abrirla para obtener los beneficios de su nuevo rol.",
    buttonContent: "Aceptar",
  },
  updateWinnerSubmission: {
    dialogTitle: "Haz cambiado la entrega final",
    dialogContent:
      "ADVERTENCIA: La entrega seleccionada es la que se mostrará en el Ranking Público y tu entrega final una vez que cierre el desafío. No podrás modificar la entrega final seleccionada al cerrar el desafío",
    buttonContent: "Aceptar",
  },
};
