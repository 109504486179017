import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import { TitleWhiteNH1, ParagraphWhite } from "../styling/Styles";
import sampleLandingPage from "../text/sample-landing-page.js";
import ComputerSVG from "../components/svgicons/landing/svgLandingPage";
import Footer from "../components/Footer";

const styles = (theme) => ({
  container: {
    maxWidth: 1440,
    padding: "4em 0",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      flexDirection: "column-reverse",
    },
  },
  infoContainer: {
    padding: "2em",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingRight: 80,
  },
  explanationContainer: {
    paddingRight: "2em",
  },
  explanationParagraph: {
    lineHeight: "25px",
  },
  contactButton: {
    textAlign: "center",
    borderRadius: "1em",
    width: "100%",
    paddingRight: 16,
    textTransform: "none",
  },
  challengesButton: {
    textAlign: "center",
    color: "#fff",
    border: "1px solid #fff",
    textTransform: "none",
    width: "100%",
  },
  titleH5: {
    color: theme.palette.secondary.light,
  },
  responsiveBackground: {
    background:
      "-webkit-gradient(radial,-10.01% 21.12% ,0 , -10.01% 21.12%, 1931.13 ,color-stop(0,rgba(66, 232, 224, 1) ),color-stop(0.0346,rgba(63, 211, 213, 1) ),color-stop(0.1192,rgba(57, 166, 188, 1) ),color-stop(0.2097,rgba(51, 126, 166, 1) ),color-stop(0.3048,rgba(46, 93, 147, 1) ),color-stop(0.406,rgba(43, 65, 132, 1) ),color-stop(0.5153,rgba(40, 44, 121, 1) ),color-stop(0.6368,rgba(38, 29, 112, 1) ),color-stop(0.7801,rgba(36, 21, 107, 1) ),color-stop(1,rgba(36, 18, 106, 1) ))",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      background:
        "-webkit-gradient(radial,-10.01% 21.12% ,0 , -10.01% 21.12%, 1931.13 ,color-stop(0,rgba(66, 232, 224, 1) ),color-stop(0.0346,rgba(63, 211, 213, 1) ),color-stop(0.1192,rgba(57, 166, 188, 1) ),color-stop(0.2097,rgba(51, 126, 166, 1) ),color-stop(0.3048,rgba(46, 93, 147, 1) ),color-stop(0.406,rgba(43, 65, 132, 1) ),color-stop(0.5153,rgba(40, 44, 121, 1) ),color-stop(0.6368,rgba(38, 29, 112, 1) ),color-stop(0.7801,rgba(36, 21, 107, 1) ),color-stop(1,rgba(36, 18, 106, 1) ))",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      background:
        "-webkit-gradient(radial,-10.01% 21.12% ,0 , -10.01% 21.12%, 1931.13 ,color-stop(0,rgba(66, 232, 224, 1) ),color-stop(0.0346,rgba(63, 211, 213, 1) ),color-stop(0.1192,rgba(57, 166, 188, 1) ),color-stop(0.2097,rgba(51, 126, 166, 1) ),color-stop(0.3048,rgba(46, 93, 147, 1) ),color-stop(0.406,rgba(43, 65, 132, 1) ),color-stop(0.5153,rgba(40, 44, 121, 1)",
      height: "100%",
    },
  },
});

class Landing extends React.Component {
  state = {
    mobileOpen: true,
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.responsiveBackground}>
        <Grid container className={classes.container}>
          <Grid item md={12} lg={6}>
            <ComputerSVG />
          </Grid>
          <Grid container md={12} lg={6} className={classes.infoContainer}>
            <TitleWhiteNH1>{sampleLandingPage.data.title} </TitleWhiteNH1>
            <Grid item md={6} className={classes.explanationContainer}>
              <Typography variant="h5" className={classes.titleH5}>
                Empresas
              </Typography>
              <ParagraphWhite className={classes.explanationParagraph}>
                {" "}
                {sampleLandingPage.data.companiesDescription}
              </ParagraphWhite>
              <ParagraphWhite className={classes.explanationParagraph}>
                {" "}
                {sampleLandingPage.data.companiesDescription2}
              </ParagraphWhite>
            </Grid>
            <Grid item md={6} className={classes.explanationContainer}>
              <Typography variant="h5" className={classes.titleH5}>
                Data Scientists
              </Typography>
              <ParagraphWhite className={classes.explanationParagraph}>
                {" "}
                {sampleLandingPage.data.dataScientistsDescription}
              </ParagraphWhite>
              <ParagraphWhite className={classes.explanationParagraph}>
                {" "}
                {sampleLandingPage.data.dataScientistsDescription2}
              </ParagraphWhite>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                paddingTop: 10,
              }}
            >
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.challengesButton}
                  href="/contact"
                >
                  Contáctanos
                </Button>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.challengesButton}
                  href="/challenges"
                >
                  Ver desafíos
                </Button>
              </div>
              {/* <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.challengesButton}
                  href="/job_offers"
                >
                  Ver Ofertas Laborales
                </Button>
              </div> */}
              {/* <div className={classes.buttonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.contactButton}
                  href="/signup"
                >
                  Únete
                </Button>
              </div> */}
            </div>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}

Landing.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(styles)(Landing);
