import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Editor from 'draft-js-plugins-editor';
import { EditorState, RichUtils, AtomicBlockUtils, Modifier } from 'draft-js';
import createImagePlugin from 'draft-js-image-plugin';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'draft-js-image-plugin/lib/plugin.css';
import './TextEditor.css';
const imagePlugin = createImagePlugin();

const styleMap = {
  'LINK': {
    textDecoration: 'underline',
    color: '#3b5998',
  },
  'CODE': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const styles = (theme) => ({
  actionContainer: {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    width: 'fit-content',
  },
  iconNotSelected: {
    color: 'white',
  },
  iconSelected: {
    color: theme.palette.secondary.main,
  },
  editor: {
    backgroundColor: theme.palette.secondary.main,
  },
  overlay: {
    width: '10em',
    height: '4em',
    position: 'absolute',
    top: '38px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1em',
    fontSize: '16px',
  },
  input: {
    borderColor: `${theme.palette.primary.light} !important`,
    borderWidth: '1px',
    borderRadius: '10px',
  },
})

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.focus = () => this.editor.focus();
    this.state = {
      editorState: EditorState.createEmpty(),
      url: '',
      linkOpen: false,
      imageOpen: false,
      dialogContentText: '',
      dialogActions: null,
    };
  }

  componentDidMount() {
    this.props.setRef(this.editor);
  }

  onTextFieldChange = name => event => {
    this.setState({[name]: event.target.value})
  }

  handleDialogOpen = name => {
    let dialogActions = (<DialogActions>
                            <Button onClick={() => this.setState({[name]: false})} color="primary">
                              Cancel
                            </Button>
                            <Button onClick={() => this.handleClose(name)} color="primary">
                              Aceptar
                            </Button>
                          </DialogActions>)
    if(name === 'linkOpen'){
      const currentStyle = this.state.editorState.getCurrentInlineStyle();
      const selectionState = this.state.editorState.getSelection();
      let start = selectionState.getStartOffset();
      let end = selectionState.getEndOffset();
      //No text selected
      if (end - start === 0)
        return
      if (currentStyle.has('LINK')){
        let contentState = this.state.editorState.getCurrentContent();
        let newEditorState = null;
        let newContentState = Modifier.removeInlineStyle(contentState, selectionState, 'LINK')
        newEditorState = EditorState.push(
                this.state.editorState,
                newContentState,
                'change-inline-style'
              );
        newContentState = Modifier.applyEntity(newContentState, selectionState, null)
        newEditorState = EditorState.push(
                newEditorState,
                newContentState,
                'apply-entity'
              );
        this.onChange(newEditorState)
      }
      else{
        this.setState({
          [name]: true,
          dialogContentText: 'Ingresa la url del link al cual quieres vincular el texto',
          dialogActions: dialogActions,
        });
      }
    }
    else
      this.setState({
        [name]: true,
        dialogContentText: 'Ingresa la url de la imagen que quieres insertar',
        dialogActions: dialogActions,
      });
  };

  handleClose = (name) => {
    if(name === 'linkOpen')
      this.insertLink();
    else
      this.insertImage();
  };

  onChange = (editorState) => {
      this.setState({editorState});
      this.props.setRef(this.editor);
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  insertImage = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: this.state.url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    let newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithEntity
    });
    newEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    this.onChange(newEditorState);
    this.setState({url: '', imageOpen: false});
  };

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
  }

  onItalicClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'));
  }

  onUnderlineClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'));
  }

  // onHeaderClick = () => {
  //   this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'header-one'));
  // }
  //
  // onTitleClick = () => {
  //   this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'header-three'));
  // }
  //
  // onSubtitleClick = () => {
  //   this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'header-five'));
  // }

  onCodeClick = () => {
    this.onChange(RichUtils.toggleCode(this.state.editorState));
  }

  onUnorderedListClick = () => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'unordered-list-item'));
  }

  onOrderedListClick = () => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'ordered-list-item'));
  }

  insertLink = () => {
    let contentState = this.state.editorState.getCurrentContent();
    let selectionState = this.state.editorState.getSelection();
    let newEditorState = null;
    let linkEntity = contentState.createEntity(
                      'LINK',
                      'MUTABLE',
                      {url: this.state.url}
                    ).getLastCreatedEntityKey()
    let newContentState = Modifier.applyInlineStyle(contentState, selectionState, 'LINK')
    newEditorState = EditorState.push(
            this.state.editorState,
            newContentState,
            'change-inline-style'
          );
    newContentState = Modifier.applyEntity(newContentState, selectionState, linkEntity)
    newEditorState = EditorState.push(
            newEditorState,
            newContentState,
            'apply-entity'
          );
      // TODO: Toggle link style off
      // let newSelectionState = SelectionState.createEmpty();
      // let updatedSelection = newSelectionState.merge({
      //   focusKey: selectionState.getEndKey(),
      //   focusOffset: 0,
      //   anchorKey: selectionState.getEndKey(),
      //   anchorOffset: 0,
      // });
      // newContentState = Modifier.applyInlineStyle(contentState, updatedSelection, 'LINK')
      // newEditorState = EditorState.push(
      //         this.state.editorState,
      //         newContentState,
      //         'change-inline-style'
      //       );

    this.setState({url: '', linkOpen: false});
    this.onChange(newEditorState)
  }

  render() {
    const { classes } = this.props
    const currentStyle = this.state.editorState.getCurrentInlineStyle();
    return (
      <div>
        <div className={classes.actionContainer}>
          <IconButton aria-label="Negrita" onClick={this.onBoldClick}>
            <Icon fontSize="small" className={currentStyle.has('BOLD') ? classes.iconSelected : classes.iconNotSelected}>format_bold</Icon>
          </IconButton>
          <IconButton aria-label="Itálica" onClick={this.onItalicClick}>
            <Icon fontSize="small" className={currentStyle.has('ITALIC') ? classes.iconSelected : classes.iconNotSelected}>format_italic</Icon>
          </IconButton>
          <IconButton aria-label="Subrayado" onClick={this.onUnderlineClick}>
            <Icon fontSize="small" className={currentStyle.has('UNDERLINE') ? classes.iconSelected : classes.iconNotSelected}>format_underlined</Icon>
          </IconButton>
          <IconButton aria-label="Punteo" onClick={this.onUnorderedListClick}>
            <Icon fontSize="small" className={classes.iconNotSelected}>format_list_bulleted</Icon>
          </IconButton>
          <IconButton aria-label="Lista" onClick={this.onOrderedListClick}>
            <Icon fontSize="small" className={classes.iconNotSelected}>format_list_numbered</Icon>
          </IconButton>

          <IconButton aria-label="Link" onClick={() => this.handleDialogOpen('linkOpen')}>
            <Icon fontSize="small" className={classes.iconNotSelected}>insert_link</Icon>
          </IconButton>

          <IconButton aria-label="Imagen" onClick={() => this.handleDialogOpen('imageOpen')}>
            <Icon fontSize="small" className={classes.iconNotSelected}>insert_photo</Icon>
          </IconButton>


          <IconButton aria-label="Código" onClick={this.onCodeClick}>
            <Icon fontSize="small" className={classes.iconNotSelected}>code</Icon>
          </IconButton>
        </div>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          handleKeyCommand={this.handleKeyCommand}
          customStyleMap={styleMap}
          ref={ref => this.editor = ref}
          plugins={[imagePlugin]}
          className={classes.editor}
          />
          <Dialog
            open={this.state.linkOpen || this.state.imageOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Agregar url</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.state.dialogContentText}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="url"
                label="url"
                type="email"
                fullWidth
                value={this.state.url}
                onChange={this.onTextFieldChange('url')}
              />
            </DialogContent>
            {this.state.dialogActions}
          </Dialog>
      </div>
    );
  }
}

TextEditor.propTypes = {
  setRef: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TextEditor);
