import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, Typography, Grid } from "@material-ui/core";

class NotYetView extends React.Component {
  render() {
    const { NotYetText } = this.props;

    return (
      <Grid style={{ maxWidth: "1000px", margin: "auto" }} xs={9}>
        <Box pt={10} pb={10} display="flex" justifyContent="center">
          <Typography align="center" color="primary" variant="h5">
            {NotYetText}
          </Typography>
        </Box>
      </Grid>
    );
  }
}

NotYetView.propTypes = {
  history: PropTypes.object,
  NotYetText: PropTypes.string.isRequired,
};

export default withRouter(NotYetView);
