import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import {
  Paper,
  Chip,
  Grid,
  Button,
  Switch,
  Box,
  Typography,
} from "@material-ui/core";
import diffInDates from "../../helpers/diffInDates";
import { db } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  paperRoot: ({ display }) => {
    let cardStyles = {};
    if (display) {
      cardStyles = {
        backgroundColor: "#f3f9ff",
        padding: "40px",
        borderRadius: "15px",
        marginBottom: "40px",
      };
    } else {
      cardStyles = {
        backgroundColor: "#183642",
        color: "#fff",
        padding: "30px",
        borderRadius: "15px",
        marginBottom: "40px",
        "& h3": {
          color: "white",
        },
      };
    }
    return {
      width: 1000,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },

      ...cardStyles,
    };
  },

  mainGrid: {
    cursor: "pointer",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  chipRoot: {
    margin: "2px",
    width: "100%",
  },
  buttonsGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 50,
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  buttons: {
    marginTop: 10,
  },
  logo: {
    width: "100%",
    maxWidth: 250,
  },
  verticalLine: {
    borderLeft: "2px solid #C0BFF6",
    height: "100%",
    marginLeft: 40,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  h4Date: {
    textAlign: "center",
    color: "#00c26e",
    fontWeight: "normal",
  },
  h4Closed: {
    textAlign: "center",
    color: "#cc0000",
    fontWeight: "bold",
  },
  strongText: {
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: "1em",
    color: "#2B3E7F",
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    lineHeight: 2,
  },
  textGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const ChallengeCard = ({ challenge, isAdmin }) => {
  const {
    id,
    name,
    endDate,
    tags,
    shortDescription,
    prizeMoney,
    isActive,
    logoUrl,
    display,
    type,
  } = challenge;
  const classes = useStyles({ display });

  const displayText = display ? "Ocultar Desafío" : "Mostrar Desafío";
  const typeOfChallenge = type === "hidden" ? "Desafío Corporativo" : null;
  const [displayStatus, setDisplayStatus] = React.useState(
    display ? true : false
  );
  const [idChangedChallenge, setIdChangedChallenge] = React.useState();

  const handleClick = (id) => {
    setDisplayStatus((prev) => !prev);
    setIdChangedChallenge(id);
  };

  React.useEffect(() => {
    if (display !== displayStatus) {
      db.updateChallenge(idChangedChallenge, {
        display: displayStatus,
      });
      window.location.reload();
    }
  }, [displayStatus, idChangedChallenge]);

  console.log({ displayStatus, display });
  if (!display && !isAdmin) {
    return null;
  }
  if (id !== "undefined") {
    return (
      <>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container className={classes.mainGrid}>
            <Grid
              container
              item
              lg={2}
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {logoUrl ? (
                <div>
                  <img src={logoUrl} alt="Logo" className={classes.logo} />
                </div>
              ) : null}
              {isActive ? (
                <h4 className={classes.h4Date}>
                  <div className={classes.strongText}>
                    {diffInDates(new Date(), endDate)}
                  </div>
                  <div>días</div>
                </h4>
              ) : (
                <h4 className={classes.h4Closed}>Finalizado</h4>
              )}
            </Grid>
            <Grid item xs={12} lg={1}>
              <div className={classes.verticalLine}></div>
            </Grid>
            <Grid item lg={6} xs={12} className={classes.textGrid}>
              {typeOfChallenge ? (
                <Typography variant="body1" color="error">
                  {typeOfChallenge}
                </Typography>
              ) : null}
              {name ? (
                <Typography variant="h4" color="secondary">
                  {name}
                </Typography>
              ) : null}
              {shortDescription ? (
                <p className={classes.paragraph}>{shortDescription}</p>
              ) : null}
              {tags ? (
                <div style={{ display: "flex" }}>
                  {tags.map((tag, index) => (
                    <Chip
                      classes={{ root: classes.chipRoot }}
                      key={index}
                      label={tag}
                    />
                  ))}
                </div>
              ) : null}
            </Grid>
            <Grid container lg={3} xs={12} className={classes.buttonsGrid}>
              {isAdmin ? (
                <Box display="flex" alignItems="center" pt={2} pb={2}>
                  <Switch
                    checked={!displayStatus}
                    onClick={() => handleClick(id)}
                    name="checked"
                  />
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    {displayText}
                  </Typography>
                </Box>
              ) : null}
              {prizeMoney ? (
                <Typography variant="body1" align="center">
                  ${prizeMoney} USD
                </Typography>
              ) : null}
              <Button
                className={classes.buttons}
                variant="outlined"
                color="primary"
                href={"/challenge/" + id}
              >
                Ver desafío
              </Button>
              {isAdmin && (
                <Button
                  className={classes.buttons}
                  id={id}
                  variant="outlined"
                  color="primary"
                  href={"/challenge_edit/" + id}
                >
                  Editar desafío
                </Button>
              )}
              {isAdmin && type === "hidden" && (
                <Button
                  className={classes.buttons}
                  id={id}
                  variant="outlined"
                  color="primary"
                  href={"/challenge_invitation/" + id}
                >
                  Invitar
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  } else return null;
};

ChallengeCard.propTypes = {
  challenge: PropTypes.object.isRequired,
  classes: PropTypes.object,
  history: PropTypes.object,
  width: PropTypes.string,
  isAdmin: PropTypes.bool,
};
export default ChallengeCard;
