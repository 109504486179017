import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

class DialogComponent extends React.Component {
  redirect = () => {
    if (this.props.refresh) {
      window.location.reload();
    } else {
      let route = this.props.route;
      let id = this.props.id;
      this.props.history.push({
        pathname: route + "/" + id,
      });
    }

    this.props.closeDialog();
  };

  render() {
    const { dialogTitle, dialogContent, buttonContent, open } = this.props;
    return (
      <Dialog open={open}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.redirect}>{buttonContent}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogComponent.propTypes = {
  history: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  route: PropTypes.string,
  id: PropTypes.string,
  refresh: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.string,
  buttonContent: PropTypes.string,
};

export default withRouter(withMobileDialog()(DialogComponent));
