import React from "react";
import { Box, Hidden } from "@material-ui/core";
import ContactForm from "../components/contact/ContactForm";

class ContactPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Hidden implementation="css" smDown>
          {" "}
          {/* For computer screen */}
          <Box pt={3} pl={25}>
            <ContactForm />
          </Box>
        </Hidden>
        <Hidden implementation="css" mdUp>
          {" "}
          {/* For mobile screen */}
          <Box>
            <ContactForm />
          </Box>
        </Hidden>
      </React.Fragment>
    );
  }
}

export default ContactPage;
