import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { db } from "../firebase";
import { auth } from "../firebase/firebase";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import ChallengeCard from "../components/challengeindex/ChallengeCard";

const useStyles = makeStyles(() => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  centerGrid: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  skeleton: {
    borderRadius: 32,
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 50,
    },
  },
}));

const ChallengeHidden = () => {
  const [suscriptions, setSuscriptions] = React.useState([]);
  // const [suscribedChallenges, setSuscribedChallenges] = React.useState({});
  const classes = useStyles();
  //  const [isSuscribed, setIsSuscribed] = React.useState(false);

  React.useEffect(() => {
    const getUserSuscriptions = async () => {
      const userSuscriptions = await db.getSuscriptionsByUser(
        auth.currentUser.uid
      );
      console.log({ suscriptions: userSuscriptions.val() });
      let allSuscribedChallenges = [];
      const promiseSuscription = Object.entries(userSuscriptions.val()).map(
        (suscription) => {
          console.log({ suscription });
          return db.getChallenge(suscription[1].challengeId);
        }
      );
      const challengeId = Object.entries(userSuscriptions.val()).map((item) => {
        console.log({ item });
        return item[1].challengeId;
      });
      console.log({ challengeId });
      const allSuscriptions = await Promise.all(promiseSuscription);
      console.log({ allSuscriptions });
      allSuscribedChallenges = allSuscriptions.map((s, index) => {
        return { ...s.val(), id: challengeId[index] };
      });
      setSuscriptions(allSuscribedChallenges);
    };
    if (suscriptions.length === 0 && auth.currentUser != null) {
      getUserSuscriptions();
    }
    //

    // const filterSuscriptionsByUser = (snapshot) => {
    //   let allSuscriptions = [];
    //   let allSuscribedChallenges = [];
    //   snapshot.forEach((suscription) => {
    //     const currentUserId = auth.currentUser.uid;
    //     if (suscription.val().userId === currentUserId) {
    //       allSuscriptions.push(suscription.val().challengeId);
    //     }
    //   });
    //   setSuscriptions(allSuscriptions);
    //   suscriptions.forEach(async (suscription) => {
    //     console.log(suscription, "ausrip");
    //     const getChallenge = await db.getChallenge(suscription);
    //     allSuscribedChallenges.push({ ...getChallenge.val(), id: suscription });
    //   });
    //   setSuscribedChallenges(allSuscribedChallenges);
    // };
  }, [suscriptions, auth.currentUser]);

  console.log(suscriptions);
  //const challenges = suscribedChallenges;
  //console.log(suscribedChallenges, "buscando esta info");

  return (
    <div>
      <Grid container className={classes.grid} xs={12}>
        <Grid item xs={12} className={classes.centerGrid}>
          <Banner title={"Desafíos Corporativos"} />
        </Grid>
        <Grid item xs={12} className={classes.centerGrid}>
          {suscriptions.length > 0 ? (
            suscriptions.map((challenge, index) => {
              console.log(challenge, "inside render");
              return (
                <ChallengeCard
                  key={index}
                  //isAdmin={isAdmin}
                  challenge={challenge}
                />
              );
            })
          ) : (
            <>
              <Skeleton
                variant="rect"
                className={classes.skeleton}
                width={1000}
                height={350}
              />
              <Skeleton
                variant="rect"
                className={classes.skeleton}
                width={1000}
                height={350}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

ChallengeHidden.propTypes = {
  isAdmin: PropTypes.bool,
  challenge: PropTypes.object,
  classes: PropTypes.object,
};

export default ChallengeHidden;
