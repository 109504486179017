import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Hidden,
  Box,
} from "@material-ui/core";
import { db } from "../../../firebase";
import { auth } from "../../../firebase/firebase";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  table: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "1em",
  },
  text: {
    fontWeight: "normal",
    //fontSize: '25px',
    color: theme.palette.primary.main,
    fontSize: "1em",
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    marginLeft: "7%",
    color: theme.palette.secondary.main,
    fontSize: "1em",
    textAlign: "center",
  },
  box: {
    border: "1px solid grey",
    padding: "10px",
    textAlign: "left",
    backgroundColor: theme.palette.grey["50"],
    boxShadow: `1px 1px 1px ${theme.palette.grey["400"]}`,
    borderRadius: "4px",
  },
});

class ChallengeRanking extends React.Component {
  state = {
    ranking: [],
    submissions: [],
    isAdmin: false,
  };
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({
            isAdmin: user,
          });
        });
      }
    });
    db.getSubmissionsByScore(this.props.challenge.id)
      .then((snapshot) => {
        this.generateRanking(snapshot, this.props.challenge.resultsOrder);
      })
      .catch((error) => error);
  }

  generateRanking = (submissionsSnapshot, order) => {
    const submissions = [];
    if (this.props.challenge.type === "old-binnario") {
      this.generateRankingWithOldBinnarioChallengeType(
        submissionsSnapshot,
        order
      );
    } else {
      if (this.props.variant === "public") {
        submissionsSnapshot.forEach((submissionSnapshot) => {
          if (submissionSnapshot.val().winner === true) {
            if (
              submissionSnapshot.val().scoreMinor !== "-" &&
              submissionSnapshot.val().scoreMinor !==
                "Error: Formato Predicciones Incorrecto"
            ) {
              submissions.push(submissionSnapshot.val());
            }
            if (order === "desc") {
              submissions.sort((a, b) => b.scoreMinor - a.scoreMinor);
            } else submissions.sort((a, b) => a.scoreMinor - b.scoreMinor);
            this.setState({ submissions: submissions });
          }
        });
      }
      if (this.props.variant === "private") {
        submissionsSnapshot.forEach((submissionSnapshot) => {
          if (submissionSnapshot.val().winner === true) {
            if (
              submissionSnapshot.val().scoreMayor !== "-" &&
              submissionSnapshot.val().scoreMinor !==
                "Error: Formato Predicciones Incorrecto"
            ) {
              submissions.push(submissionSnapshot.val());
            }
            if (order === "desc") {
              submissions.sort((a, b) => b.scoreMayor - a.scoreMayor);
            } else submissions.sort((a, b) => a.scoreMayor - b.scoreMayor);
            this.setState({ submissions: submissions });
          }
        });
      }
    }
  };

  generateRankingWithOldBinnarioChallengeType = (rankingSnapshot, order) => {
    const submissions = [];
    let ranking = [];
    let users = []; // Used to check that the ranking has no duplicated users
    rankingSnapshot.forEach((rankinSnapshot) => {
      ranking.push(rankinSnapshot.val());
    });
    ranking
      .filter((rankin) => rankin.score)
      .sort((a, b) => {
        if (order == "desc") {
          return b.score - a.score;
        }
        return a.score - b.score;
      })
      .forEach((rankin) => {
        //Check that score for the rankin exists
        if (!users.includes(rankin.userId)) {
          //Get the user of the rankin
          users.push(rankin.userId);
          db.onceGetUserInfo(rankin.userId)
            .then((snapshot) => {
              submissions.push({
                date: rankin.date,
                username: snapshot.val().username,
                score: parseFloat(rankin.score).toFixed(2),
              });
              this.setState({ submissions: submissions.slice(0, 50) });
            })
            .catch((error) => error);
        }
      });
  };

  render() {
    const { classes, variant, challenge } = this.props;
    return (
      <Box pb={30}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <Hidden xsDown>
                <TableCell className={classes.title}>#</TableCell>
              </Hidden>
              <TableCell className={classes.title}>Usuario</TableCell>
              <TableCell className={classes.title}>Puntaje</TableCell>
              <Hidden xsDown>
                <TableCell className={classes.title}>Fecha</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenge &&
              this.state.submissions.map((row, i) => {
                let scoreMinor = Number(row.scoreMinor).toFixed(4);
                let scoreMayor = Number(row.scoreMayor).toFixed(4);
                return (
                  <TableRow key={row.linuxDate}>
                    <Hidden xsDown>
                      <TableCell className={classes.text}>{i + 1} </TableCell>
                    </Hidden>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.text}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell className={classes.text}>
                      {challenge.type !== "old-binnario" && variant === "public"
                        ? scoreMinor
                        : null}
                      {challenge.type !== "old-binnario" &&
                      variant === "private"
                        ? scoreMayor
                        : null}
                      {challenge.type === "old-binnario" ? row.score : null}
                      {this.props.challenge.resultsFormat &&
                      this.props.challenge.resultsFormat === "percentage"
                        ? "%"
                        : ""}
                    </TableCell>
                    <Hidden xsDown>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.text}
                      >
                        {moment(row.date).format("LLLL")}
                      </TableCell>
                    </Hidden>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

ChallengeRanking.propTypes = {
  classes: PropTypes.object,
  challenge: PropTypes.object,
  challengeId: PropTypes.number,
  variant: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default withStyles(styles)(ChallengeRanking);
