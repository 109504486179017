import React from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from '../../config/routes';
import { LinkForgotPw} from "../../styling/Styles";


class SignInLink extends React.Component {

  render() {
    return (
      <LinkForgotPw onClick={() => this.props.link(ROUTES.SIGN_IN)}>¿Ya tienes cuenta? ingresa al inicio de sesión</LinkForgotPw>
    )
  }
}

SignInLink.propTypes = {
  link: PropTypes.func,
}

export default SignInLink;
