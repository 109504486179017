import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import PasswordForgetForm from "../components/PasswordForgetForm";
import Logo from "../styling/Logo";
import { Linking } from "../styling/Styles";
import * as ROUTES from "../config/routes";

const PasswordForget = ({ history }) => {
  return (
    <Box pt={5} display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" justifyContent="center">
        <Linking onClick={() => this.props.history.push(ROUTES.LANDING)}>
          <Logo />
        </Linking>
      </Box>
      <Box pt={1}>
        <PasswordForgetForm history={history} />
      </Box>
    </Box>
  );
};

export default withRouter(PasswordForget);

PasswordForget.propTypes = {
  history: PropTypes.object,
};
