import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Box, Avatar } from "@material-ui/core";

const styles = () => ({
  root: {
    minWidth: 275,
  },
  avatar: {
    width: 50,
    height: 50,
  },
});

class UserList extends React.Component {
  render() {
    const { classes } = this.props;
    const suscribedUser = this.props.suscribedUser;
    const date = this.props.date;

    return (
      <div>
        <Card square className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" alignItems="center" pt={2}>
              {!date && (
                <Avatar src="../images/avatar.png" className={classes.avatar} />
              )}
              <Box display="flex" flexDirection="column" ml={2}>
                <Typography align="left" color="secondary" variant="h5">
                  <strong>
                    {suscribedUser.name} {suscribedUser.lastname}
                  </strong>
                </Typography>
                {date && (
                  <Typography align="left" color="primary" variant="body2">
                    Desde el {date}
                  </Typography>
                )}
                <Typography align="left" color="primary" variant="body2">
                  <strong> {suscribedUser.email} </strong>
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  }
}

UserList.propTypes = {
  date: PropTypes.date,
  suscribedUser: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  width: PropTypes.string,
};

export default withStyles(styles)(withRouter(UserList));
