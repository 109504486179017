import React, { Component } from "react";
import PropTypes from "prop-types";
import { auth, storage } from "../firebase/firebase";
import { db } from "../firebase";
import {
  TitleDH3,
  Paragraph,
  TitleD,
  SignOutButton,
  LinkingNH3,
  ListItem,
} from "../styling/Styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Icon from "@material-ui/core/Icon";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import * as ROUTES from "../config/routes";
import RolesContext from "../session/RolesContext";
import { withRouter } from "react-router";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  gridMargin: {
    marginTop: "80px",
  },
  responsiveSVG: {
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
  },
});

class Submission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      challenge: props.challenge,
      file: null,
      error: null,
      uploadingFile: "WAITING",
      submissionsDate: {},
      date: null,
      maxSubmissions: false,
      noSubmissionsYet: false,
      isLoading: true,
      noSubmissionsOnChallenge: false,
      hasError: false,
      submissionsLength: null,
      winnerState: false,
      userName: "",
      isAdmin: false,
    };
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({
            isAdmin: user,
          });
        });
      }
    });

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    try {
      let userSubmissions = await db.getUserSubmissions(auth.currentUser.uid);
      if (userSubmissions.val() === null) {
        this.setState({
          noSubmissionsYet: true,
          isLoading: false,
          winnerState: true,
        });
      } else {
        await db
          .getUserSubmissions(auth.currentUser.uid)
          .then((snapshots) => this.getSubmissionList(snapshots))
          .catch((error) => error);
      }
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }

    db.onceGetUserInfo(auth.currentUser.uid).then((snapshot) =>
      this.setState({ userName: snapshot.val().name + snapshot.val().lastname })
    );
  }

  getSubmissionList = (snapshots) => {
    let submissionsDate = [];
    snapshots.forEach((snapshot) => {
      let challengeId = snapshot.val().challengeId;
      if (challengeId === this.props.challenge.id) {
        submissionsDate.push(snapshot.val().date);
        this.checkDate(submissionsDate);
      } else {
        this.setState({ isLoading: false });
      }
    });
    this.setState({ submissionsDate, isLoading: false });
  };

  //this function will check actual date with the last third upload. then if the result is <= 86400000 (a day in milliseconds)
  // it means that the user has uploaded X times in a day, if not then its able to upload another submission
  checkDate = (submissionsDate) => {
    let length = submissionsDate.length;
    let maxSubmissionsPerDay = this.state.challenge.maxSubmissionsPerDay;
    let date = Date.now();
    if (length >= maxSubmissionsPerDay) {
      if (date - submissionsDate[length - maxSubmissionsPerDay] <= 86400000) {
        this.setState({ maxSubmissions: true });
        if (this.context.isClient === true) {
          this.setState({ maxSubmissions: false });
        }
      } else {
        this.setState({ maxSubmissions: false });
      }
    } else {
      this.setState({ maxSubmissions: false });
    }
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  generateFileName = () => {
    return (
      auth.currentUser.displayName.replace(/\s/g, "") + `_${Date.now()}.csv`
    );
  };

  fileSelection = (event) => {
    this.setState({
      file: event.target.files[0],
      error: null,
      custom_file_name: this.generateFileName(),
    });
  };

  fileUpload = () => {
    if (!this.state.file) {
      this.setState({ error: "Debes seleccionar un archivo" });
      return;
    }
    if (!auth.currentUser) {
      this.setState({
        error: "Debes iniciar sesión para subir tus resultados",
      });
      return;
    }
    this.setState({ error: null, uploadingFile: "UPLOADING" });
    const storageRef = storage.ref(
      `submissions/${this.state.custom_file_name}`
    );
    const task = storageRef.put(this.state.file);
    task.on(
      "state_changed",
      (snapshot) => {
        let percentage = (
          (snapshot.bytesTransferred / snapshot.totalBytes) *
          100
        ).toFixed(2);
        this.setState({ uploadPercentage: percentage });
      },
      (error) => error,
      () => {
        this.setState({ uploadingFile: "DONE" });
        storageRef.getDownloadURL().then((url) => {
          db.addUserSubmission(
            auth.currentUser.uid,
            this.state.userName,
            this.props.match.params.id,
            this.state.custom_file_name,
            url,
            this.state.winnerState
          );
        });
      }
    );
  };

  getFileState = () => {
    if (this.state.uploadingFile === "WAITING")
      return (
        <Box>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <label htmlFor="submission-file">
              <div
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e0e0e0",
                  color: "#42B5C3",
                  margin: "1em 1em 0 0",
                  borderRadius: "3px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: "0.5em 1em",
                }}
              >
                Seleccionar archivo
              </div>
            </label>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>
              {this.state.file && this.state.file.name}
            </p>
          </div>
          <p
            style={{
              textAlign: "center",
              margin: 0,
              fontSize: "14px",
              color: "#e6423a",
            }}
          >
            {this.state.error}
          </p>
          <SignOutButton disabled={this.state.file} onClick={this.fileUpload}>
            Subir
          </SignOutButton>
        </Box>
      );

    if (this.state.uploadingFile === "UPLOADING")
      return (
        <div>
          <p>Tu archivo se está subiendo</p>
          <LinearProgress
            value={this.state.uploadPercentage}
            variant="determinate"
          />
        </div>
      );
    if (this.state.uploadingFile === "DONE")
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContentContent: "center",
          }}
        >
          <Icon style={{ color: "#2B3E7F", marginRight: "1em" }}> done </Icon>
          <p>Archivo {this.state.file.name} subido con éxito</p>
        </div>
      );
    if (this.state.uploadingFile === "ERROR")
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContentContent: "center",
          }}
        >
          <Icon style={{ color: "#2B3E7F", marginRight: "1em" }}> clear </Icon>
          <p>Hubo un error al subir {this.state.file.name}</p>
        </div>
      );
  };

  processRedirect = (route) => {
    if (this.props.location.pathname === route) {
      this.props.onClose();
    } else {
      this.props.history.push(route);
    }
  };

  render() {
    const { classes } = this.props;
    const { challenge, isAdmin } = this.state;
    const { isClient } = this.context;
    let length = this.state.submissionsDate.length;
    let timeLeft =
      Date.now() -
      this.state.submissionsDate[length - challenge.maxSubmissionsPerDay];
    let milliseccond = 0.27777777777778 * Math.pow(10, -6) * timeLeft;
    let result = 24 - milliseccond;

    if (this.state.isLoading) {
      return (
        <Box display="flex" justifyContentContent="center" pt={15}>
          <CircularProgress size={100} />
        </Box>
      );
    }

    if (this.state.maxSubmissions) {
      return (
        <Box
          display="flex"
          style={{ margin: "0 auto" }}
          pt={20}
          pb={15}
          width="50%"
        >
          <Typography align="center" color="primary" variant="h5">
            Superaste el máximo de entregas en 24 horas, recuerda que sólo
            puedes subir {this.state.challenge.maxSubmissionsPerDay} entregas al
            día.{" "}
            <Typography color="secondary">
              {" "}
              En {result.toFixed(2)} horas podrás subir una nueva entrega.
            </Typography>
          </Typography>
        </Box>
      );
    }
    if (auth.currentUser) {
      return (
        <Box style={{ padding: "0 25%" }}>
          <Box style={{ padding: "1em" }}>
            <TitleD> DESAFÍO </TitleD>
            <TitleDH3 style={{ margin: 0 }}> {challenge.name} </TitleDH3>
            <Paragraph
              style={{
                padding: "2px 0 0px 0",
                lineHeight: 1.5,
                fontSize: "14px",
              }}
            >
              {" "}
              {challenge.shortDescription}{" "}
            </Paragraph>
            {(isAdmin || isClient) && (
              <TitleDH3
                style={{ marginLeft: 0, marginBottom: 10, marginTop: 5 }}
              >
                Como Admin o Cliente de Binnario puedes subir entregas
                infinitamente. El resto de los usuarios solo podrán subir{" "}
                {this.state.challenge.maxSubmissionsPerDay + " "} entregas por
                día.
              </TitleDH3>
            )}
            <TitleDH3 style={{ margin: 0 }}>
              {" "}
              Reglas para subir entregas{" "}
            </TitleDH3>
            <ListItem
              style={{
                padding: "2px 0 1px 0",
                lineHeight: 1.5,
                fontSize: "14px",
              }}
            >
              Asegurate que tu entrega es un archivo csv{" "}
            </ListItem>
            <ListItem
              style={{
                padding: "2px 0 1px 0",
                lineHeight: 1.5,
                fontSize: "14px",
              }}
            >
              Sigue el formato entregado en la sección de información (puedes
              verlo en instrucciones de entrega apretando el menú){" "}
            </ListItem>
            {(isClient === undefined || isAdmin === undefined) && (
              <ListItem
                style={{
                  padding: "2px 0 1px 0",
                  lineHeight: 1.5,
                  fontSize: "14px",
                }}
              >
                Solo se aceptan máximo{" "}
                {this.state.challenge.maxSubmissionsPerDay} entregas por día.
              </ListItem>
            )}
            <ListItem
              style={{
                padding: "2px 0 1px 0",
                lineHeight: 1.5,
                fontSize: "14px",
              }}
            >
              En el caso de que la entrega aparezca con -- o -% significa que tu
              archivo está incorrecto, debe tener el mismo formato que el
              ejemplo de entrega.
            </ListItem>
            <ListItem
              style={{
                padding: "2px 0 1px 0",
                lineHeight: 1.5,
                fontSize: "14px",
              }}
            >
              En la competencia existe un ranking público que se calcula
              evaluando solo una parte menor de la data. Los finalistas se
              definirán en base a un Ranking privado que se calcula con una
              porción mayor de la data y que será publicado al momento del
              cierre del desafío (Esta medida busca evitar el sobreajuste)
            </ListItem>
          </Box>
          <div
            style={{
              backgroundColor: "#cccccc",
              borderRadius: "5px",
              padding: "0.5em 2em",
              marginTop: "1em",
              marginBottom: "10em",
            }}
          >
            <Typography colorstyle={{ paddingLeft: "1em" }}>
              {" "}
              SUBIR DESAFÍO{" "}
            </Typography>
            <hr
              style={{
                margin: "0 1em",
                border: "none",
                height: "1px",
                backgroundColor: "#b4b4be",
              }}
            ></hr>
            {auth.currentUser ? (
              <Box
                pb={20}
                style={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  marginTop: "1em",
                  padding: "1em",
                }}
              >
                <input
                  onChange={this.fileSelection}
                  name="submission"
                  type="file"
                  accept=".csv"
                  id="submission-file"
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: "-1",
                  }}
                />
                <Box>{this.getFileState()}</Box>
              </Box>
            ) : (
              <TitleD style={{ marginLeft: "1em" }}>
                {" "}
                Debes iniciar sesión para subir tus resultados{" "}
              </TitleD>
            )}
          </div>
        </Box>
      );
    } else {
      return (
        <div>
          <Grid container spacing={12} className={classes.gridMargin}>
            <Grid
              item
              container
              xs={4}
              justifyContent="center"
              alignItems="center"
            ></Grid>
            <Grid
              item
              container
              xs={4}
              justifyContent="center"
              alignItems="center"
            >
              <LinkingNH3
                style={{ textAlign: "center", marginBottom: "50px" }}
                onClick={() => this.processRedirect(ROUTES.SIGN_IN)}
              >
                Inicia sesión para poder subir tu entrega
              </LinkingNH3>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

Submission.propTypes = {
  challenge: PropTypes.string,
  classes: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.string,
  onClose: PropTypes.bool,
  challengeId: PropTypes.string,
  match: PropTypes.string,
  "match.params": PropTypes.string,
  "match.params.id": PropTypes.string,
};
Submission.contextType = RolesContext;

export default withStyles(styles)(withRouter(Submission));
