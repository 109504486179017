import React from "react";
import "./svgLandingPage.css";
import PropTypes from "prop-types";
class svgLandingPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <svg
          style={{ transform: "scale(-1, 1)" }}
          id="154415bf-121d-4e44-a637-856a1cdd8f2f"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 607.05 435.25"
          width={this.props.width * 0.4}
        >
          <defs>
            <linearGradient
              id="db17fda7-f9ba-4774-8f59-76cf5510a670"
              x1="-3196.72"
              y1="565.1"
              x2="-3174.74"
              y2="577.79"
              gradientTransform="matrix(-1 0 0 1 -2607.99 0)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#42e8e0" />
              <stop offset="0.15" stopColor="#43e3e0" />
              <stop offset="0.33" stopColor="#45d5df" />
              <stop offset="0.52" stopColor="#49bddd" />
              <stop offset="0.72" stopColor="#4e9cdb" />
              <stop offset="0.93" stopColor="#5472d8" />
              <stop offset="1" stopColor="#5761d7" />
            </linearGradient>
            <linearGradient
              id="db159bd6-0e54-48a1-8024-c300f351b645"
              x1="-1136.97"
              y1="2043.95"
              x2="-1091.09"
              y2="2043.95"
              gradientTransform="matrix(-.87 .5 1 .58 -2397.01 -15.65)"
              xlinkHref="#db17fda7-f9ba-4774-8f59-76cf5510a670"
            />
            <linearGradient
              id="e25e4398-f0bd-4f47-8813-3980212cffb9"
              x1="-3181.85"
              y1="597.62"
              x2="-3143.48"
              y2="597.62"
              xlinkHref="#db17fda7-f9ba-4774-8f59-76cf5510a670"
            />
            <linearGradient
              id="27770b7f-2e1c-41dc-b2fe-045e278865f8"
              x1="-3155.55"
              y1="581.64"
              x2="-3169.78"
              y2="613.59"
              xlinkHref="#db17fda7-f9ba-4774-8f59-76cf5510a670"
            />
            <linearGradient
              id="81e195d1-c332-4ca2-9060-ab3e32924260"
              x1="-3537.88"
              y1="612.8"
              x2="-3233.54"
              y2="612.8"
              xlinkHref="#db17fda7-f9ba-4774-8f59-76cf5510a670"
            />
            <linearGradient
              id="0e498fad-1e9d-4421-84fd-87fe87e85cab"
              x1="-3579.51"
              y1="465.78"
              x2="-3347.45"
              y2="465.78"
              gradientTransform="matrix(-1 0 0 1 -2607.99 0)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#c0bff6" />
              <stop offset="0.29" stopColor="#c5c4f7" />
              <stop offset="0.64" stopColor="#d3d2f9" />
              <stop offset="1" stopColor="#e9e9fd" />
            </linearGradient>
            <linearGradient
              id="f06fc011-104a-4bcd-9c07-907bbb32f170"
              x1="-3579.51"
              y1="525.29"
              x2="-3539.66"
              y2="525.29"
              gradientTransform="matrix(-1 0 0 1 -2607.99 0)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#5761d7" />
              <stop offset="0.18" stopColor="#5c66d9" />
              <stop offset="0.39" stopColor="#6a73df" />
              <stop offset="0.61" stopColor="#8289e8" />
              <stop offset="0.85" stopColor="#a3a7f5" />
              <stop offset="1" stopColor="#bcbeff" />
            </linearGradient>
            <linearGradient
              id="6ee0f367-e3c2-4fdd-be35-00f4eb4c0226"
              x1="-3548.42"
              y1="592.19"
              x2="-3220.67"
              y2="592.19"
              xlinkHref="#f06fc011-104a-4bcd-9c07-907bbb32f170"
            />
            <linearGradient
              id="980bc147-179d-41a0-ab9a-4c5fe30763f4"
              x1="-3438.29"
              y1="581.26"
              x2="-3110.37"
              y2="581.26"
              xlinkHref="#0e498fad-1e9d-4421-84fd-87fe87e85cab"
            />
            <linearGradient
              id="b0f067fd-e79f-4580-97cc-0c3de426a78e"
              x1="-3382.76"
              y1="599.88"
              x2="-3298.82"
              y2="599.88"
              xlinkHref="#0e498fad-1e9d-4421-84fd-87fe87e85cab"
            />
            <clipPath
              id="bb6cd7ac-fb79-47f4-a229-73b16a282c1b"
              transform="translate(-458.73 -266.05)"
            >
              <polygon
                id="f8d3047f-be93-4483-9703-674551ba0c58"
                data-name="SVGID"
                className="d4880cee-d56c-4712-828c-7c220219be7b"
                points="767.46 340.08 961.1 451.87 937.08 593.43 743.58 481.71 767.46 340.08"
              />
            </clipPath>
            <linearGradient
              id="88d26b02-3269-471c-b0e9-e69f8535a9dc"
              x1="-3191.72"
              y1="532.46"
              x2="-3066.72"
              y2="532.46"
              xlinkHref="#db17fda7-f9ba-4774-8f59-76cf5510a670"
            />
            <linearGradient
              id="18ed72e0-9cdb-4f41-9185-faed83685281"
              x1="-3886.3"
              y1="-1641.87"
              x2="-3769.99"
              y2="-1641.87"
              gradientTransform="matrix(-.87 -.5 0 1.15 -2781.68 475.72)"
              xlinkHref="#f06fc011-104a-4bcd-9c07-907bbb32f170"
            />
            <radialGradient
              id="5b7dea50-4738-4aa7-9e89-e12085cb3867"
              cx="-3168.17"
              cy="492.78"
              fx="-3213.479"
              fy="476.792"
              r="48.05"
              gradientTransform="matrix(-1 0 0 1 -2607.99 0)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#2b237c" />
              <stop offset="1" stopColor="#180d5b" />
            </radialGradient>
            <linearGradient
              id="66ef6181-84dd-4070-9437-5d4b303b0e73"
              x1="-3191.84"
              y1="507.87"
              x2="-3076.07"
              y2="507.87"
              xlinkHref="#f06fc011-104a-4bcd-9c07-907bbb32f170"
            />
            <linearGradient
              id="ca721b3e-d0d9-4611-ac02-e515fa820e1c"
              x1="-3863.75"
              y1="-1622.29"
              x2="-3747.45"
              y2="-1622.29"
              gradientTransform="matrix(-.87 -.5 0 1.15 -2781.68 475.72)"
              xlinkHref="#0e498fad-1e9d-4421-84fd-87fe87e85cab"
            />
          </defs>
          <path
            className="f022151e-fdff-4a3e-8a40-04ea37b8f641"
            d="M572.78,588.15l-.88-1.46,19.25-11.6a1.22,1.22,0,0,0,0-2.1l-18.91-11.24.87-1.46L592,571.53a2.93,2.93,0,0,1,0,5Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="f55ea85c-af39-4021-b0cf-e4cccb135278"
            d="M611.88,629a10.06,10.06,0,0,1-5-1.35l-34.24-19.77.85-1.48,34.24,19.77a8.37,8.37,0,0,0,8.36,0l33.85-19.54.85,1.48-33.85,19.54A10.07,10.07,0,0,1,611.88,629Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="1a484672-d970-4439-b5e7-ca2e96a4bddc"
            d="M554.68,609a27.38,27.38,0,0,1-13.39-3.2h0c-3.74-2.16-5.8-5.08-5.8-8.22s2.06-6.07,5.8-8.22c7.38-4.26,19.4-4.27,26.78,0,3.74,2.16,5.8,5.08,5.8,8.22s-2.06,6.07-5.8,8.22A27.39,27.39,0,0,1,554.68,609Zm0-21.16a25.65,25.65,0,0,0-12.54,3c-3.19,1.84-4.95,4.24-4.95,6.75s1.76,4.91,4.94,6.75h0c6.91,4,18.16,4,25.08,0,3.19-1.84,4.95-4.24,4.95-6.75s-1.76-4.91-4.94-6.75A25.64,25.64,0,0,0,554.68,587.87Zm0,16.33a15.14,15.14,0,0,1-7.44-1.81c-2.15-1.24-3.33-2.94-3.33-4.78s1.18-3.54,3.33-4.78a16.45,16.45,0,0,1,14.87,0c2.15,1.24,3.33,2.94,3.33,4.78s-1.18,3.54-3.33,4.78A15.14,15.14,0,0,1,554.68,604.21Zm0-11.47a13.48,13.48,0,0,0-6.58,1.57c-1.6.92-2.48,2.1-2.48,3.31s.88,2.39,2.48,3.31a14.59,14.59,0,0,0,13.17,0c1.6-.92,2.48-2.1,2.48-3.31s-.88-2.39-2.48-3.31A13.47,13.47,0,0,0,554.68,592.73Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="2ec6995d-a3d6-4012-873b-01399eb5747e"
            d="M554.68,612.87c-6.55,0-13.1-1.44-18.08-4.32s-7.74-6.76-7.74-10.93,2.75-8.05,7.74-10.93c10-5.76,26.2-5.76,36.17,0,5,2.88,7.74,6.76,7.74,10.93s-2.75,8.05-7.74,10.93S561.23,612.87,554.68,612.87Zm0-28.82c-6.24,0-12.48,1.37-17.23,4.12-4.44,2.56-6.89,5.92-6.89,9.46s2.45,6.89,6.89,9.46h0c9.5,5.49,25,5.49,34.47,0,4.44-2.56,6.89-5.92,6.89-9.46s-2.45-6.89-6.89-9.46C567.16,585.41,560.92,584,554.68,584Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="45563578-de59-4f88-837b-a3427d006ab5"
            d="M815.71,701.3a16.2,16.2,0,0,1-8.09-2.16L627.2,595a3.3,3.3,0,0,1,0-5.71l112.53-65,.43.25L928.25,633.13a3.3,3.3,0,0,1,0,5.71l-104.44,60.3A16.19,16.19,0,0,1,815.71,701.3Zm-76-175L628.05,590.74a1.59,1.59,0,0,0,0,2.76L808.47,697.67a14.51,14.51,0,0,0,14.48,0l104.44-60.3a1.59,1.59,0,0,0,0-2.76Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="59f4b4cb-6af8-45d9-8fb1-b6bf69eb9ea6"
            d="M739.5,480.21l25.14-149.12-2-.85,3-1.74v0a2.19,2.19,0,0,1,2.34.16l200.88,116a5.67,5.67,0,0,1,2.61,5.1L946.14,600.07a1.88,1.88,0,0,1-1.06,1.53h0l-3,1.72-.51-2.77.17.05L742.11,485.31A5.67,5.67,0,0,1,739.5,480.21Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="468caab3-e39c-402c-91cc-72f173c20de1"
            d="M967.75,448.73l3.3-1.46a4.54,4.54,0,0,1,.42,2.49L946.14,600.07a1.88,1.88,0,0,1-1.06,1.53h0l-3,1.72-.51-2.77.17.05-10.1-5.83Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            d="M739,487.12l200.88,116c1.55.9,2.88.38,3.15-1.23l25.34-150.31a5.67,5.67,0,0,0-2.61-5.1l-200.88-116c-1.55-.9-2.88-.38-3.15,1.23L736.35,482A5.67,5.67,0,0,0,739,487.12Z"
            transform="translate(-458.73 -266.05)"
          />
          <g className="f886907d-01bb-4850-8fdb-4f27a4e98d70">
            <polygon
              className="3b5a89a5-b2f8-471e-b737-c03481f95986"
              points="308.73 74.02 502.37 185.82 478.35 327.38 284.85 215.66 308.73 74.02"
            />
          </g>
          <path
            className="d0e3080d-332d-4294-a7ea-68354f18da0b"
            d="M612.68,558.28l11,5.73,109.93-63.47a8,8,0,0,1,7.18.3L930.34,610.3c1.81-1,10.09-5,10.09-5l-4,10.46h0a2.39,2.39,0,0,1-1.12,1.24L819.5,683.84a8,8,0,0,1-7.18-.3l-194-112a3.47,3.47,0,0,1-1.63-1.73h0Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="0efde1ea-4687-437d-bfb3-0420d46810ac"
            d="M614.38,560.09l198.84,114.8a8.17,8.17,0,0,0,7.35.3l118.65-68.51c1.88-1.09,1.65-3-.53-4.25L739.86,487.64a8.17,8.17,0,0,0-7.35-.3L613.85,555.84C612,556.93,612.2,558.83,614.38,560.09Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="a1d30a82-8d0a-45f2-81d4-03bc63904dc0"
            d="M873.19,650.05v-2.18c0-.21-.13-.3-.28-.21l-7.69,4.44a.67.67,0,0,0-.28.54v2.18c0,.21.13.3.28.21l7.69-4.44A.67.67,0,0,0,873.19,650.05Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="a1d30a82-8d0a-45f2-81d4-03bc63904dc0"
            d="M861.69,656.69v-2.18c0-.21-.13-.3-.28-.21l-7.69,4.44a.67.67,0,0,0-.28.54v2.18c0,.21.13.3.28.21l7.69-4.44A.67.67,0,0,0,861.69,656.69Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="5d280fe3-69b9-424b-86fc-d082b24ebfc8"
            d="M908.29,600.27l-165.24-95.4a3.56,3.56,0,0,0-3.56,0l-58.84,34a1.27,1.27,0,0,0,0,2.2l165.16,95.35a3.56,3.56,0,0,0,3.56,0l58.92-34A1.27,1.27,0,0,0,908.29,600.27Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="30943f74-bd74-41ea-b221-7a2fe5fea3fe"
            d="M908.29,602.47l-.59.34L743.06,507.75a3.56,3.56,0,0,0-3.56,0l-58.25,33.7-.59-.34a1.27,1.27,0,0,1,0-2.2l58.84-34a3.56,3.56,0,0,1,3.56,0l165.24,95.4A1.27,1.27,0,0,1,908.29,602.47Z"
            transform="translate(-458.73 -266.05)"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="276.32 247.44 419.85 330.31 426.24 326.62 282.71 243.75 276.32 247.44"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="260.68 256.47 395.3 334.2 401.69 330.51 267.06 252.79 260.68 256.47"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="250.49 262.36 409 353.87 415.38 350.19 256.87 258.67 250.49 262.36"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="240.3 268.24 398.81 359.75 405.19 356.07 246.68 264.56 240.3 268.24"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="230.11 274.12 388.61 365.64 395 361.95 236.49 270.44 230.11 274.12"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="428.53 335.31 434.91 339 441.29 335.31 434.91 331.63 428.53 335.31"
          />
          <polygon
            className="976e9bd4-4a7e-4a03-8799-76a17a19a8c6"
            points="406.02 340.38 419.26 348.03 425.64 344.35 412.4 336.7 406.02 340.38"
          />
          <path
            className="9b2dafd3-2517-4546-86df-e3562aabde44"
            d="M736.69,623.36l-44.55-25.72c-1.74-1-1.74-2.64,0-3.64l30.47-17.59a7,7,0,0,1,6.31,0l44.55,25.72c1.74,1,1.74,2.64,0,3.64L743,623.36A7,7,0,0,1,736.69,623.36Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="30943f74-bd74-41ea-b221-7a2fe5fea3fe"
            d="M773.47,603.61l-44.55-25.72a7,7,0,0,0-6.31,0l-30.47,17.59a2.73,2.73,0,0,0-1.09,1.08c-.46-.88-.11-1.87,1.09-2.57l30.47-17.59a7,7,0,0,1,6.31,0l44.55,25.72c1.2.69,1.56,1.69,1.09,2.57A2.73,2.73,0,0,0,773.47,603.61Z"
            transform="translate(-458.73 -266.05)"
          />
          <g className="626734bf-143a-4b18-8131-d75ce2abe655">
            <path
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              d="M739.66,487.4,938.5,602.2a8.17,8.17,0,0,0,7.35.3L1064.51,534c1.88-1.09,1.65-3-.53-4.25L865.14,415a8.17,8.17,0,0,0-7.35-.3L739.13,483.16C737.25,484.25,737.49,486.15,739.66,487.4Z"
              transform="translate(-458.73 -266.05)"
            />
            <path
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              d="M939.09,508l-44.55-25.72c-1.74-1-1.74-2.64,0-3.64L925,461a7,7,0,0,1,6.31,0l44.55,25.72c1.74,1,1.74,2.64,0,3.64L945.4,508A7,7,0,0,1,939.09,508Z"
              transform="translate(-458.73 -266.05)"
            />
            <path
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              d="M975.87,488.24l-44.55-25.72a7,7,0,0,0-6.31,0l-30.47,17.59a2.73,2.73,0,0,0-1.09,1.08c-.46-.88-.11-1.87,1.09-2.57L925,461a7,7,0,0,1,6.31,0l44.55,25.72c1.2.69,1.56,1.69,1.09,2.57A2.73,2.73,0,0,0,975.87,488.24Z"
              transform="translate(-458.73 -266.05)"
            />
            <path
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              d="M995.45,548.18l-165.24-95.4a3.56,3.56,0,0,0-3.56,0l-58.84,34a1.27,1.27,0,0,0,0,2.2L933,584.38a3.56,3.56,0,0,0,3.56,0l58.92-34A1.27,1.27,0,0,0,995.45,548.18Z"
              transform="translate(-458.73 -266.05)"
            />
            <path
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              d="M995.45,550.38l-.59.34L830.22,455.67a3.56,3.56,0,0,0-3.56,0l-58.25,33.7-.59-.34a1.27,1.27,0,0,1,0-2.2l58.84-34a3.56,3.56,0,0,1,3.56,0l165.24,95.4A1.27,1.27,0,0,1,995.45,550.38Z"
              transform="translate(-458.73 -266.05)"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="324.48 218.45 468.43 301.56 462.73 304.86 318.78 221.75 324.48 218.45"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="339.65 209.7 476.47 288.69 470.77 291.98 333.95 212.99 339.65 209.7"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="486.69 294.59 499.47 301.97 493.76 305.26 480.99 297.88 486.69 294.59"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="475.4 305.59 484.3 310.72 478.6 314.02 469.7 308.88 475.4 305.59"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="349.15 204.21 508.97 296.48 503.27 299.77 343.45 207.5 349.15 204.21"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="358.66 198.72 518.48 290.99 512.77 294.29 352.96 202.02 358.66 198.72"
            />
            <polygon
              className="d4880cee-d56c-4712-828c-7c220219be7b"
              points="368.16 193.24 527.98 285.51 522.28 288.8 362.46 196.53 368.16 193.24"
            />
          </g>
          <path
            className="6119a5d0-8501-413d-92cd-5d0b31b6d477"
            d="M559.23,568.94a4.68,4.68,0,0,1-2.3-.54l-97-56a2.19,2.19,0,0,1-1.2-1.87,2.64,2.64,0,0,1,1.5-2.19l20.16-11.64a5.64,5.64,0,0,1,5.15-.17l97,56a2.19,2.19,0,0,1,1.2,1.87,2.64,2.64,0,0,1-1.5,2.19l-20.16,11.64A5.83,5.83,0,0,1,559.23,568.94Zm-1.36-2.18a3.76,3.76,0,0,0,3.26-.17l20.16-11.64c.46-.27.55-.5.55-.55s0-.11-.25-.23l-97-56a3.75,3.75,0,0,0-3.25.17L461.18,510c-.46.27-.55.5-.55.55s.06.12.25.23Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="235600c7-aa84-45b5-b036-808e955f6b61"
            d="M499.36,464.3a28.79,28.79,0,0,1,7.11,6c3-9.57,12.07-12.54,22.82-6.33,8.89,5.14,16.65,15.24,20.75,25.94a9.66,9.66,0,0,1,4.48,1.42c5.8,3.35,10.51,11.5,10.51,18.2,0,.14,0,.28,0,.42a12.7,12.7,0,0,1,5.41,1.81c7.42,4.29,13.44,14.71,13.44,23.28s-6,12-13.44,7.76l-71.05-41c-9-5.17-16.23-17.76-16.23-28.1S490.4,459.12,499.36,464.3Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="16e8ad1c-642b-4fa3-80bc-c42a807d5be3"
            d="M499.36,464.3a26,26,0,0,1,4.27,3.13l7.94-4.59h0c4.36-3.07,10.68-2.93,17.71,1.12,8.89,5.14,16.65,15.24,20.75,25.94a9.66,9.66,0,0,1,4.48,1.42c5.8,3.35,10.51,11.5,10.51,18.2,0,.14,0,.28,0,.42a12.7,12.7,0,0,1,5.41,1.81c7.42,4.29,13.44,14.71,13.44,23.28,0,4.25-1.48,7.24-3.88,8.66h0l-.06,0h0L560.45,555l-4.25-20.45-56.84-32.82c-9-5.17-16.23-17.76-16.23-28.1S490.4,459.12,499.36,464.3Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="fbb10c9f-3cad-43fd-8ef6-389830ebb111"
            d="M468.08,474.67l19.72-11.46h0c2.94-1.75,7-1.53,11.56,1.08a26,26,0,0,1,4.27,3.13l7.94-4.59h0c4.36-3.07,10.68-2.93,17.71,1.12,8.89,5.14,16.65,15.24,20.75,25.94a9.66,9.66,0,0,1,4.48,1.42c5.8,3.35,10.51,11.5,10.51,18.2,0,.14,0,.28,0,.42a12.7,12.7,0,0,1,5.41,1.81c7.42,4.29,13.44,14.71,13.44,23.28,0,4.25-1.48,7.24-3.88,8.66h0l-.06,0h0L560.45,555l-4.25-20.45-56.84-32.82c-7.26-4.19-13.41-13.26-15.48-22.05Z"
            transform="translate(-458.73 -266.05)"
          />
          <path
            className="66784f56-cbe2-4e2f-b3f0-a48ea2722ded"
            d="M479.84,475.63a28.79,28.79,0,0,1,7.11,6c3-9.57,12.07-12.54,22.82-6.33,8.89,5.14,16.65,15.24,20.75,25.94a9.66,9.66,0,0,1,4.48,1.42c5.8,3.35,10.51,11.5,10.51,18.2,0,.14,0,.28,0,.42a12.71,12.71,0,0,1,5.41,1.81c7.42,4.29,13.44,14.71,13.44,23.28s-6,12-13.44,7.76l-71.05-41c-9-5.17-16.23-17.76-16.23-28.1S470.87,470.45,479.84,475.63Z"
            transform="translate(-458.73 -266.05)"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="333.79 16.93 312.55 4.66 312.55 0 333.79 12.26 333.79 16.93"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.68 23.21 337.04 18.8 337.04 14.14 344.68 18.55 344.68 23.21"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="353.12 28.09 347.93 25.09 347.93 20.42 353.12 23.42 353.12 28.09"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="324.12 22 312.55 15.32 312.55 10.65 324.12 17.34 324.12 22"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="341.55 32.06 327.37 23.87 327.37 19.21 341.55 27.4 341.55 32.06"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="349.99 36.94 344.8 33.94 344.8 29.27 349.99 32.27 349.99 36.94"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="358.02 41.57 352.83 38.57 352.83 33.91 358.02 36.91 358.02 41.57"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="320.93 30.81 312.55 25.97 312.55 21.31 320.93 26.14 320.93 30.81"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="327.03 34.33 324.17 32.68 324.17 28.02 327.03 29.67 327.03 34.33"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="335.47 39.21 330.28 36.21 330.28 31.54 335.47 34.54 335.47 39.21"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="343.46 54.47 312.55 36.63 312.55 31.96 343.46 49.81 343.46 54.47"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="354.35 60.76 346.7 56.34 346.7 51.68 354.35 56.1 354.35 60.76"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="320.93 52.12 312.55 47.28 312.55 42.62 320.93 47.45 320.93 52.12"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="340.57 63.46 324.17 53.99 324.17 49.33 340.57 58.8 340.57 63.46"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="349.02 68.34 343.82 65.34 343.82 60.67 349.02 63.67 349.02 68.34"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="329.68 67.83 312.55 57.94 312.55 53.27 329.68 63.16 329.68 67.83"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="345.32 76.86 332.93 69.7 332.93 65.04 345.32 72.19 345.32 76.86"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="356.94 83.56 348.56 78.73 348.56 74.06 356.94 78.9 356.94 83.56"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="318.34 71.93 312.55 68.59 312.55 63.93 318.34 67.27 318.34 71.93"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="329.23 78.22 321.58 73.81 321.58 69.14 329.23 73.56 329.23 78.22"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.8 87.21 332.47 80.1 332.47 75.43 344.8 82.55 344.8 87.21"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="330.28 89.48 312.55 79.25 312.55 74.58 330.28 84.82 330.28 89.48"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="350.13 100.94 333.52 91.36 333.52 86.69 350.13 96.28 350.13 100.94"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="358.57 105.82 353.37 102.82 353.37 98.15 358.57 101.15 358.57 105.82"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="366.67 110.49 361.47 107.49 361.47 102.83 366.67 105.83 366.67 110.49"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="323.17 96.03 312.55 89.9 312.55 85.24 323.17 91.37 323.17 96.03"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="367.58 36.44 382.31 44.94 382.31 40.28 367.58 31.77 367.58 36.44"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="370.74 48.91 382.31 55.59 382.31 50.93 370.74 44.25 370.74 48.91"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="373.94 61.41 382.31 66.25 382.31 61.59 373.94 56.75 373.94 61.41"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="370.13 69.87 382.31 76.9 382.31 72.24 370.13 65.21 370.13 69.87"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="373.94 82.72 382.31 87.56 382.31 82.89 373.94 78.06 373.94 82.72"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.69 92.08 382.31 98.21 382.31 93.55 371.69 87.42 371.69 92.08"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="376.52 105.53 382.31 108.87 382.31 104.2 376.52 100.86 376.52 105.53"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.1 113.05 382.31 119.52 382.31 114.86 371.1 108.39 371.1 113.05"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.69 124.05 382.31 130.18 382.31 125.51 371.69 119.38 371.69 124.05"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="330.93 100.51 326.42 97.91 326.42 93.24 330.93 95.85 330.93 100.51"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.34 108.26 334.18 102.39 334.18 97.72 344.34 103.59 344.34 108.26"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="416.15 64.3 394.91 52.03 394.91 47.37 416.15 59.63 416.15 64.3"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="427.04 70.58 419.4 66.17 419.4 61.5 427.04 65.92 427.04 70.58"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="435.49 75.46 430.29 72.46 430.29 67.79 435.49 70.79 435.49 75.46"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="406.49 69.37 394.91 62.69 394.91 58.02 406.49 64.7 406.49 69.37"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="423.91 79.43 409.73 71.24 409.73 66.58 423.91 74.77 423.91 79.43"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="432.35 84.3 427.16 81.3 427.16 76.64 432.35 79.64 432.35 84.3"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="440.39 88.94 435.19 85.94 435.19 81.28 440.39 84.28 440.39 88.94"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="403.29 78.18 394.91 73.34 394.91 68.68 403.29 73.51 403.29 78.18"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="409.39 81.7 406.53 80.05 406.53 75.39 409.39 77.04 409.39 81.7"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="417.83 86.58 412.64 83.58 412.64 78.91 417.83 81.91 417.83 86.58"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="425.82 101.84 394.91 84 394.91 79.33 425.82 97.18 425.82 101.84"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="436.71 108.13 429.06 103.71 429.06 99.05 436.71 103.47 436.71 108.13"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="403.29 99.49 394.91 94.65 394.91 89.99 403.29 94.82 403.29 99.49"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="422.94 110.83 406.53 101.36 406.53 96.69 422.94 106.17 422.94 110.83"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="431.38 115.7 426.18 112.7 426.18 108.04 431.38 111.04 431.38 115.7"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="412.05 115.2 394.91 105.31 394.91 100.64 412.05 110.53 412.05 115.2"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="427.68 124.22 415.29 117.07 415.29 112.41 427.68 119.56 427.68 124.22"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="439.3 130.93 430.92 126.1 430.92 121.43 439.3 126.27 439.3 130.93"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="400.7 119.3 394.91 115.96 394.91 111.3 400.7 114.64 400.7 119.3"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="411.59 125.59 403.94 121.18 403.94 116.51 411.59 120.93 411.59 125.59"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="427.16 134.58 414.84 127.46 414.84 122.8 427.16 129.91 427.16 134.58"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="412.64 136.85 394.91 126.62 394.91 121.95 412.64 132.19 412.64 136.85"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="432.49 148.31 415.88 138.72 415.88 134.06 432.49 143.65 432.49 148.31"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="440.93 153.19 435.73 150.19 435.73 145.52 440.93 148.52 440.93 153.19"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="449.03 157.86 443.83 154.86 443.83 150.2 449.03 153.2 449.03 157.86"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="405.53 143.4 394.91 137.27 394.91 132.61 405.53 138.74 405.53 143.4"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="449.94 83.81 464.67 92.31 464.67 87.64 449.94 79.14 449.94 83.81"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="453.1 96.28 464.67 102.96 464.67 98.3 453.1 91.62 453.1 96.28"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="456.3 108.78 464.67 113.62 464.67 108.95 456.3 104.12 456.3 108.78"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="452.49 117.24 464.67 124.27 464.67 119.61 452.49 112.58 452.49 117.24"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="456.3 130.09 464.67 134.93 464.67 130.26 456.3 125.43 456.3 130.09"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="454.05 139.45 464.67 145.58 464.67 140.92 454.05 134.79 454.05 139.45"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="458.89 152.9 464.67 156.24 464.67 151.57 458.89 148.23 458.89 152.9"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="453.46 160.42 464.67 166.89 464.67 162.23 453.46 155.75 453.46 160.42"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="454.05 171.42 464.67 177.55 464.67 172.88 454.05 166.75 454.05 171.42"
          />
          <polygon
            className="b3239348-d37d-46cf-baf6-6df45853ba95"
            points="413.29 147.88 408.78 145.28 408.78 140.61 413.29 143.22 413.29 147.88"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="426.7 155.63 416.54 149.76 416.54 145.09 426.7 150.96 426.7 155.63"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="333.79 112.88 312.55 100.62 312.55 95.95 333.79 108.22 333.79 112.88"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.68 119.17 337.04 114.75 337.04 110.09 344.68 114.51 344.68 119.17"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="353.12 124.04 347.93 121.04 347.93 116.38 353.12 119.38 353.12 124.04"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="324.12 117.96 312.55 111.27 312.55 106.61 324.12 113.29 324.12 117.96"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="341.55 128.02 327.37 119.83 327.37 115.17 341.55 123.35 341.55 128.02"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="349.99 132.89 344.8 129.89 344.8 125.23 349.99 128.23 349.99 132.89"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="358.02 137.53 352.83 134.53 352.83 129.86 358.02 132.86 358.02 137.53"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="320.93 126.76 312.55 121.93 312.55 117.27 320.93 122.1 320.93 126.76"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="327.03 130.29 324.17 128.64 324.17 123.97 327.03 125.63 327.03 130.29"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="335.47 135.16 330.28 132.16 330.28 127.5 335.47 130.5 335.47 135.16"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="343.46 150.43 312.55 132.58 312.55 127.92 343.46 145.76 343.46 150.43"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="354.35 156.72 346.7 152.3 346.7 147.64 354.35 152.05 354.35 156.72"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="320.93 148.07 312.55 143.24 312.55 138.57 320.93 143.41 320.93 148.07"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="340.57 159.42 324.17 149.95 324.17 145.28 340.57 154.75 340.57 159.42"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="349.02 164.29 343.82 161.29 343.82 156.63 349.02 159.63 349.02 164.29"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="329.68 163.78 312.55 153.89 312.55 149.23 329.68 159.12 329.68 163.78"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="345.32 172.81 332.93 165.66 332.93 160.99 345.32 168.15 345.32 172.81"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="356.94 179.52 348.56 174.68 348.56 170.02 356.94 174.85 356.94 179.52"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="318.34 167.89 312.55 164.55 312.55 159.88 318.34 163.22 318.34 167.89"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="329.23 174.18 321.58 169.76 321.58 165.1 329.23 169.51 329.23 174.18"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.8 183.16 332.47 176.05 332.47 171.39 344.8 178.5 344.8 183.16"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="330.28 185.44 312.55 175.2 312.55 170.54 330.28 180.77 330.28 185.44"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="350.13 196.9 333.52 187.31 333.52 182.65 350.13 192.23 350.13 196.9"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="358.57 201.77 353.37 198.77 353.37 194.11 358.57 197.11 358.57 201.77"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="366.67 206.45 361.47 203.45 361.47 198.78 366.67 201.78 366.67 206.45"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="323.17 191.99 312.55 185.86 312.55 181.19 323.17 187.32 323.17 191.99"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="367.58 132.39 382.31 140.9 382.31 136.23 367.58 127.73 367.58 132.39"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="370.74 144.87 382.31 151.55 382.31 146.88 370.74 140.2 370.74 144.87"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="373.94 157.37 382.31 162.21 382.31 157.54 373.94 152.71 373.94 157.37"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="370.13 165.83 382.31 172.86 382.31 168.19 370.13 161.16 370.13 165.83"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="373.94 178.68 382.31 183.51 382.31 178.85 373.94 174.02 373.94 178.68"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.69 188.04 382.31 194.17 382.31 189.5 371.69 183.37 371.69 188.04"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="376.52 201.48 382.31 204.82 382.31 200.16 376.52 196.82 376.52 201.48"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.1 209 382.31 215.48 382.31 210.81 371.1 204.34 371.1 209"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="371.69 220 382.31 226.13 382.31 221.47 371.69 215.34 371.69 220"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="330.93 196.47 326.42 193.86 326.42 189.2 330.93 191.81 330.93 196.47"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="344.34 204.21 334.18 198.34 334.18 193.68 344.34 199.55 344.34 204.21"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="352.75 240.05 335.6 230.15 335.6 225.48 352.75 235.39 352.75 240.05"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="364.5 246.84 357.74 242.94 357.74 238.27 364.5 242.17 364.5 246.84"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="416.15 160.25 394.91 147.99 394.91 143.32 416.15 155.59 416.15 160.25"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="427.04 166.54 419.4 162.12 419.4 157.46 427.04 161.87 427.04 166.54"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="435.49 171.41 430.29 168.41 430.29 163.75 435.49 166.75 435.49 171.41"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="406.49 165.32 394.91 158.64 394.91 153.98 406.49 160.66 406.49 165.32"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="423.91 175.39 409.73 167.2 409.73 162.53 423.91 170.72 423.91 175.39"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="432.35 180.26 427.16 177.26 427.16 172.59 432.35 175.59 432.35 180.26"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="440.39 184.9 435.19 181.9 435.19 177.23 440.39 180.23 440.39 184.9"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="403.29 174.13 394.91 169.3 394.91 164.63 403.29 169.47 403.29 174.13"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="409.39 177.66 406.53 176 406.53 171.34 409.39 172.99 409.39 177.66"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="417.83 182.53 412.64 179.53 412.64 174.87 417.83 177.87 417.83 182.53"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="425.82 197.8 394.91 179.95 394.91 175.29 425.82 193.13 425.82 197.8"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="436.71 204.08 429.06 199.67 429.06 195 436.71 199.42 436.71 204.08"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="403.29 195.44 394.91 190.61 394.91 185.94 403.29 190.78 403.29 195.44"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="422.94 206.79 406.53 197.31 406.53 192.65 422.94 202.12 422.94 206.79"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="431.38 211.66 426.18 208.66 426.18 204 431.38 207 431.38 211.66"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="412.05 211.15 394.91 201.26 394.91 196.6 412.05 206.49 412.05 211.15"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="427.68 220.18 415.29 213.03 415.29 208.36 427.68 215.51 427.68 220.18"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="439.3 226.89 430.92 222.05 430.92 217.39 439.3 222.22 439.3 226.89"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="400.7 215.26 394.91 211.92 394.91 207.25 400.7 210.59 400.7 215.26"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="411.59 221.55 403.94 217.13 403.94 212.47 411.59 216.88 411.59 221.55"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="427.16 230.53 414.84 223.42 414.84 218.75 427.16 225.87 427.16 230.53"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="412.64 232.81 394.91 222.57 394.91 217.91 412.64 228.14 412.64 232.81"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="432.49 244.27 415.88 234.68 415.88 230.01 432.49 239.6 432.49 244.27"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="440.93 249.14 435.73 246.14 435.73 241.48 440.93 244.48 440.93 249.14"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="449.03 253.82 443.83 250.82 443.83 246.15 449.03 249.15 449.03 253.82"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="405.53 239.36 394.91 233.23 394.91 228.56 405.53 234.69 405.53 239.36"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="449.94 179.76 464.67 188.26 464.67 183.6 449.94 175.1 449.94 179.76"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="453.1 192.24 464.67 198.92 464.67 194.25 453.1 187.57 453.1 192.24"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="456.3 204.74 464.67 209.57 464.67 204.91 456.3 200.07 456.3 204.74"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="452.49 213.2 464.67 220.23 464.67 215.56 452.49 208.53 452.49 213.2"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="456.3 226.05 464.67 230.88 464.67 226.22 456.3 221.38 456.3 226.05"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="454.05 235.41 464.67 241.54 464.67 236.87 454.05 230.74 454.05 235.41"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="458.89 248.85 464.67 252.19 464.67 247.53 458.89 244.19 458.89 248.85"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="453.46 256.37 464.67 262.85 464.67 258.18 453.46 251.71 453.46 256.37"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="454.05 267.37 464.67 273.5 464.67 268.84 454.05 262.71 454.05 267.37"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="413.29 243.84 408.78 241.23 408.78 236.57 413.29 239.17 413.29 243.84"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="426.7 251.58 416.54 245.71 416.54 241.05 426.7 246.92 426.7 251.58"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="435.11 287.42 417.96 277.52 417.96 272.85 435.11 282.76 435.11 287.42"
          />
          <polygon
            className="a1afbc3c-0da9-4a1e-8660-c368c26fdfea"
            points="446.86 294.2 440.11 290.3 440.11 285.64 446.86 289.54 446.86 294.2"
          />
        </svg>
      </React.Fragment>
    );
  }
}
svgLandingPage.propTypes = {
  width: PropTypes.string,
};

export default svgLandingPage;
