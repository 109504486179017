import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { convertToRaw, convertFromRaw } from "draft-js";
import { withStyles } from "@material-ui/core/styles";
import {
  Divider,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CircularProgress,
  Hidden,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { db } from "../firebase";
import { auth, storage } from "../firebase/firebase";
import { TitleH2, TitleH4, LightParagraph } from "../styling/Styles";
import DatePicker from "../styling/DatePicker";
import TextEditorEdit from "../styling/TextEditorEdit";
import ProgressBar from "../styling/ProgressBar";
import { challengeExplanation } from "../Data/ChallengeData";
//import RolesContext from "../session/RolesContext";

const styles = (theme) => ({
  page: {
    backgroundColor: theme.palette.grey["100"],
    padding: "3em 5em",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "1em",
  },
  contentContainer: {
    backgroundColor: "white",
    padding: "1em 10em",
  },
  divider: {
    marginBottom: "2em",
    backgroundColor: theme.palette.primary.main,
  },
  subInfoContainer: {
    marginTop: "3em",
    paddingRight: "4em",
  },
  titleWithExplanation: {
    marginBottom: "0",
  },
  explanationText: {
    marginBottom: "1em",
  },
  input: {
    borderColor: `${theme.palette.primary.light} !important`,
    borderWidth: "1px",
    borderRadius: "10px",
    textTransform: "None",
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "0.8em",
    paddingLeft: "1em",
  },
  normalTextInput: {
    width: "75%",
  },
  moneyInput: {
    width: "15%",
  },
  uploadInput: {
    display: "none",
  },
  sendButtonContainer: {
    marginTop: "4em",
    display: "flex",
    justifyContentContent: "flex-end",
  },
  sendButton: {
    borderRadius: "10px",
  },
  selection: {
    width: "50%",
    border: "2px #c8c8f5 solid",
    padding: "0 10px",
    marginTop: 10,
  },
});
class ChallengeEdit extends React.Component {
  state = {
    isAdmin: false,
    shortDescriptionCharacters: 350,
    name: "",
    shortDescription: "",
    prizeMoney: 0,
    endDate: new Date(),
    logoFile: null,
    termsAndConditionsFile: null,
    submissionExampleFile: null,
    description: null,
    prizesDescription: null,
    rulesAndEvaluation: null,
    instructions: null,
    faq: null,
    calendar: null,
    dataDescription: null,
    dataDownloadLink: "",
    tags: "",
    alertOpen: false,
    alertTitle: "",
    alertMessage: "",
    alertActions: null,
    uploadingFiles: false,
    uploadPercentage: 0,
    isLoading: true,
    resultsFormat: "percentage",
    resultsOrder: "desc",
    challenge: null,
    currentChallenge: null,
    maxSubmissionsPerDay: 3,
    numberMaxSubmissions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    type: "binnario-default",
    emailPreference: null,
  };
  async componentDidMount() {
    //this function is to verify if the current user is an Admin or not
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({ isAdmin: user });
        });
      }
    });
    try {
      const challenge = await db.getChallenge(this.props.match.params.id);
      if (challenge.val() === null) {
        this.setState({ hasError: true, isLoading: false });
      } else {
        const currentChallenge = { ...challenge.val() };
        const endDate = new Date(currentChallenge.endDate);
        const newDescription = currentChallenge.description;
        newDescription.entityMap = {};
        const newPrizesDescription = currentChallenge.prizesDescription;
        newPrizesDescription.entityMap = {};
        const newRulesAndEvaluation = currentChallenge.rulesAndEvaluation;
        newRulesAndEvaluation.entityMap = {};
        const newInstructions = currentChallenge.instructions;
        newInstructions.entityMap = {};
        const newFAQ = currentChallenge.faq;
        newFAQ.entityMap = {};
        const newCalendar = currentChallenge.calendar;
        newCalendar.entityMap = {};
        const newDataDescription = currentChallenge.dataDescription;
        newDataDescription.entityMap = {};
        this.setState({
          challenge: { ...challenge.val(), id: challenge.key },
          isLoading: false,
          name: currentChallenge.name,
          shortDescription: currentChallenge.shortDescription || "",
          prizeMoney: currentChallenge.prizeMoney,
          newEndDate: endDate,
          logoFile: currentChallenge.logoFile,
          termsAndConditionsFile: currentChallenge.termsAndConditions,
          submissionExampleFile: currentChallenge.submissionExample,
          newDescription: newDescription,
          newPrizesDescription: newPrizesDescription,
          newRulesAndEvaluation: newRulesAndEvaluation,
          newInstructions: newInstructions,
          newFAQ: newFAQ,
          newCalendar: newCalendar,
          newDataDescription: newDataDescription,
          dataDownloadLink: currentChallenge.dataDownloadLink,
          tags: currentChallenge.tags.toString(),
          resultsFormat: currentChallenge.resultsFormat,
          resultsOrder: currentChallenge.resultsOrder,
          type: currentChallenge.type,
          maxSubmissionsPerDay: currentChallenge.maxSubmissionsPerDay,
          emailPreference: currentChallenge.emailPreference,
        });
      }
    } catch (error) {
      this.setState({ hasError: true, isLoading: false });
    }
  }

  handleClose = () => {
    this.setState({ alertOpen: false });
  };

  setRef = (ref, editorId) => {
    this.setState({ [editorId]: ref });
  };

  sendData = () => {
    // Check if a field is in blank
    if (
      this.state.name === "" ||
      this.state.shortDescription === "" ||
      this.state.logoFile === null
    ) {
      let actions = (
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      );
      this.setState({
        alertOpen: true,
        alertMessage:
          "Debes completar por lo menos los campos: nombre, breve descripción, y logo",
        alertTitle: "Error: campos vacíos",
        alertActions: actions,
      });
      return;
    }
    const data = {
      name: this.state.name,
      shortDescription: this.state.shortDescription,
      prizeMoney: this.state.prizeMoney,
      endDate: this.state.endDate,
      description: convertToRaw(
        this.state.description.getEditorState().getCurrentContent()
      ),
      prizesDescription: convertToRaw(
        this.state.prizesDescription.getEditorState().getCurrentContent()
      ),
      rulesAndEvaluation: convertToRaw(
        this.state.rulesAndEvaluation.getEditorState().getCurrentContent()
      ),
      instructions: convertToRaw(
        this.state.instructions.getEditorState().getCurrentContent()
      ),
      faq: convertToRaw(this.state.faq.getEditorState().getCurrentContent()),
      calendar: convertToRaw(
        this.state.calendar.getEditorState().getCurrentContent()
      ),
      dataDescription: convertToRaw(
        this.state.dataDescription.getEditorState().getCurrentContent()
      ),
      dataDownloadLink: this.state.dataDownloadLink,
      tags: this.state.tags.split(","),
      resultsFormat: this.state.resultsFormat,
      resultsOrder: this.state.resultsOrder,
      maxSubmissionsPerDay: this.state.maxSubmissionsPerDay,
      type: this.state.type,
      emailPreference: this.state.emailPreference,
    };
    const challengeId = this.state.challenge.id;

    let challengeCreation = db.updateChallenge(challengeId, data);

    challengeCreation.then(
      () => {
        this.uploadFiles(challengeId);
      },
      () => {
        let actions = (
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        );
        this.setState({
          alertOpen: true,
          alertMessage:
            "Hubo un error al intentar editar el desafío, por favor contacta a soporte",
          alertTitle: "Error del servidor",
          alertActions: actions,
        });
      }
    );
  };

  uploadFiles = async (challengeId) => {
    this.setState({ uploadingFiles: true });
    const logoStorageRef = storage.ref(`challenges/${challengeId}/logo`);
    const termsAndConditionsStorageRef = storage.ref(
      `challenges/${challengeId}/t&c`
    );
    const submissionExampleStorageRef = storage.ref(
      `challenges/${challengeId}/submission_example`
    );
    if (this.state.logoFile) {
      const logoTask = logoStorageRef.put(this.state.logoFile);
      logoTask.on(
        "state_changed",
        (snapshot) => {
          let percentage = (
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) /
            3
          ).toFixed(2);
          this.setState({ uploadPercentage: percentage });
        },
        () => this.setState({ uploadingFiles: false })
      );

      const logoRes = await logoStorageRef.getDownloadURL();
      db.updateChallenge(challengeId, {
        logoUrl: logoRes,
      });
    }

    if (this.state.termsAndConditionsFile) {
      const termsAndConditionsTask = termsAndConditionsStorageRef.put(
        this.state.termsAndConditionsFile
      );
      termsAndConditionsTask.on(
        "state_changed",
        (snapshot) => {
          let percentage = (
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 3 +
            33
          ).toFixed(2);
          this.setState({ uploadPercentage: percentage });
        },
        () => this.setState({ uploadingFiles: false })
      );

      const termsRes = await termsAndConditionsStorageRef.getDownloadURL();
      db.updateChallenge(challengeId, {
        termsAndConditionsUrl: termsRes,
      });
    }

    if (this.state.submissionExampleFile) {
      const submissionExampleTask = submissionExampleStorageRef.put(
        this.state.submissionExampleFile
      );
      submissionExampleTask.on(
        "state_changed",
        (snapshot) => {
          let percentage = (
            ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) / 3 +
            67
          ).toFixed(2);
          this.setState({ uploadPercentage: percentage });
        },
        () => this.setState({ uploadingFiles: false })
      );

      const subRes = await submissionExampleStorageRef.getDownloadURL();
      db.updateChallenge(challengeId, {
        submissionExampleUrl: subRes,
      });
    }

    this.setState({ uploadingFiles: false });
    let actions = (
      <DialogActions>
        <Button onClick={() => window.location.reload()} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    );
    this.setState({
      alertOpen: true,
      alertMessage: "Desafío actualizado con éxito.",
      alertTitle: "Actualización exitosa",
      alertActions: actions,
    });
  };

  onTextFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  fileSelection = (event) => {
    this.setState({ [event.target.name]: event.target.files[0] });
  };

  render() {
    if (!auth.currentUser) {
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ height: window.innerHeight - 100 }}
          >
            <h1>Debes estar loggeado para ver esta página</h1>
          </Grid>
        </div>
      );
    }
    if (this.state.isLoading) {
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ height: window.innerHeight - 100 }}
          >
            <CircularProgress size={100} />
          </Grid>
        </div>
      );
    }
    if (!this.state.isAdmin) {
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ height: window.innerHeight - 100 }}
          >
            <h1>No tienes permisos para ver esta página</h1>
          </Grid>
        </div>
      );
    }
    const { classes } = this.props;
    if (this.state.challenge !== null) {
      return (
        <div>
          <Hidden mdUp>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              style={{ height: window.innerHeight - 100 }}
            >
              <h1>No puedes crear desafíos en pantallas tan pequeñas</h1>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <div className={classes.page}>
              <div className={classes.header}> Editar desafío</div>
              <div className={classes.contentContainer}>
                <TitleH2>Editar desafío</TitleH2>
                <Divider className={classes.divider} />

                <TitleH4> Nombre </TitleH4>
                <TextField
                  className={classes.normalTextInput}
                  value={this.state.name}
                  onChange={this.onTextFieldChange("name")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
                <TitleH4 className={classes.titleWithExplanation}>
                  Selecciona el tipo de desafío
                </TitleH4>
                <Select
                  labelId="challenge-result-format-label"
                  id="typeOfChallenge"
                  className={classes.selection}
                  value={this.state.type}
                  onChange={this.onTextFieldChange("type")}
                >
                  <MenuItem value="binnario-default">Binnario</MenuItem>
                  <MenuItem value="hidden">Corporativo</MenuItem>
                </Select>
                <TitleH4 className={classes.titleWithExplanation}>
                  {" "}
                  Mail de Preferencia{" "}
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  Conteste esto solo si seleccionó un desafío corporativo
                </LightParagraph>
                <TextField
                  className={classes.normalTextInput}
                  value={this.state.emailPreference}
                  onChange={this.onTextFieldChange("emailPreference")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Breve descripción (quedan
                  {this.state.shortDescriptionCharacters -
                    this.state.shortDescription.length}
                  carácteres)
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.shortDescription}
                </LightParagraph>
                <TextField
                  className={classes.normalTextInput}
                  inputProps={{ maxLength: "350" }}
                  rows="2"
                  rowsMax="3"
                  multiline
                  value={this.state.shortDescription}
                  onChange={this.onTextFieldChange("shortDescription")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Premio (USD)
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.prizeMoney}
                </LightParagraph>
                <TextField
                  type="number"
                  className={classes.moneyInput}
                  value={this.state.prizeMoney}
                  onChange={this.onTextFieldChange("prizeMoney")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Fecha de término
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.endDate}
                </LightParagraph>
                <DatePicker
                  dateDay={this.state.newEndDate.getDate()}
                  dateMonth={this.state.newEndDate.getMonth()}
                  dateYear={this.state.newEndDate.getFullYear()}
                  onChange={(endDate) => this.setState({ endDate })}
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Logo de la empresa
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.logo}
                </LightParagraph>
                <input
                  accept="image/*"
                  className={classes.uploadInput}
                  id="contained-button-logo"
                  type="file"
                  name="logoFile"
                  onChange={this.fileSelection}
                />
                <label htmlFor="contained-button-logo">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.input}
                  >
                    Subir Imagen
                  </Button>
                </label>
                <span className={classes.fileName}>
                  {this.state.logoFile && this.state.logoFile.name}
                </span>

                <TitleH4 className={classes.titleWithExplanation}>
                  Términos y condiciones
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.termsAndConditions}
                </LightParagraph>
                <input
                  accept="application/pdf"
                  className={classes.uploadInput}
                  id="contained-button-terms-and-conditions"
                  type="file"
                  name="termsAndConditionsFile"
                  onChange={this.fileSelection}
                />
                <label htmlFor="contained-button-terms-and-conditions">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.input}
                  >
                    Subir Términos y Condiciones
                  </Button>
                </label>
                <span className={classes.fileName}>
                  {this.state.termsAndConditionsFile &&
                    this.state.termsAndConditionsFile.name}
                </span>

                <TitleH4 className={classes.titleWithExplanation}>
                  Ejemplo de entrega
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {challengeExplanation.submissionExample}
                </LightParagraph>
                <input
                  accept=".csv"
                  className={classes.uploadInput}
                  id="contained-button-submission-example"
                  type="file"
                  name="submissionExampleFile"
                  onChange={this.fileSelection}
                />
                <label htmlFor="contained-button-submission-example">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.input}
                  >
                    Subir Ejemplo de Entrega
                  </Button>
                </label>
                <span className={classes.fileName}>
                  {this.state.submissionExampleFile &&
                    this.state.submissionExampleFile.name}
                </span>

                <div className={classes.subInfoContainer}>
                  <TitleH4> Información general </TitleH4>
                  <Divider className={classes.divider} />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Descripción del desafío
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.description}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newDescription)}
                    setRef={(ref) => this.setRef(ref, "description")}
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Premios
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.prizesDescription}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newPrizesDescription)}
                    setRef={(ref) => this.setRef(ref, "prizesDescription")}
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Reglas y método de evaluación
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.rulesAndEvaluation}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newRulesAndEvaluation)}
                    setRef={(ref) => this.setRef(ref, "rulesAndEvaluation")}
                  />
                  <TitleH4 className={classes.titleWithExplanation}>
                    Instrucciones sobre el formato de entrega de los resultados
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.instructions}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newInstructions)}
                    setRef={(ref) => this.setRef(ref, "instructions")}
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Preguntas Frecuentes
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.faq}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newFAQ)}
                    setRef={(ref) => this.setRef(ref, "faq")}
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Calendario
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.calendar}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newCalendar)}
                    setRef={(ref) => this.setRef(ref, "calendar")}
                  />
                </div>
                <div className={classes.subInfoContainer}>
                  <TitleH2>Datos</TitleH2>
                  <Divider className={classes.divider} />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Descripción de los datos
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.dataDescription}
                  </LightParagraph>
                  <TextEditorEdit
                    content={convertFromRaw(this.state.newDataDescription)}
                    setRef={(ref) => this.setRef(ref, "dataDescription")}
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Descarga de los datos
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.dataDownload}
                  </LightParagraph>
                  <TextField
                    fullWidth
                    value={this.state.dataDownloadLink}
                    onChange={this.onTextFieldChange("dataDownloadLink")}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.input,
                      },
                    }}
                    variant="outlined"
                  />

                  <TitleH4 className={classes.titleWithExplanation}>
                    Agrega tags (separados por coma, en minúscula y sin
                    espacios)
                  </TitleH4>
                  <LightParagraph className={classes.explanationText}>
                    {challengeExplanation.tags}
                  </LightParagraph>
                  <TextField
                    fullWidth
                    value={this.state.tags}
                    onChange={this.onTextFieldChange("tags")}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.input,
                      },
                    }}
                    variant="outlined"
                  />
                  <div className={classes.subInfoContainer}>
                    <TitleH2>Método de Evaluación</TitleH2>
                    <Typography align="left" color="primary" variant="body2">
                      Una vez rellenado este formulario, el desafío estará
                      arriba, pero falta agregarle el método evaluador el cual
                      es la función en python que evaluará las entregas de los
                      usuarios de Binnario School y entregará un puntaje que se
                      podrá ver en el ranking. Para esto es necesario saber si
                      el puntaje (score) será mostrado de mayor a menor o en
                      porcentaje o decimal.{" "}
                      <strong>
                        Además, deberás enviarnos un mail a
                        cfernandez@binnario.ai con lo siguiente:
                      </strong>
                      <ol>
                        <li>
                          {" "}
                          Función en Python con el método evaluador, la cual
                          debe retornar la variable score, como ejemplo puedes
                          ver{" "}
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/binnarioschooltest.appspot.com/o/ejemploEntregaMetodoEvaluador.py?alt=media&token=c7ccf781-0a55-4b6e-8114-7ceff3199d8f"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            aquí.
                          </a>
                        </li>
                        <li>
                          Entregar un archivo .csv que es la data real con la
                          cual el método evaluador va a comparar.{" "}
                        </li>
                        <li>
                          Deberás entregar un archivo de entrega de ejemplo, que
                          retorne una variable llamada `score` que debería
                          retornar el método evaluador. Para así poder
                          cerciorarnos que el método evaluador está funcionando
                          correctamente.
                        </li>
                      </ol>
                    </Typography>
                    <Divider className={classes.divider} />
                    <TitleH4 className={classes.titleWithExplanation}>
                      Selecciona el formato de los resultados de evaluación
                    </TitleH4>
                    <Select
                      labelId="challenge-result-format-label"
                      id="resultsFormat"
                      className={classes.selection}
                      value={this.state.resultsFormat}
                      onChange={this.onTextFieldChange("resultsFormat")}
                    >
                      <MenuItem value="percentage">Porcentaje</MenuItem>
                      <MenuItem value="decimal">Decimal</MenuItem>
                    </Select>

                    <TitleH4 className={classes.titleWithExplanation}>
                      Selecciona el orden de los resultados de evaluación
                    </TitleH4>
                    <Select
                      labelId="challenge-result-order-label"
                      id="resultsOrder"
                      className={classes.selection}
                      value={this.state.resultsOrder}
                      onChange={this.onTextFieldChange("resultsOrder")}
                    >
                      <MenuItem value="desc">Mayor a menor</MenuItem>
                      <MenuItem value="asc">Menor a mayor</MenuItem>
                    </Select>
                    <TitleH4 className={classes.titleWithExplanation}>
                      Selecciona el máximo de entregas por día que podrán
                      realizar los usuarios de Binnario School
                    </TitleH4>
                    <Select
                      labelId="challenge-result-order-label"
                      id="maxSubmissionsPerDay"
                      className={classes.selection}
                      value={this.state.maxSubmissionsPerDay}
                      onChange={this.onTextFieldChange("maxSubmissionsPerDay")}
                    >
                      {this.state.numberMaxSubmissions.map((n) => (
                        <MenuItem key={n} value={n}>
                          {n}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.sendButtonContainer}>
                  {this.state.uploadingFiles ? (
                    <div style={{ height: "300px", width: "200px" }}>
                      <ProgressBar
                        uploadPercentage={parseFloat(
                          this.state.uploadPercentage
                        )}
                      />
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.sendButton}
                      onClick={this.sendData}
                    >
                      Guardar Desafío
                    </Button>
                  )}
                </div>
              </div>
              <Dialog
                open={this.state.alertOpen}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {this.state.alertTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.alertMessage}
                  </DialogContentText>
                </DialogContent>
                {this.state.alertActions}
              </Dialog>
            </div>
          </Hidden>
        </div>
      );
    }
  }
}

ChallengeEdit.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(withStyles(styles)(ChallengeEdit));
