import React from "react";
import { firebase } from "../firebase";
import AuthUserContext from "./AuthUserContext";
import { onceGetUserInfo } from "../firebase/db";

const WithAuthentication = (Component) => {
  class WithAuthenticationClass extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged((authUser) => {
        authUser
          ? onceGetUserInfo(authUser.uid).then((snapshot) =>
              this.setState({ authUser: snapshot.val() })
            )
          : this.setState({ authUser: null });
      });
    }

    render() {
      const { authUser } = this.state;
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthenticationClass;
};

export default WithAuthentication;
