export default {
  client: {
    noSubmission: "No se han subido entregas para este desafío todavía",
    NotLogged: "Debes iniciar sesión para ver esta página",
    NotAClientAnymore:
      "Usted ya no es Cliente de Binnario, si cree que esto es un error, porfavor, contáctenos en la sección de contacto o envíenos un email a cfernandez@binnario.ai, gracias!.",
    NotAllowed: "Usted no tiene permisos para editar desafíos",
  },
  // student: {
  //   NotLogged: "Debes iniciar sesión para ver esta página",
  //   noCourseYet:
  //     "Todavía no has sido invitado a participar a ningún curso, si deseas ser profesor envíanos un correo a cfernandez@binnario.ai, si eres alumno debes esperar a que tu profesor te envíe el link de invitación",
  //   noChallenge: "Todavía no existe ningún desafío para este curso",
  //   noSubmission: "No se han subido entregas para este desafío todavía",
  // },
  admin: {
    notAnAdmin: "No eres administrador en Binnario School",
  },
  errors: {
    //suscripciones
    NotLogged: "Debes iniciar sesión para ver esta página",
    error: "Ocurrió un error en el sistema",
    jobOffer:
      "No puedes suscribirte a este oferta de trabajo porque no existe!",
    adminTryingToSuscribeError:
      "No puedes suscribirte a los cursos u ofertas de trabajo",
    jobOfferNotFound: "No existe esta oferta de trabajo",
    alreadySuscribed: "Ya estás inscrito a este desafío",
    challenge:
      "No puedes suscribirte a este desafío, debes ingresar con el mail de tu empresa",
    //generales
  },
};

export const createCourse = {
  universities: [
    "U. Academia de Humanismo Cristiano",
    "U. Adolfo Ibáñez ",
    "U. Adventista de Chile ",
    "U. Alberto Hurtado ",
    "U. Andrés Bello ",
    "U. Arturo Prat ",
    "U. Austral de Chile",
    "U. Autónoma de Chile",
    "U. Bernardo O`Higgins",
    "U. Bolivariana",
    "U. Católica de Chile",
    "U. Católica de la Santísima Concepción",
    "U. Católica de Temuco",
    "U. Católica de Valparaíso",
    "U. Católica del Maule",
    "U. Católica del Norte",
    "U. Católica Silva Henríquez",
    "U. Central de Chile",
    "U. Chileno-Británica de Cultura",
    "U. de Aconcagua",
    "U. de Antofagasta",
    "U. de Atacama",
    "U. de Chile",
    "U. de Concepción",
    "U. de La Frontera",
    "U. de La Serena",
    "U. de Las Américas",
    " U. de los Andes",
    "U. de Los Lagos",
    "U. de Magallanes",
    "U. de Playa Ancha",
    "U. de Santiago de Chile",
    "U. de Talca",
    "U. de Tarapacá de Arica",
    "U. de Valparaíso",
    "U. de Viña del Mar",
    "U. del Bío Bío",
    "U. del Desarrollo",
    "U. Diego Portales",
    "U. Finis Terrae",
    "U. Gabriela Mistral",
    "U. SEK",
    "U. La República",
    "U. Mayor",
    "U. Metropolitana de Ciencias de la Educación",
    "U. Miguel de Cervantes",
    "U. Pedro de Valdivia",
    "U. San Sebastián",
    "U. Santo Tomás",
    "U. Técnica Federico Santa María",
    "U. Tecnológica Metropolitana",
    "U. UNIACC",
    "Otra",
  ],
  currentSemester: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "+11"],
};
