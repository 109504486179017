import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, ButtonGroup, Collapse, Divider } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(() => ({
  buttonRoot: {
    border: 0,
    color: "white",
    padding: 0,
    minHeight: "0",
    justifyContent: "flex-start",
    fontSize: 14,
    fontWeight: 700,
  },
  buttonLabel: {
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#ffffff",
    marginBottom: 10,
  },
  buttonLight: {
    fontWeight: 400,
  },
}));

const ButtonGroupArrow = ({ title, buttonGroup }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box display="block">
      <Box pb={2}>
        <Button
          onClick={handleClick}
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          fullWidth
        >
          {title}
          {open ? (
            <ArrowDownwardIcon
              style={{ fontSize: "medium", color: "#ffffff" }}
            />
          ) : (
            <ArrowForwardIcon
              style={{ fontSize: "medium", color: "#ffffff" }}
            />
          )}
        </Button>
      </Box>
      <Divider className={classes.divider} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ButtonGroup orientation="vertical" color="primary" variant="text">
          {buttonGroup.map((n) => (
            <Button
              key={n}
              href={n.href}
              className={clsx(classes.buttonRoot, classes.buttonLight)}
            >
              {n.label}
            </Button>
          ))}
        </ButtonGroup>
      </Collapse>
    </Box>
  );
};

ButtonGroupArrow.propTypes = {
  buttonGroup: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default ButtonGroupArrow;
