import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const styles = () => ({
  box: {
    margin: "0 auto",
  },
});

class ErrorComponent extends React.Component {
  render() {
    const { ErrorText, classes } = this.props;
    return (
      <div>
        <Box pt="20%" width="50%" className={classes.box}>
          <Typography align="center" color="primary" variant="h5">
            {ErrorText}
          </Typography>
        </Box>
      </div>
    );
  }
}

ErrorComponent.propTypes = {
  ErrorText: PropTypes.string,
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(styles)(withRouter(ErrorComponent));
