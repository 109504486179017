const landingPage = {
  data: {
    title:
      "Conectamos desafíos de organizaciones con Data Scientists del mundo",
    companiesDescription:
      "Tenemos una comunidad global de data scientists dispuestos a resolver desafíos de analitica avanzada mediante competencias",
    companiesDescription2:
      "Colaboramos en el reclutamiento de Data Scientists utilizando un innovador proceso de evaluación técnica de los postulantes",
    dataScientistsDescription:
      "Se parte de esta comunidad que te invita a ser premiado por resolver desafíos reales de las empresas mas relevantes del mundo",
    dataScientistsDescription2:
      "Al pertenecer a nuestra comunidad recibirás ofertas laborales de las empresas más importantes de Latinoamérica",
  },
};

export default landingPage;
