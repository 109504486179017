import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, ButtonGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  buttonRoot: {
    border: 0,
    color: "white",
    padding: 0,
    minHeight: "0",
    justifyContent: "flex-start",
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
  },
  label: {
    color: "white",
    fontSize: 16,
    lineHeight: 1.75,
  },
}));

const ButtonGroupList = ({ title, buttonGroup, gutterBottom }) => {
  const classes = useStyles();

  return (
    <Box pb={gutterBottom ? 3 : 0}>
      <Box pb={2}>
        <Typography variant="body1" color="secondary" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <ButtonGroup orientation="vertical" color="primary" variant="text">
        {buttonGroup.map((n, idx) =>
          n.href ? (
            <Button
              key={idx}
              classes={{ root: classes.buttonRoot }}
              href={n.href}
            >
              {n.label}
            </Button>
          ) : (
            <Typography key={idx} className={classes.label}>
              {n.label}
            </Typography>
          )
        )}
      </ButtonGroup>
    </Box>
  );
};
ButtonGroupList.propTypes = {
  title: PropTypes.string,
  gutterBottom: PropTypes.string,
  buttonGroup: PropTypes.object,
};

export default ButtonGroupList;
