import React from "react";
import Footer from "../components/Footer";
import JobOfferLanding from "../components/JobOffers/JobOfferLanding";

class CreateJobOffer extends React.Component {
  render() {
    return (
      <div>
        <JobOfferLanding />
        <Footer />
      </div>
    );
  }
}

export default CreateJobOffer;
