import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChallengeInfo from "./Tabs/ChallengeInfo";
import ChallengeRanking from "./Tabs/ChallengeRanking";
import Submission from "../../views/Submission";
import SubmissionsList from "./Tabs/ChallengeUserSubmissionList";
import RolesContext from "../../session/RolesContext";
import { auth } from "../../firebase/firebase";

const styles = () => ({
  root: {
    width: "100%",
    backgroundColor: "#FFFF",
  },
  tabRoot: {
    textTransform: "initial",
    color: "#251A6A",
  },
  indicator: {
    backgroundColor: "#251A6A",
  },
});

class ChallengeTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge: props.challenge,
      isAdmin: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({
            isAdmin: user,
          });
        });
      }
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, changeTab, activeTab, challenge, challengeId } =
      this.props;
    const { isClient } = this.context;
    const isAdmin = this.state.isAdmin;
    return (
      <div className={classes.root}>
        <Paper square elevation={5}>
          <Tabs
            value={activeTab}
            variant="fullWidth"
            onChange={changeTab}
            classes={{ indicator: classes.indicator }}
          >
            <Tab label="Información" classes={{ root: classes.tabRoot }} />
            <Tab
              label="Entregas"
              disabled={
                auth.currentUser === null || challenge.type === "old-binnario"
              }
              classes={{ root: classes.tabRoot }}
            />
            <Tab label="Ranking Público" classes={{ root: classes.tabRoot }} />

            {challenge.isActive && (
              <Tab
                label="Subir entrega"
                disabled={auth.currentUser === null}
                classes={{ root: classes.tabRoot }}
              />
            )}
            {(isAdmin || isClient || !challenge.isActive) && (
              <Tab
                label="Ranking Privado"
                disabled={
                  auth.currentUser === null || challenge.type === "old-binnario"
                }
                classes={{ root: classes.tabRoot }}
              />
            )}
          </Tabs>
        </Paper>
        <div className={classes.infoContainer}>
          {activeTab === 0 && (
            <div>
              <ChallengeInfo
                scrollTo={this.props.scrollTo}
                scrollToUndefined={this.props.scrollToUndefined}
                challenge={challenge}
              />
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <SubmissionsList
                challengeId={challengeId}
                challenge={challenge}
              />
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <ChallengeRanking
                variant="public"
                challengeId={challengeId}
                challenge={challenge}
              />
            </div>
          )}

          {activeTab === 3 && challenge.isActive && (
            <div>
              <Submission challengeId={challengeId} challenge={challenge} />
            </div>
          )}
          {!challenge.isActive && activeTab === 3 && (
            <div>
              <ChallengeRanking
                variant="private"
                challengeId={challengeId}
                challenge={challenge}
              />
            </div>
          )}
          {(isAdmin || isClient) && challenge.isActive && activeTab === 4 && (
            <div>
              <ChallengeRanking
                variant="private"
                challengeId={challengeId}
                challenge={challenge}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ChallengeTabs.propTypes = {
  challengeId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
  changeTab: PropTypes.func.isRequired,
  scrollToUndefined: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  scrollTo: PropTypes.string.isRequired,
};

ChallengeTabs.contextType = RolesContext;

export default withStyles(styles)(ChallengeTabs);
