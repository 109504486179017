import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { LANDING } from "../../config/routes";

class SuccessDialog extends React.Component {
  redirect = () => {
    this.props.history.push(LANDING);
    this.props.closeDialog();
  };

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>{"¡Mensaje de contacto enviado con éxito!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              "Nos alegra que te hayas contactado con nosotros, apenas podamos responderemos tu mensaje."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.redirect}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SuccessDialog.propTypes = {
  history: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withRouter(withMobileDialog()(SuccessDialog));
