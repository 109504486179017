import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Grid, Box, Typography } from "@material-ui/core";
import ButtonGroupArrow from "../ButtonGroupArrow";
import ButtonGroupList from "../ButtonGroupList";

const styles = (theme) => ({
  main: {
    maxWidth: 1240,
    margin: "auto",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  divColor: {
    background:
      "-webkit-gradient(radial,-10.01% 21.12% ,0 , -10.01% 21.12%, 1931.13 ,color-stop(0.3048,rgba(46, 93, 147, 1) ),color-stop(0.406,rgba(43, 65, 132, 1) ),color-stop(0.5153,rgba(40, 44, 121, 1) ),color-stop(0.6368,rgba(38, 29, 112, 1) ),color-stop(0.7801,rgba(36, 21, 107, 1) ),color-stop(1,rgba(36, 18, 106, 1) ))",
  },
  divTransparent: {
    backgroundColor: "transparent",
  },
});

class SignedInLanding extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        id="footer"
        className={
          location.pathname === "/" ? classes.divTransparent : classes.divColor
        }
      >
        <Box container display="inline">
          <Hidden implementation="css" smDown>
            {/* For computer screen */}
            <Box mt={1} className={classes.main}>
              <Grid container>
                <Grid item xs={3}>
                  <ButtonGroupList
                    title={"MÁS"}
                    buttonGroup={[
                      { label: "Sobre BINNARIO", href: "/description" },
                      {
                        label: "BINNARIO School",
                        href: "https:www.binnarioschool.com",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonGroupList
                    title={"SOCIAL"}
                    buttonGroup={[
                      {
                        label: "LinkedIn",
                        href: "https://www.linkedin.com/company/binnarioai/",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonGroupList
                    title={"ÚNETE"}
                    buttonGroup={[{ label: "Crear Cuenta", href: "/signup" }]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonGroupList
                    title={"CUENTA / SOPORTE"}
                    buttonGroup={[
                      {
                        label: "Inicia Sesión",
                        href: "/signin",
                      },
                      { label: "Contáctanos", href: "/contact" },
                    ]}
                  />
                </Grid>
              </Grid>
              <Box pt={4}>
                <Grid container>
                  <Grid item xs={3} md={3}>
                    <ButtonGroupList
                      title={"INFORMACIÓN"}
                      buttonGroup={[
                        { label: "BINNARIO", href: "/" },
                        {
                          label: "BINNARIO School",
                          href: "https:www.binnarioschool.com",
                        },
                        { label: "44 Alcántara, Las Condes" },
                        { label: "Santiago, Chile" },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Hidden>
          <Hidden implementation="css" mdUp>
            {/* For mobile screen */}
            <Box p={2} mt={10} pb={4} display="block">
              <Box pb={2}>
                <ButtonGroupArrow
                  title={"MÁS"}
                  buttonGroup={[
                    { label: "Sobre Binnario", href: "/description" },
                    {
                      label: "Binnario School",
                      href: "https:www.binnarioschool.com",
                    },
                  ]}
                />
              </Box>
              <Box pb={2}>
                <ButtonGroupArrow
                  title={"SOCIAL"}
                  buttonGroup={[
                    {
                      label: "LinkedIn",
                      href: "https://www.linkedin.com/company/binnarioai/",
                    },
                  ]}
                />
              </Box>
              <Box pb={2}>
                <ButtonGroupArrow
                  title={"ÚNETE"}
                  buttonGroup={[{ label: "Crear Cuenta", href: "/signup" }]}
                />
              </Box>
              <Box pb={2}>
                <ButtonGroupArrow
                  title={"CUENTA / SOPORTE"}
                  buttonGroup={[
                    {
                      label: "Inicia Sesión",
                      href: "/signin",
                    },
                    { label: "Contáctanos", href: "/contact" },
                  ]}
                />
              </Box>
              <ButtonGroupList
                gutterBottom
                title={"INFORMACIÓN"}
                buttonGroup={[
                  { label: "Binnario", href: "/" },
                  {
                    label: "Binnario School",
                    href: "https:www.binnarioschool.com",
                  },
                  { label: "44 Alcántara, Las Condes" },
                  { label: "Santiago, Chile" },
                ]}
              />
              <Typography variant="body2" color="secondary">
                ©2021 Binnario School
              </Typography>
            </Box>
          </Hidden>
        </Box>
      </div>
    );
  }
}

SignedInLanding.propTypes = {
  course: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(withRouter(SignedInLanding));
