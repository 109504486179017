import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import {
  Paper,
  Hidden,
  Grid,
  Button,
  Box,
  Typography,
  Switch,
} from "@material-ui/core";
import { db } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  paperRoot: ({ display }) => {
    let cardStyles = {};
    if (display) {
      cardStyles = {
        backgroundColor: theme.palette.secondary.contrastText,
      };
    } else {
      cardStyles = {
        backgroundColor: "#183642",
      };
    }
    return {
      ...cardStyles,
      width: 1000,
      margin: "0 auto",
      padding: "30px",
      borderRadius: "15px",
      marginBottom: 60,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: "20px",
        padding: "10px",
      },
    };
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  typographyStyle: {
    textAlign: "left",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  chipRoot: {
    margin: "2px",
  },
  logo: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  verticalLine: {
    borderLeft: "2px solid #C0BFF6",
    height: "100%",
    paddingRight: 20,
  },
  h4Date: {
    textAlign: "center",
    color: "#00c26e",
    fontWeight: "normal",
  },
  h4Closed: {
    textAlign: "center",
    color: "#cc0000",
    fontWeight: "bold",
  },
  strongText: {
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: "1em",
    color: "#2B3E7F",
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    lineHeight: 2,
  },
  stateOfJO: ({ isActive }) => {
    const colorButton = isActive
      ? `${theme.palette.secondary.main}`
      : `${theme.palette.error.main}`;
    return {
      color: `${colorButton}`,
      paddingTop: 10,
      fontWeight: "bold",
    };
  },
}));

const JobOfferCard = ({ jobOffer, isAdmin }) => {
  const {
    id,
    company,
    jobShortDescription,
    jobType,
    logoUrl,
    isActive,
    display,
  } = jobOffer;
  const classes = useStyles({ isActive, display });
  let stateOfJO = isActive ? "Postulaciones Abiertas" : "Cerrada";

  const displayText = display ? "Ocultar" : "Mostrar";
  const [displayStatus, setDisplayStatus] = React.useState(
    display ? true : false
  );
  const [idChangedCard, setIdChangedCard] = React.useState();

  const handleClick = (id) => {
    setDisplayStatus((prev) => !prev);
    setIdChangedCard(id);
  };

  React.useEffect(() => {
    if (display !== displayStatus) {
      db.updateJobOffer(idChangedCard, {
        display: displayStatus,
      });
      window.location.reload();
    }
  }, [displayStatus, idChangedCard]);

  console.log({ isAdmin });
  if (!display && !isAdmin) {
    return null;
  }
  if (id !== "undefined") {
    return (
      <Paper classes={{ root: classes.paperRoot }}>
        <Box style={{ cursor: "pointer" }} className={classes.mainBox}>
          <Hidden smDown>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="30%"
            >
              <Box display="flex" justifyContent="center">
                <img
                  src={logoUrl}
                  alt="JobOfferLogo"
                  className={classes.logo}
                />
              </Box>
              <Typography
                variant="body1"
                align="center"
                className={classes.stateOfJO}
              >
                {stateOfJO}
              </Typography>
            </Box>
            <Grid item>
              <div className={classes.verticalLine}></div>
            </Grid>
          </Hidden>
          <Box maxWidth="600px" display="flex" flexDirection="column">
            <Typography
              className={classes.typographyStyle}
              variant="h5"
              color="secondary"
            >
              {company}
            </Typography>
            <Typography
              gutterBottom
              className={classes.typographyStyle}
              variant="body1"
              color="primary"
            >
              {jobType}
            </Typography>
            <Typography
              gutterBottom
              className={classes.typographyStyle}
              variant="body1"
              color="primary"
            >
              {jobShortDescription}
            </Typography>
          </Box>
          <Box
            p={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box display="flex" justifyContent="center" flexDirection="column">
              {isAdmin ? (
                <Box display="flex" alignItems="center" pt={2} pb={2}>
                  <Switch
                    checked={displayStatus}
                    onClick={() => handleClick(id)}
                    name="checked"
                  />
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    {displayText}
                  </Typography>
                </Box>
              ) : null}
              <Button
                gutterBottom
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                href={"job_offer/" + id}
              >
                Ver más
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  } else return null;
};

JobOfferCard.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  Card: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  width: PropTypes.string,
  jobOffer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    company: PropTypes.string.isRequired,
    companyShortDescription: PropTypes.string.isRequired,
    jobShortDescription: PropTypes.string.isRequired,
    jobType: PropTypes.string.isRequired,
    tags: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    endDate: PropTypes.number,
    isActive: PropTypes.bool,
    display: PropTypes.bool,
  }),
};

export default JobOfferCard;
