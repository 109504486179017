import React from "react";
import PropTypes from "prop-types";
import * as ROUTES from "../../config/routes";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { LinkingNH3NavBar, TitleWhiteP3 } from "../../styling/Styles";

const styles = (theme) => ({
  paperDrawer: {
    backgroundColor: theme.palette.primary.main,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    backgroundColor: "white",
    margin: "0 1em",
  },
  link: {
    margin: "1em",
    display: "flex",
    alignItems: "center",
  },
});

class LeftDrawer extends React.Component {
  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  processRedirect = (route) => {
    if (this.props.location.pathname === route) {
      this.props.onClose();
    } else {
      this.props.history.push(route);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        classes={{
          paper: classes.paperDrawer,
        }}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <div className={classes.titleContainer}>
          <TitleWhiteP3>MENU</TitleWhiteP3>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.link}>
          <LinkingNH3NavBar
            onClick={() => this.processRedirect(ROUTES.LANDING)}
          >
            Inicio
          </LinkingNH3NavBar>
        </div>
        <div className={classes.link}>
          <LinkingNH3NavBar
            onClick={() => this.processRedirect(ROUTES.CHALLENGES)}
          >
            Desafíos
          </LinkingNH3NavBar>
        </div>
        <div className={classes.link}>
          <LinkingNH3NavBar
            onClick={() => this.processRedirect(ROUTES.JOB_OFFERS)}
          >
            Ofertas Laborales
          </LinkingNH3NavBar>
        </div>
        <div className={classes.link}>
          <LinkingNH3NavBar
            href="https://www.binnarioschool.com"
            target="_blank"
          >
            Binnario School
          </LinkingNH3NavBar>
        </div>
      </Drawer>
    );
  }
}

LeftDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withRouter(LeftDrawer));
