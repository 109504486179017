import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  googleTranslator: {
    "& .goog-te-gadget-simple": {
      backgroundColor: "transparent !important",
      border: "none !important",
    },
    "& .goog-te-gadget-simple .goog-te-menu-value, .goog-te-gadget-simple .goog-te-menu-value span":
      {
        textTransform: "uppercase !important",
        color: "#fff !important",
        fontWeight: "bold",
      },
    "& .goog-te-gadget-simple .goog-te-gadget-icon": {
      display: "none",
    },
  },
}));

const useScript = (url, async) => {
  React.useEffect(() => {
    const googleTranslateApiScript = document.createElement("script");
    const translateElementScript = document.createElement("script");

    googleTranslateApiScript.src = url;
    googleTranslateApiScript.type = "text/javascript";
    googleTranslateApiScript.async = async;

    translateElementScript.type = "text/javascript";
    translateElementScript.text = `
      function googleTranslateElementInit() { 
        new google.translate.TranslateElement(
            {pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 
            'google_translate_element'
        )
      }
    `;
    translateElementScript.async = async;

    document.body.appendChild(googleTranslateApiScript);
    document.body.appendChild(translateElementScript);

    return () => {
      document.body.removeChild(googleTranslateApiScript);
      document.body.removeChild(translateElementScript);
    };
  }, [url]);
};

export const GoogleTranslateIntegration = () => {
  const classes = useStyles();
  useScript(
    "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
    true
  );

  return (
    <Box className={classes.googleTranslator} id="google_translate_element" />
  );
};

export default GoogleTranslateIntegration;
