export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const CHALLENGE = "/challenge/:id";
export const CHALLENGES = "/challenges";
export const CHALLENGE_CREATE = "/challenge_create";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const SUBMISSION = "/submission";
export const CONTACT = "/contact";
export const PARSETEXT = "/parse";
export const DESCRIPTION = "/description";
export const CHALLENGE_EDIT = "/challenge_edit/:id";
export const CONFIRMATION_PAGE = "/confirmation_page";
export const CREATE_JOB_OFFER = "/create_job_offer";
export const JOB_OFFERS = "/job_offers";
export const JOB_OFFER = "/job_offer/:id";
export const CHALLENGE_INVITATION = "/challenge_invitation/:id";
export const CHALLENGE_SUSCRIPTION = "/challenge_suscription/:id";
export const CHALLENGE_HIDDEN = "/challenges_corporative";
