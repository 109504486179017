import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../../styling/Logo";
import NavBarButtons from "./NavBarButtons";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "./Tabs";
import LeftDrawer from "./LeftDrawer";
import AvatarComponent from "./AvatarComponent";
import { db } from "../../firebase";
import { auth } from "../../firebase/firebase";
import * as ROUTES from "../../config/routes";
import { withRouter } from "react-router";
import { Linking } from "../../styling/Styles";
import { Paper } from "@material-ui/core";

const styles = () => ({
  divColor: {
    background:
      "-webkit-gradient(radial,-10.01% 21.12% ,0 , -10.01% 21.12%, 1931.13 ,color-stop(0,rgba(66, 232, 224, 1) ),color-stop(0.0346,rgba(63, 211, 213, 1) ),color-stop(0.1192,rgba(57, 166, 188, 1) ),color-stop(0.2097,rgba(51, 126, 166, 1) ),color-stop(0.3048,rgba(46, 93, 147, 1) ),color-stop(0.406,rgba(43, 65, 132, 1) ),color-stop(0.5153,rgba(40, 44, 121, 1) ),color-stop(0.6368,rgba(38, 29, 112, 1) ),color-stop(0.7801,rgba(36, 21, 107, 1) ),color-stop(1,rgba(36, 18, 106, 1) ))",
  },
  // divTransparent: {
  //   backgroundColor: "transparent",
  // },
  container: {
    backgroundColor: "inherit",
    maxWidth: 1440,
    margin: "auto",
    height: "6rem",
    display: "flex",
    alignItems: "center",
  },
});

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      drawerOpen: false,
      isAdmin: false,
      isUserSuscribed: false,
    };
  }

  async componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          user = idTokenResult.claims.admin;
          this.setState({
            isAdmin: user,
          });
        });
        try {
          if (auth.currentUser != null) {
            const isSuscribed = await db.getSuscriptionsByUser(
              auth.currentUser.uid
            );
            if (
              isSuscribed.val() != null &&
              Object.keys(isSuscribed.val()).length !== 0 &&
              isSuscribed.val().constructor === Object
            ) {
              this.setState({ isUserSuscribed: true, isLoading: false });
            }
          }
        } catch (error) {
          console.log(error, "error");
          null;
        }
      }
    });
  }

  render() {
    const {
      classes,
      //  location
    } = this.props;
    const { isUserSuscribed } = this.state;
    return (
      <div
        className={
          // location.pathname === "/" ? classes.divTransparent : classes.divColor
          classes.divColor
        }
      >
        <Paper className={classes.container} elevation={0}>
          <Hidden mdUp>
            <Grid item xs={1} sm={1}>
              <IconButton
                aria-label="Menu"
                onClick={() => this.setState({ drawerOpen: true })}
              >
                <Icon fontSize="large" style={{ color: "white" }}>
                  menu
                </Icon>
              </IconButton>
              <LeftDrawer
                isAdmin={this.state.isAdmin}
                open={this.state.drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
              />
            </Grid>
          </Hidden>
          <Grid item xs={5} sm={8} md={2}>
            <Linking onClick={() => this.props.history.push(ROUTES.LANDING)}>
              <Logo white />
            </Linking>
          </Grid>
          <Hidden smDown>
            <Grid item md={8}>
              <Grid container justifyContent="center">
                <Tabs
                  isAdmin={this.state.isAdmin}
                  isUserSuscribed={isUserSuscribed}
                />
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={6} sm={3} md={2}>
            {auth.currentUser ? (
              <AvatarComponent authUser={auth.currentUser} />
            ) : (
              <NavBarButtons />
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

Navigation.propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Navigation));
