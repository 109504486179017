import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { convertToRaw } from "draft-js";
import { withStyles } from "@material-ui/core/styles";
import {
  Divider,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
} from "@material-ui/core";
import RolesContext from "../../session/RolesContext";
import { db } from "../../firebase";
import { auth, storage } from "../../firebase/firebase";
import { TitleH2, TitleH4, LightParagraph } from "../../styling/Styles";
import TextEditor from "../../styling/TextEditor";
import DatePicker from "../../styling/DatePicker";
import ProgressBar from "../../styling/ProgressBar";
import { JOB_OFFERS } from "../../config/routes";

const explanations = {
  company: "Indica el premio total de la competencia",
  companyShortDescription: "Ingresa una breve descripción de la empresa",
  jobShortDescriptionL: "Ingresa una breve descripción del cargo",
  logo: "Sube el logo de la empresa",
  jobType: "Nombre del Cargo",
  jobDescription:
    "Describa en que consiste el cargo, requerimientos, pasos a seguir, etc.",
  tags: "tags del area de la compania y el cargo, por ejemplo: Data Analist, Telecomunicaciones",
  endDate: "Indica la fecha en la cuál se cerrará la oferta de trabajo",
};

const styles = (theme) => ({
  page: {
    backgroundColor: theme.palette.grey["100"],
    padding: "3em 5em",
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    padding: "1em",
  },
  contentContainer: {
    backgroundColor: "white",
    padding: "1em 10em",
  },
  divider: {
    marginBottom: "2em",
    backgroundColor: theme.palette.secondary.main,
  },
  subInfoContainer: {
    marginTop: "3em",
    paddingRight: "4em",
  },
  titleWithExplanation: {
    marginBottom: "0",
  },
  explanationText: {
    marginBottom: "1em",
  },
  input: {
    borderColor: `${theme.palette.secondary.light} !important`,
    borderWidth: "1px",
    borderRadius: "10px",
    textTransform: "None",
  },
  fileName: {
    color: theme.palette.secondary.main,
    fontSize: "0.8em",
    paddingLeft: "1em",
  },
  normalTextInput: {
    width: "75%",
  },
  moneyInput: {
    width: "15%",
  },
  uploadInput: {
    display: "none",
  },
  sendButtonContainer: {
    marginTop: "4em",
    display: "flex",
    justifyContentContent: "flex-end",
  },
  sendButton: {
    borderRadius: "10px",
  },
  selection: {
    width: "50%",
    border: "2px #c8c8f5 solid",
    padding: "0 10px",
    marginTop: 10,
  },
});
class CreateJobOfferForm extends React.Component {
  state = {
    isAdmin: false,
    shortDescriptionCharacters: 350,
    company: "",
    companyShortDescription: "",
    jobType: "",
    logoFile: null,
    endDate: new Date(),
    jobShortDescription: "",
    jobDescription: null,
    tags: "",
    alertOpen: false,
    alertTitle: "",
    alertMessage: "",
    alertActions: null,
    uploadingFiles: false,
    uploadPercentage: 0,
    isLoading: true,
    colorTextEditor: "#A6BE13",
  };
  componentDidMount() {
    //this function is to verify if the current user is an Teacher or not.
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          //if its a Teacher its going to be true
          user = idTokenResult.claims.admin;
          this.setState({
            isAdmin: user,
          });
        });
      }
    });
  }
  redirectToJobOffer = (route) => {
    this.props.history.push(JOB_OFFERS.replace(":id", route));
  };
  handleClose = () => {
    this.setState({ alertOpen: false });
  };
  setRef = (ref, editorId) => {
    this.setState({ [editorId]: ref });
  };
  sendData = () => {
    // Check if a field is in blank
    if (
      this.state.company === "" ||
      this.state.companyShortDescription === "" ||
      this.state.logoFile === null ||
      this.state.jobType === "" ||
      this.state.jobShortDescription === "" ||
      this.state.jobDescription
        .getEditorState()
        .getCurrentContent()
        .hasText() === false ||
      this.state.tags === ""
    ) {
      let actions = (
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Aceptar
          </Button>
        </DialogActions>
      );
      this.setState({
        alertOpen: true,
        alertMessage: "Debes completar todos los campos",
        alertTitle: "Error: campos vacíos",
        alertActions: actions,
      });
      return;
    }
    let jobOfferCreation = db.createJobOffer(
      auth.currentUser.uid,
      this.state.company,
      this.state.companyShortDescription,
      this.state.endDate,
      this.state.jobType,
      this.state.jobShortDescription,
      convertToRaw(
        this.state.jobDescription.getEditorState().getCurrentContent()
      ),
      this.state.tags.split(",")
    );
    let jobOfferId = jobOfferCreation.id;
    jobOfferCreation.promess.then(
      () => {
        this.uploadFiles(jobOfferId);
      },
      () => {
        let actions = (
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        );
        this.setState({
          alertOpen: true,
          alertMessage:
            "Hubo un error al intentar crear la Oferta de Trabajo, por favor contacta a soporte",
          alertTitle: "Error del servidor",
          alertActions: actions,
        });
      }
    );
  };

  uploadFiles = (jobOfferId) => {
    this.setState({ uploadingFiles: true });
    const logoStorageRef = storage.ref(`jobOffers/${jobOfferId}/logo`);
    const logoTask = logoStorageRef.put(this.state.logoFile);
    logoTask.on(
      "state_changed",
      (snapshot) => {
        let percentage = (
          ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) /
          3
        ).toFixed(2);
        this.setState({ uploadPercentage: percentage });
      },
      () => this.setState({ uploadingFiles: false }),
      () => {
        this.setState({ uploadingFiles: false });
        let promises = [logoStorageRef.getDownloadURL()];
        Promise.all(promises).then((results) => {
          db.updateJobOffer(jobOfferId, {
            logoUrl: results[0],
          }).then(() => {
            let actions = (
              <DialogActions>
                <Button
                  onClick={() => this.redirectToJobOffer(jobOfferId)}
                  color="secondary"
                >
                  Aceptar
                </Button>
              </DialogActions>
            );
            this.setState({
              alertOpen: true,
              alertMessage: "Desafío creado con éxito!",
              alertTitle: "Creación exitosa",
              alertActions: actions,
            });
          });
        });
      }
    );
  };

  onTextFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  fileSelection = (event) => {
    this.setState({ [event.target.name]: event.target.files[0] });
  };

  render() {
    const isAdmin = this.state.isAdmin;
    const { classes } = this.props;
    if (!auth.currentUser) {
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ height: window.innerHeight - 100 }}
          >
            <h1>Debes estar loggeado para ver esta página</h1>
          </Grid>
        </div>
      );
    }
    if (isAdmin) {
      return (
        <div>
          <Hidden mdUp>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              style={{ height: window.innerHeight - 100 }}
            >
              <h1>No puedes crear desafíos en pantallas tan pequeñas</h1>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <div className={classes.page}>
              <div className={classes.header}>
                Publicar una Nueva Oferta de Trabajo{" "}
              </div>
              <div className={classes.contentContainer}>
                <TitleH2>Nuevo Oferta de Trabajo</TitleH2>
                <Divider className={classes.divider} />

                <TitleH4> Nombre Compañia </TitleH4>
                <TextField
                  className={classes.normalTextInput}
                  value={this.state.company}
                  onChange={this.onTextFieldChange("company")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
                <TitleH4 className={classes.titleWithExplanation}>
                  Breve descripción de la Compañia (quedan{" "}
                  {this.state.shortDescriptionCharacters -
                    this.state.companyShortDescription.length}{" "}
                  carácteres)
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.companyShortDescription}
                </LightParagraph>
                <TextField
                  className={classes.normalTextInput}
                  inputProps={{ maxLength: "350" }}
                  rows="2"
                  rowsMax="3"
                  multiline
                  value={this.state.companyShortDescription}
                  onChange={this.onTextFieldChange("companyShortDescription")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
                <TitleH4 className={classes.titleWithExplanation}>
                  Fecha de Cierre
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.endDate}
                </LightParagraph>
                <DatePicker
                  onChange={(endDate) => this.setState({ endDate })}
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Logo/Imagen
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.logo}
                </LightParagraph>
                <input
                  accept="image/*"
                  className={classes.uploadInput}
                  id="contained-button-logo"
                  type="file"
                  name="logoFile"
                  onChange={this.fileSelection}
                />
                <label htmlFor="contained-button-logo">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    className={classes.input}
                  >
                    Subir Imagen
                  </Button>
                </label>
                <span className={classes.fileName}>
                  {this.state.logoFile && this.state.logoFile.name}
                </span>

                <TitleH4> Nombre del Cargo </TitleH4>
                <TextField
                  className={classes.normalTextInput}
                  value={this.state.jobType}
                  onChange={this.onTextFieldChange("jobType")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
                <TitleH4 className={classes.titleWithExplanation}>
                  Breve descripción del cargo (quedan{" "}
                  {this.state.shortDescriptionCharacters -
                    this.state.jobShortDescription.length}{" "}
                  carácteres)
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.jobShortDescription}
                </LightParagraph>
                <TextField
                  className={classes.normalTextInput}
                  inputProps={{ maxLength: "350" }}
                  rows="2"
                  rowsMax="3"
                  multiline
                  value={this.state.jobShortDescription}
                  onChange={this.onTextFieldChange("jobShortDescription")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
                <TitleH4 className={classes.titleWithExplanation}>
                  Descripción del Cargo
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.jobDescription}
                </LightParagraph>
                <TextEditor
                  color={this.state.colorTextEditor}
                  setRef={(ref) => this.setRef(ref, "jobDescription")}
                />

                <TitleH4 className={classes.titleWithExplanation}>
                  Agrega tags (separados por coma, en minúscula y sin espacios)
                  por ejemplo, regresión, clasificación, inventario
                </TitleH4>
                <LightParagraph className={classes.explanationText}>
                  {explanations.tags}
                </LightParagraph>
                <TextField
                  fullWidth
                  value={this.state.tags}
                  onChange={this.onTextFieldChange("tags")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.input,
                    },
                  }}
                  variant="outlined"
                />
              </div>
              <div className={classes.sendButtonContainer}>
                {this.state.uploadingFiles ? (
                  <div style={{ height: "300px", width: "200px" }}>
                    <ProgressBar
                      uploadPercentage={parseFloat(this.state.uploadPercentage)}
                    />
                  </div>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.sendButton}
                      onClick={this.sendData}
                    >
                      Subir Oferta de Trabajo
                    </Button>
                  </div>
                )}
              </div>

              <Dialog
                open={this.state.alertOpen}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {this.state.alertTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.alertMessage}
                  </DialogContentText>
                </DialogContent>
                {this.state.alertActions}
              </Dialog>
            </div>
          </Hidden>
        </div>
      );
    } else
      return (
        <div>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ height: window.innerHeight - 100 }}
          >
            <h1>No tienes permisos para ver esta página</h1>
          </Grid>
        </div>
      );
  }
}

CreateJobOfferForm.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
};

CreateJobOfferForm.contextType = RolesContext;

export default withRouter(withStyles(styles)(CreateJobOfferForm));
