import React from "react";
import DescriptionComponent from "../components/description/DescriptionComponent";
class Description extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div style={{ padding: "10%" }}>
          <DescriptionComponent />
        </div>
      </React.Fragment>
    );
  }
}

export default Description;
