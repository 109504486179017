import React from 'react';
import './ContactFormImage.css';

class ContactFormImage extends React.Component {
  render() {
    return (
      <svg
        width="300px"
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 466.17 503.23'>
        <defs>
          <linearGradient id='a4db1be3-7542-49e3-afa1-e3cf4f33f029' x1='243.05'
            y1='280.2' x2='259.98' y2='280.2' gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#5761d7' />
            <stop offset='0.07' stopColor='#5472d8' />
            <stop offset='0.28' stopColor='#4e9cdb' />
            <stop offset='0.48' stopColor='#49bddd' />
            <stop offset='0.67' stopColor='#45d5df' />
            <stop offset='0.85' stopColor='#43e3e0' />
            <stop offset='1' stopColor='#42e8e0' />
          </linearGradient>
          <linearGradient id='f71ae59e-aa77-4d5e-a015-03b5d447d537' x1='251.67'
            y1='243' x2='294.06' y2='243' xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029'
          />
          <linearGradient id='a9ff6bf6-f8e1-4214-bfc7-439b10809344' x1='251.86'
            y1='201.25' x2='297.8' y2='201.25' xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029'
          />
          <linearGradient id='fe86dcd0-b9f3-4162-bba1-362a310f14d5' x1='330.35'
            y1='368.31' x2='358.21' y2='368.31' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='f7ec24c7-8940-40bf-bc68-134861a232d2' x1='362.54'
            y1='151.12' x2='366.46' y2='151.12' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='fdb30a51-a8be-4fd8-bd61-32567b0c93e2' x1='303.56'
            y1='290.74' x2='370.44' y2='290.74' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='a5a328b0-743f-4d6a-bc01-6fe3b82560ee' x1='366.98'
            y1='211.51' x2='382.8' y2='211.51' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='be31441e-5e1a-42aa-8b4d-70b8a630b62c' x1='268.23'
            y1='353.28' x2='322.33' y2='353.28' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='ac8b5b28-6ea9-44c8-9ad0-ad859f1596bb' x1='298.61'
            y1='333.71' x2='305.5' y2='333.71' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='bd698e9d-be11-4aa9-8bd3-6dd2bae920f6' x1='285.59'
            y1='320.65' x2='292.46' y2='320.65' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='ecdb26b1-820d-4392-8c19-6d6a06f5bd89' x1='298.76'
            y1='261.07' x2='349.94' y2='261.07' gradientTransform='matrix(1 -.09 .09 1 -41.23 14.74)'
            xlinkHref='#a4db1be3-7542-49e3-afa1-e3cf4f33f029' />
          <linearGradient id='be81a806-6ce1-4524-807a-47206f8ad0cf' x1='-38.11'
            y1='293.38' x2='160.41' y2='293.38' gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#5761d7' />
            <stop offset='0.16' stopColor='#5e67da' />
            <stop offset='0.41' stopColor='#727ae2' />
            <stop offset='0.69' stopColor='#9297ee' />
            <stop offset='1' stopColor='#bcbeff' />
          </linearGradient>
          <linearGradient id='f2c9e2e7-80b6-412c-958b-c13be6000712' x1='18.07' y1='266.55'
            x2='137.23' y2='266.55' gradientUnits='userSpaceOnUse'>
            <stop offset='0' stopColor='#42e8e0' />
            <stop offset='0.15' stopColor='#43e3e0' />
            <stop offset='0.33' stopColor='#45d5df' />
            <stop offset='0.52' stopColor='#49bddd' />
            <stop offset='0.72' stopColor='#4e9cdb' />
            <stop offset='0.93' stopColor='#5472d8' />
            <stop offset='1' stopColor='#5761d7' />
          </linearGradient>
          <linearGradient id='b83f2e42-961b-47fb-bbda-590ddc6769fc' x1='77.65' y1='245.02'
            x2='137.23' y2='245.02' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='b538d6a1-6147-4670-bcaa-f9f8e6e93d8e' x1='18.07' y1='245.02'
            x2='77.65' y2='245.02' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='ebc64414-e790-4963-9e48-334c1daeb5cc' x1='18.07' y1='257.88'
            x2='137.23' y2='257.88' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a22feffe-8c31-415d-af34-d466c41cfdd1' x1='77.65' y1='279.42'
            x2='137.23' y2='279.42' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='b80dfda1-0523-45d1-b3ba-5eb90a9c9e7b' x1='18.07' y1='279.42'
            x2='77.65' y2='279.42' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='b8b5949d-3d63-458a-8616-ac227e9ce58b' x1='-38.11'
            y1='454.06' x2='160.41' y2='454.06' xlinkHref='#be81a806-6ce1-4524-807a-47206f8ad0cf'
          />
          <linearGradient id='a0c473ca-7b8d-4a8f-9191-7c60416d3805' x1='18.07' y1='427.24'
            x2='137.23' y2='427.24' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='e312ff01-3476-4aae-925d-4574d5b9b4a2' x1='77.65' y1='405.7'
            x2='137.23' y2='405.7' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='ac3b7f8f-3e29-4faf-a27b-5208c77711c2' x1='18.07' y1='405.7'
            x2='77.65' y2='405.7' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='f0a3a4cc-f665-4d09-95a4-d77d0240a3e9' x1='18.07' y1='418.56'
            x2='137.23' y2='418.56' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='ecd2412c-e241-44ef-935e-44ffcd06f50c' x1='77.65' y1='440.1'
            x2='137.23' y2='440.1' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='bc8da2b8-c00a-4d61-becb-19c2c4c55cc4' x1='18.07' y1='440.1'
            x2='77.65' y2='440.1' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='fa091e48-e6be-4942-9760-22093d101e54' x1='272.38'
            y1='293.38' x2='470.9' y2='293.38' xlinkHref='#be81a806-6ce1-4524-807a-47206f8ad0cf'
          />
          <linearGradient id='edce7322-356d-4e24-8cfe-019331c3312b' x1='328.56'
            y1='266.55' x2='447.72' y2='266.55' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a24ea1ce-5896-4fde-b0a9-a7659479b861' x1='388.14'
            y1='245.02' x2='447.72' y2='245.02' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='b1182014-8ecd-42bd-841a-c54615ba1735' x1='328.56'
            y1='245.02' x2='388.14' y2='245.02' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a7f8d264-d532-4307-8443-a34e118a8bc2' x1='328.56'
            y1='257.88' x2='447.72' y2='257.88' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a47f7b9b-7167-4446-b62c-0254ec1c27bb' x1='388.14'
            y1='279.42' x2='447.72' y2='279.42' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a156e741-2777-4756-aa0e-b57c6803ce66' x1='328.56'
            y1='279.42' x2='388.14' y2='279.42' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='be5e1ed5-c4bd-4260-bba0-f50468d8ad27' x1='272.38'
            y1='454.06' x2='470.9' y2='454.06' xlinkHref='#be81a806-6ce1-4524-807a-47206f8ad0cf'
          />
          <linearGradient id='ac9e00cd-3ad3-40de-95f5-627c8ed4a068' x1='328.56'
            y1='427.24' x2='447.72' y2='427.24' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='b8f5c4b3-ec89-4f15-becc-4753bdc2b16d' x1='388.14'
            y1='405.7' x2='447.72' y2='405.7' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='f0616b00-6a43-44d9-a4ae-e698a1580b19' x1='328.56'
            y1='405.7' x2='388.14' y2='405.7' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a71e4203-4de7-490c-8374-e00bd05ca238' x1='328.56'
            y1='418.56' x2='447.72' y2='418.56' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='a07e6c6f-46c9-4e5e-b176-d5763d7c6b0a' x1='388.14'
            y1='440.1' x2='447.72' y2='440.1' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
          <linearGradient id='bdce9435-86cc-40df-8874-cd27e2f889dd' x1='328.56'
            y1='440.1' x2='388.14' y2='440.1' xlinkHref='#f2c9e2e7-80b6-412c-958b-c13be6000712'
          />
        </defs>
        <g className='bf178724-38cf-4bbd-b137-f30d5470125d'>
          <g id='a727b179-c10b-4e2a-b2e3-d950a52f742b' data-name='Capa 1'>
            <ellipse className='a1119d3d-0e09-4fce-838e-f30f8cd06be2' cx='232.43'
              cy='337.41' rx='95.87' ry='55.35' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M278.93,313.68l.18.86.27-.57s1.31,4.46.18,6.69a52.67,52.67,0,0,1-5.15,8.11c-2.37,3.12-6.17,9.6-7.16,10.85a24.48,24.48,0,0,1-14,6.84c-7.24.75-9.08-3.39-8.49-5.64s4.5-8.19,8.74-13A65,65,0,0,0,261,316.71Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M257.18,294a33.35,33.35,0,0,1-.27,6.56c-.48,1.91-11.11,2.5-14.9,3s-10.37,3.18-12.42,4.19-15.88,2.47-17.56-2.62,6.92-9.36,12.76-10.7,15.39-4.27,15.39-4.27Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M294.05,202.08s.38,13-4,31-3.82,30.55-3.82,30.55,4.55,20.34.55,34.21-7.38,17.77-7.38,17.77-8,10.58-18.47,1.1c0,0,5.89-19.07,6.59-34.24,0,0-5.2-11.15-7-21.1,0,0-1.5,15.81-2.4,22.2,0,0,.9,9.38-1.6,13.17s-13.77,4.9-17.37-1.69,3-3.37,1-33.68c0,0-10.38-12.94-11.88-36.75s-1.35-33.25,0-37.89,24.36-21.36,24.36-21.36l13,16.57Z'
              transform='translate(-46 -21.88)' />
            <path className='b26f79e8-157c-459d-9e85-1e75e61e38f6' d='M260,267.24a68,68,0,0,1-1.6-6.63s-1.49,15.81-2.39,22.2c0,0,.9,9.38-1.6,13.17-1.61,2.44-6.84,3.76-11.34,2.64,4.81,2.3,11.64,1,13.51-1.88,2.5-3.79,1.6-13.17,1.6-13.17C258.71,279.7,259.47,272.42,260,267.24Z'
              transform='translate(-46 -21.88)' />
            <path className='b9449cc7-4dfa-4efd-bff3-ce2df80ab236' d='M294.05,202.08l-28.4-20.14-13-16.57-1,.73,11.8,15.08,28.41,20.14s.37,13-4,31-3.82,30.54-3.82,30.54,4.54,20.35.55,34.22-7.39,17.77-7.39,17.77-4.91,6.45-12.06,4.71c8.32,3.85,14.24-3.95,14.24-3.95s3.39-3.89,7.38-17.77-.55-34.21-.55-34.21-.52-12.58,3.82-30.55S294.05,202.08,294.05,202.08Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M242.84,228.66c-3.75-4.49-5.85-18.57-5.85-18.57s5.85,11.83,10,15.27,7.79,10.34,8.54,14.83,5,21.18,5,21.18-1.5,15.81-2.4,22.2c0,0,.9,9.38-1.6,13.17a5.74,5.74,0,0,1-2.45,1.92c0-.18.63-20.92,0-29.72S246.58,233.15,242.84,228.66Z'
              transform='translate(-46 -21.88)' />
            <path className='ac72c0ae-730f-4c9e-8eab-0fe65f770741' d='M328.61,106.05s9.92,19.38,10.91,20.88,19.57,25.16,21.37,38.84c0,0,1.4,5.29-5,10s-38.64,25.16-40.63,25.46-13.68-5.4-14.18-18.17c0,0,25.26-11.78,30.75-17.67,0,0-9.84-16.53-14.65-21.39-1.21-1.22-1.92-3.45-2.3-6.11a33.21,33.21,0,0,1,10.78-29.13Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M254.44,60.64s-15.35,5.69-20.59,3.75-18.12-3.6-26.95-8.54S159.48,41,153.76,40.6c0,0-.1.23.85.63s4.92.74,6,5.24-1.3,9.88-1.95,10.25-1-1.22-4.71-2.92a14.46,14.46,0,0,0-6-1.59L146.57,54l-.84,1.3s1.82,5.44,6.76,5.81,36.69,13.63,38.68,14.23,28.66,12.09,33.65,13.88a57.8,57.8,0,0,1,8.88,4.09S249.28,82.9,249.78,82.9,254.44,60.64,254.44,60.64Z'
              transform='translate(-46 -21.88)' />
            <path className='bc367941-44e9-4a92-8139-c4df947fd122' d='M115.65,34.14c.65.15,8.69,2.84,8.69,2.84s-3.2,1.65-3.5,3.1.65,9.58,1,10.08a11.47,11.47,0,0,0,2.45,1.9c.5.3,1.74,3.09,2.89,2.74a2.63,2.63,0,0,0,1.5-1.79s.9-.45,1.17-.63,6,.35,7.91,1.57,6.24,1.88,8,1.33a33.88,33.88,0,0,0,5-2.18,12.33,12.33,0,0,1,3.07.88c1.1.57,4.59,2.92,5.12,3.12s7.69-13.85-5.12-16.5c0,0-5.31-.47-7.61-1.57s-8.78-6.09-11.18-6.19a15.76,15.76,0,0,0-4.84.7s-14.48-4.14-15.68-2.85S115,34,115.65,34.14Zm10.48,9.63c1.7,0,2.92-1.75,2.92-1.75a2,2,0,0,0,2.23-.17c0,.26,2,.64,2,.64s.9.74,2,1.57-.37,3.25-.37,3.25a7.22,7.22,0,0,1-3.43-1,21.32,21.32,0,0,0-2.75-.73A16.79,16.79,0,0,1,126.13,43.77Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M228.31,186.73c1.35-4.64,24.36-21.36,24.36-21.36l6.45,8.23,5.53,21.52L294,204.26h0s-1.56,14.7-25.5,13S241.79,197,241.79,197s-2.66,6.1-14.46,7.69C227.06,194.87,227.43,189.77,228.31,186.73Z'
              transform='translate(-46 -21.88)' />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M299.49,72.72l4.05,10.38s19.48,16.86,25.07,23c0,0-9.63,18.46-11.42,37.93,0,0-8.91,7.26-11.61,10.6s-9.51,18.82-11.68,23.91,3.89,20.44,3.89,20.44-8.28,15.3-21.61,14.8-22.41-8.63-24.11-23.11-1.2-16.57-1.2-16.57-6,17.77-15.07,17.77-23.51-15.42-23.51-15.42-9.73-8.84-8.84-12.83,22.92-38.54,24.11-44.23,6.14-26,6.14-26l20.74-32.65,12.65.6Z'
              transform='translate(-46 -21.88)' />
            <path className='a01eb628-dad6-428a-b1a2-46bf766f6d30' d='M275.63,210.08c-12.75-.48-21.54-8-23.77-21.34.07.62.13,1.22.21,1.88,1.7,14.48,10.78,22.62,24.11,23.11s21.62-14.8,21.62-14.8-.45-1.12-1-2.83C294.83,199.24,287.11,210.51,275.63,210.08Z'
              transform='translate(-46 -21.88)' />
            <path className='f2bf49b8-86a1-449b-9637-f8f16c67f84b' d='M299.49,72.72a35.94,35.94,0,0,1-2.34,12.33c-2.45,6.14-10.44,40.73-28.56,52.56l-7,4.42V120s.13-13.56,3.22-22.14,3-19.07,3-19.07,5.07-1.4,5.08-1.3S299.49,72.72,299.49,72.72Z'
              transform='translate(-46 -21.88)' />
            <path className='bc367941-44e9-4a92-8139-c4df947fd122' d='M277.17,88.79S274,90.11,272.56,88s-2.81-6.33-2.43-8.27c0,0-8.57.42-10.27-4.07s.15-5.67.42-7.07.53-5.06,1.68-7.73l.12-1.15s-1.75-1.5-.95-3.07,5.48-6.47,6-7.29-.12-6.89,1.7-9.91a75.08,75.08,0,0,1,5.34-7.21s18.27-14.25,32.65,0-7.29,40.48-7.29,40.48-1.73,8.48-5.93,11.84S277.17,88.79,277.17,88.79Z'
              transform='translate(-46 -21.88)' />
            <path className='a01c859d-86e8-464c-bab0-1889e5a6d6ef' d='M290.53,54.09a4.51,4.51,0,0,1,.22-1.55,7.56,7.56,0,0,0-2.94-9c-5.49-4-15.17-7.59-14.87-11.58s15.57-13.88,26.85-8.58S321.65,42.07,314,56.25s-14.48,16.47-14.48,16.47-2.29-.47-.23-2.83,5.8-8.31,2.69-10.18-8.5,2.17-9.21,3.07A11.54,11.54,0,0,0,292,64a1.52,1.52,0,0,1-1.95.61l-.27-.12a1.51,1.51,0,0,1-.78-1.89l1.35-3.67a4.77,4.77,0,0,0,.29-1.71Z'
              transform='translate(-46 -21.88)' />
            <path className='ad62af4d-d56a-413d-96a5-5b5e1e0c67df' d='M277.63,92.79a21.56,21.56,0,0,1,6.46,8.43s-4.34-4.49-6.81-5.39c0,0-4.79,2.25-4.79,3.75a99.49,99.49,0,0,1-1.57,15.31c-1.13,4.83-1,21.8-1,21.8l-3,2-1.44-7.44.55-15.87,2.82-14.46s.52-5.46.75-5.76S277.63,92.79,277.63,92.79Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M277.63,92.79s-4.64-4.94-7-5S267,88.91,267,89.2s-2.25,8-2.25,8a96.79,96.79,0,0,0-3.93,29.21c.45,15.73.82,15.6.82,15.6l5.76-3.65A72.27,72.27,0,0,1,269,114.1c3.14-13,.37-15,1.87-16.32S278.35,94.39,277.63,92.79Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M305.58,154.58c-2.69,3.35-9.36,18.8-11.54,23.89a8,8,0,0,0-.6,3.19l-.89.58-2.91-2.85s2.61-9.43,6.81-21.11S317.19,144,317.19,144,308.28,151.24,305.58,154.58Z'
              transform='translate(-46 -21.88)' />
            <path className='bc367941-44e9-4a92-8139-c4df947fd122' d='M301.09,183.44s-4.74-6.89-6.24-7.79c0,0,.35,1.95-2.35,3.14s-16.79,6.67-17.28,7.3,0,1.43.94,1.61a19.33,19.33,0,0,1-4.12,1c-1.2,0-2.8,1.49-2,2.79s1.47,1.2,1.47,1.2-.35,1.45.53,1.77a13.11,13.11,0,0,0,3,.38,1.73,1.73,0,0,0,.75,2c1.17.57,6.89,1.2,6.89,1.2s4.46-.48,5.74-.5,7.08,1.39,9.55,1.05a74.18,74.18,0,0,0,11.46-3.74C310.22,194.37,301.09,183.44,301.09,183.44Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M304.46,86.47l-.6,9.29-5,1.57,3.37,2.77s-13.6,30.92-31,40.71c0,0,16.57-5.89,32.25-40.43,0,0-1.65-1.95-2.15-2.5l3.49-1.55Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M260.06,85.5s-6.16,31.55-.91,55.31c0,0-6.31-11.43-.61-55.31Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M121.17,39.4A11.1,11.1,0,0,1,124.34,37s6.56,2.67,7.68,3.2C132,40.18,125.29,39.47,121.17,39.4Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M158.88,57.1c-.27-.1-1.32-.77-2.42-1.47h0c4.35-5.87-.44-14.34-.45-14.37C165.66,45,159.37,57.28,158.88,57.1Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M309.4,194.85a79.61,79.61,0,0,1-8.63,3s-2.18-8.53-1.68-11.38,1.68-3.53,1.68-3.53l.32.46S310.22,194.37,309.4,194.85Z'
              transform='translate(-46 -21.88)' />
            <path className='ac72c0ae-730f-4c9e-8eab-0fe65f770741' d='M301.09,183s-1.46,4.93,1.87,8.74,3.71,4.31,3.71,4.31h3.82s2-6,1.68-6.18S301.09,183,301.09,183Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M265.45,94.72h0s-3.67,21.63-2.85,29.57a137.82,137.82,0,0,1,.53,16.52l-1.16,1-.34.21s-.37.13-.82-15.6a96.79,96.79,0,0,1,3.93-29.21S265.05,96.11,265.45,94.72Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M264.25,319.11a18.54,18.54,0,0,1-3.29-2.4s5.89-19.07,6.59-34.24c0,0-5.2-11.15-7-21.1.5,2,6.54,12.66,9.27,23.59S264.25,319.11,264.25,319.11Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M291.05,69.23a.2.2,0,0,1,0,0Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M291.05,69.23c-.11.54-1.68,8.24-4.79,11.85s-5.7,7.36-5.7,7.36c-2,.26-3.39.35-3.39.35S274,90.11,272.56,88s-2.81-6.33-2.43-8.27c0,0,11.57-1.2,16-4.27C290.22,72.62,291,69.64,291.05,69.23Z'
              transform='translate(-46 -21.88)' />
            <path className='a7fdc01e-20be-45cf-a2c5-07a2f97f5c9a' d='M295.22,175.72s-.62,1.31-.73,1.42-.27-1-.27-1,.52-1.07.54-1.07S295.22,175.72,295.22,175.72Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M261.63,142s-8.07,25.78-10.76,32l.16,6.2S261.31,148.24,261.63,142Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M253.83,151.22a2.65,2.65,0,1,0,3.36-1.95A2.76,2.76,0,0,0,253.83,151.22Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M249.74,162.34a2.65,2.65,0,1,0,3.36-2A2.74,2.74,0,0,0,249.74,162.34Z'
              transform='translate(-46 -21.88)' />
            <path className='e55ceeaf-2a01-45c8-ab0a-5f5ad695fbf2' d='M290.3,58.89a4.77,4.77,0,0,0,.29-1.71l-.06-3.09a4.51,4.51,0,0,1,.22-1.55,8.32,8.32,0,0,0,.42-1.92s12.57,9.15,20.53,9.52c-6.7,10.72-12.21,12.58-12.21,12.58s-2.29-.47-.23-2.83,5.8-8.31,2.69-10.18-8.5,2.17-9.21,3.07A11.54,11.54,0,0,0,292,64a1.52,1.52,0,0,1-1.95.61l-.27-.12a1.51,1.51,0,0,1-.78-1.89Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M314.08,396a64.58,64.58,0,0,1-11.16,1.62c-5.5.21-20.43,3.21-20.06,8.78S291,420,300.81,421.22s22.37-1.62,28.77-1.45,10.76-6.34,8.25-12.58S314.08,396,314.08,396Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M347,381s2.09,3.37,1.07,5.44-3.76,7.21-12.7,2.23-26.09-12-26.09-12-9.84-5.24-9.31-11.42,9.6-5.23,9.6-5.23Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M289,293.31s3.8,40.16,11.87,55.38c8.2,15.47,10.47,43.8,12.18,49.21s7,13.26,15.4,14.92,9.76-4,9.34-7.46-2.73-8.93-2.76-12.63.82-5.42.95-7.11c0,0,9.59,2.82,11.05-4.61a20.2,20.2,0,0,1-.9-3.27c-.13-1.1.82-28,1.05-31.25S345.67,325.67,343,317s-1.76-26.85-1.76-26.85l-27.31-3.81Z'
              transform='translate(-46 -21.88)' />
            <path className='a9034a32-0641-4c0b-b9b0-d4fff406d3e6' d='M343,317c-2.64-8.58-1.76-26.85-1.76-26.85l-2.38-.33c-.11,2.53-.68,18.56,1.74,26.45,2.65,8.65,4.38,26.21,4.15,29.46s-1.22,30.12-1.1,31.22a21.34,21.34,0,0,0,.9,3.27c-1.46,7.42-11.05,4.6-11.05,4.6-.13,1.69-1,3.42-1,7.1s2.34,9.22,2.75,12.62-1,9.12-9.35,7.46c-.26-.05-.49-.16-.75-.22a16.83,16.83,0,0,0,3.27,1c8.39,1.66,9.76-4,9.34-7.46s-2.73-8.93-2.76-12.63.83-5.75,1-7.45c0,0,9.58,3.16,11-4.27a20.2,20.2,0,0,1-.9-3.27c-.13-1.1.82-28,1.05-31.25S345.67,325.67,343,317Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M333.15,293.07a30.9,30.9,0,0,1-2.72,11.54s.05,21.35-2.14,32.16c0,0,3.53,21.7,5.76,28.54S336,385.62,336,385.62a9.53,9.53,0,0,0,2.66.46c1.56.06,1.67-26.53-.73-31.22s-4.2-25.39-3.3-31.71.59-24.23,1.78-27.4a4,4,0,0,0-1.49-4.84Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M310.17,148.2s-7.93,5.74-7.53,15.65l5.89-1.27S306.78,151.61,310.17,148.2Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M313.25,301.82c18.11.55,27.87-7.46,27.87-7.46,0-2.54.12-4.2.12-4.2l-27.31-3.81-24.92,7s.75,7.92,2.29,18.06c4.44.48,8.8-10.43,8.8-10.43l12.42.79S295.16,301.27,313.25,301.82Z'
              transform='translate(-46 -21.88)' />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M292.5,293.91S331.86,310,350.88,279.5a5.34,5.34,0,0,0,.76-3l-.24-7.1a7.49,7.49,0,0,1,.38-2.63h0a11.24,11.24,0,0,0,.39-5.67c-1.47-7.94-5.46-29.52-6.4-35.51-1.14-7.3,2.73-36.53,9.26-49.19,6.44-12.48-10.09-40.54-10.09-40.54a10.71,10.71,0,0,0-12-4.3s7.57,5.29,3.43,10.88-16.61,10.12-16.61,10.12-23,11.94-30.88,26.46a60.77,60.77,0,0,0-2.95,17.11c-.24,9.66.83,32.84.43,36.07s-1.63,25.32-1.5,26.35S292.5,293.91,292.5,293.91Z'
              transform='translate(-46 -21.88)' />
            <path className='b66adb21-1294-414f-a5e9-850436fe655b' d='M336.65,135.61a5.67,5.67,0,0,0-.14-4.46,11.54,11.54,0,0,0-3.56.41A14.1,14.1,0,0,1,336.65,135.61Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M304.43,297.19s23.18-62.58,22.07-85.3c-1.07-22.15-33.83,44.87-33.83,44.87l-.17,37.15S299.93,296.65,304.43,297.19Z'
              transform='translate(-46 -21.88)' />
            <path className='fd1e55c4-83fc-4340-9ce5-ca5fa2a9ed16' d='M351.13,260.64a7.43,7.43,0,0,0-.38,2.62l.24,7.05a5.26,5.26,0,0,1-.75,3c-19,30.26-58.19,14.31-58.19,14.31S286.66,263,285,255c-.09,1.95-.13,3.31-.1,3.54.13,1,7.63,35.36,7.63,35.36S331.86,310,350.88,279.5a5.34,5.34,0,0,0,.76-3l-.24-7.1a7.49,7.49,0,0,1,.38-2.63,11.35,11.35,0,0,0,.39-5.67c-.15-.83-.34-1.83-.54-2.93A10.83,10.83,0,0,1,351.13,260.64Z'
              transform='translate(-46 -21.88)' />
            <path className='adf9201c-7941-475a-9c99-509bd3dcfcb5' d='M354.41,170.87c-6.51,12.57-10.38,41.62-9.25,48.87.11.67.25,1.55.43,2.57,0-10.48,3.65-34.68,9.44-45.91,1.81-3.5,1.76-8.26.82-13.27A16.06,16.06,0,0,1,354.41,170.87Z'
              transform='translate(-46 -21.88)' />
            <path className='ac72c0ae-730f-4c9e-8eab-0fe65f770741' d='M291.93,223.26a101.94,101.94,0,0,0,1.38-15.35c.1-8.9,2.61-23.67,18.71-21.1,22.77,3.62,13,35.51,10.53,41.08s-12.47,30.87-13,32.3-16.24,32.88-21.86,40.45a1.38,1.38,0,0,1-2.05.25c-2.28-1.94-7.4-6.56-8.07-9.49C276.72,287.64,280,274,280,274s8.87-34.09,9.62-35.68C290.18,237.13,290.57,231.53,291.93,223.26Z'
              transform='translate(-46 -21.88)' />
            <path className='bc367941-44e9-4a92-8139-c4df947fd122' d='M310.56,148.92s-1.81-4.11-6-4.54-6.54-1.58-7-3.13-.44-3.41-1.45-5.71a42,42,0,0,1-1.85-8.08c0-.14-3.16-1.09-3.31-2.49s1.49-6.37,1.31-6.76-1.89-3.44-1.66-5.81a29.16,29.16,0,0,1,1.81-8.72c1.26-2.9,10.11-20.55,33.13-11.46,23.28,9.2,5.57,28.72,7.87,41.38,0,0,2.07,2.67-1.72,7.2S316.91,146.34,310.56,148.92Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M331.74,140.8c3.79-4.53,1.72-7.2,1.72-7.2a17.54,17.54,0,0,1,.05-5.64l-9.11,1.74s-13.45-12-12.5-5.56-2.3,7.57-2.3,7.57l2.76,3.52c.71.9.29,2-.29,3-3.84,6.6-13.16,4.62-13.19,4.62a13.19,13.19,0,0,0,5.69,1.58c4.18.43,6,4.54,6,4.54C316.91,146.34,327.93,145.36,331.74,140.8Z'
              transform='translate(-46 -21.88)' />
            <path className='a01c859d-86e8-464c-bab0-1889e5a6d6ef' d='M291.46,97s-1.61,4.72,1,6.71,5,3.87,5,3.87v6a4.51,4.51,0,0,0,.71,2.43l3.29,5.06a6.21,6.21,0,0,1,1,3.3l0,2.45a1.39,1.39,0,0,0,1.23,1.4l.86.1A1.39,1.39,0,0,0,306,127l.36-3.4s1.72-2.73,5.5.52l3.4,1.4a3.66,3.66,0,0,1,2.05,2.15c.95,2.61,3,7.79,4.51,8.64,2.06,1.17,10-3,11.09-4.77s3.75-14,5.41-19.75.68-20-16.47-23.6S293.82,91.83,291.46,97Z'
              transform='translate(-46 -21.88)' />
            <path className='e55ceeaf-2a01-45c8-ab0a-5f5ad695fbf2' d='M333,131.56c1.06-1.82,3.75-14,5.41-19.75a41.9,41.9,0,0,1-15.24,5.73c-8.62,1.32-25.69-8.39-25.69-8.39v4.39a4.51,4.51,0,0,0,.71,2.43l3.29,5.06a6.21,6.21,0,0,1,1,3.3l0,2.45a1.39,1.39,0,0,0,1.23,1.4l.86.1A1.39,1.39,0,0,0,306,127l.36-3.4s1.72-2.73,5.5.52l4.92,2s3,9,5,10.17S331.89,133.38,333,131.56Z'
              transform='translate(-46 -21.88)' />
            <path className='f2bf49b8-86a1-449b-9637-f8f16c67f84b' d='M333.29,132.13s6.67,4.11,3.34,9.94-27.52,16.28-28.71,17.82c0,0-1-8.74,2.25-11.69,0,0,18.51-3.6,21.38-8.33S333.29,132.13,333.29,132.13Z'
              transform='translate(-46 -21.88)' />
            <path className='ad62af4d-d56a-413d-96a5-5b5e1e0c67df' d='M333.29,132.13s1.12,3-1.74,7.74c-2.17,3.57-13.29,6.51-18.58,7.73h0s2,1.11,5.25,6.71c7.44-3.86,16.46-8.82,18.41-12.24C340,136.24,333.29,132.13,333.29,132.13Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M337.8,150.78s-10.77,6.17-16,9-11,6.51-11,6.51l-2-2-2.41,5.31s-8.27,5-12.14,9.11A42.28,42.28,0,0,0,287,189.49s10.33-13.55,18.46-17.57l2.19-1.1,1.24-4.21,2,2.11S334.52,154.45,337.8,150.78Z'
              transform='translate(-46 -21.88)' />
            <path className='bb30dc39-14c3-4b60-bc95-2fd62d179bfc' d='M310,333.49h0a5.27,5.27,0,0,0-1.44-2.32L264.29,294c-1.17-1-2.3-.23-2.53,1.67L256.58,339a7,7,0,0,0,1.73,5.49l37.21,32.17-1.29,12.52L305.35,384v0a2.68,2.68,0,0,0,1-2.11l4.14-45.26A7.84,7.84,0,0,0,310,333.49Z'
              transform='translate(-46 -21.88)' />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M252.22,298.25l10.47-4.53.34-.14a1.45,1.45,0,0,1,1.26.4l44.29,37.19a5.37,5.37,0,0,1,1.45,2.32l-1.59.76h0l-9,4.36-38.82-33.2h0Z'
              transform='translate(-46 -21.88)' />
            <path className='b16238c7-6a5a-4d5c-bff4-18e61d1b31fd' d='M247.41,349.29l45.3,39.46c1.24,1.09,2.41.26,2.62-1.84l4.4-45.48a7,7,0,0,0-1.85-5.47l-44.19-37.38c-1.17-1-2.3-.23-2.54,1.68l-5.44,43.51A7,7,0,0,0,247.41,349.29Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M301,384.25l4.27-45.36a7,7,0,0,0-1.86-5.46l-44.24-37.28a1.61,1.61,0,0,0-1.05-.43,1.38,1.38,0,0,1,1.42.35l44.25,37.28a7.07,7.07,0,0,1,1.86,5.45l-4.26,45.36c-.13,1.44-.7,2.34-1.46,2.37C300.45,386.27,300.84,385.44,301,384.25Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M284.73,317.46l2.32-1.15a2.11,2.11,0,0,1,1.44-.26l.19,0c.23.05.35.3.31.65l0,.42-6.52,3.26,0-.42a2,2,0,0,1,.5-1.06l.21-.24A5.52,5.52,0,0,1,284.73,317.46Z'
              transform='translate(-46 -21.88)' />
            <polygon className='bfd2a03f-3904-407c-b650-c5d674d04673' points='239.56 301.18 236.43 298.54 242.9 294.35 245.98 296.93 239.56 301.18'
            />
            <path className='af335e7a-0716-4e63-bb0a-b215686eb815' d='M287.86,320.1l2.32-1.16a2.23,2.23,0,0,1,1.44-.26l.19,0c.23,0,.35.3.32.66l-.05.42-6.52,3.27,0-.43a2,2,0,0,1,.5-1.06l.21-.24A5.38,5.38,0,0,1,287.86,320.1Z'
              transform='translate(-46 -21.88)' />
            <polygon className='b1e31983-3c7f-4981-afa7-01816711c46f' points='243.25 299.33 243.45 297.42 243.25 297.52 243.05 299.43 243.25 299.33'
            />
            <polygon className='b1e31983-3c7f-4981-afa7-01816711c46f' points='243.45 297.42 240.23 294.83 240.06 294.92 243.25 297.52 243.45 297.42'
            />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M270.66,305.63l2.31-1.14a2.24,2.24,0,0,1,1.44-.26l.18,0c.23.05.35.3.31.65l0,.41-6.5,3.22,0-.42a2,2,0,0,1,.5-1l.21-.24A5.6,5.6,0,0,1,270.66,305.63Z'
              transform='translate(-46 -21.88)' />
            <polygon className='bfd2a03f-3904-407c-b650-c5d674d04673' points='225.44 289.28 222.35 286.67 228.81 282.53 231.85 285.08 225.44 289.28'
            />
            <path className='f27a8367-88e9-4cc9-9e05-6bf5ef157c95' d='M273.75,308.23l2.31-1.14a2.24,2.24,0,0,1,1.44-.26l.19,0c.22.05.34.3.3.65l0,.42-6.51,3.22.05-.42a1.94,1.94,0,0,1,.5-1l.21-.24A5.34,5.34,0,0,1,273.75,308.23Z'
              transform='translate(-46 -21.88)' />
            <polygon className='b1e31983-3c7f-4981-afa7-01816711c46f' points='229.12 287.45 229.33 285.56 229.14 285.66 228.93 287.55 229.12 287.45'
            />
            <polygon className='b1e31983-3c7f-4981-afa7-01816711c46f' points='229.33 285.57 226.16 283.01 225.99 283.09 229.14 285.66 229.33 285.57'
            />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M290.69,310.24l-.83,8.07-2.1-1.76.82-7.84a4.2,4.2,0,0,0-1.25-3.3l-9-7.54c-.8-.67-1.56-.3-1.68.83l-.87,7.76-2.07-1.74.9-8c.28-2.49,1.94-3.31,3.72-1.83l9.61,8A9.27,9.27,0,0,1,290.69,310.24Z'
              transform='translate(-46 -21.88)' />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M275.15,297.32l3.72-.37,10.74,10,.47,6.19-.22,5.16-2.4,1.2-.08-6.44-.13-4.4L285,305.49s-4-3.25-4.06-3.35S275.15,297.32,275.15,297.32Z'
              transform='translate(-46 -21.88)' />
            <polygon className='b016f5fb-7b15-4680-9333-2e75751141ab' points='227.36 285.76 229.76 284.58 230.4 278.86 228.11 279.04 227.43 279.16 227.36 285.76'
            />
            <path className='b016f5fb-7b15-4680-9333-2e75751141ab' d='M273.45,295.56,276,294.3s-.68,1.4-.69,1.39-.85.3-.87.3Z'
              transform='translate(-46 -21.88)' />
            <path className='bfd2a03f-3904-407c-b650-c5d674d04673' d='M288.3,311.43l-.84,8.08-2.1-1.76.83-7.85a4.22,4.22,0,0,0-1.25-3.31l-9-7.55c-.81-.67-1.56-.3-1.69.84l-.88,7.77-2.06-1.75.9-8c.29-2.5,1.95-3.32,3.72-1.84l9.61,8A9.31,9.31,0,0,1,288.3,311.43Z'
              transform='translate(-46 -21.88)' />
            <path className='bc367941-44e9-4a92-8139-c4df947fd122' d='M278.22,291.07s-1.53,7.9-1.1,9.88,4.21,5.81,6.49,6.51,1.35-2,1.86-3.72a24.62,24.62,0,0,0,.42-5.41Z'
              transform='translate(-46 -21.88)' />
            <path className='be7e76a6-6eb1-495f-9c50-ab75b2f6433d' d='M285.89,298.33l-7.67-7.26s-.31,1.62-.62,3.58c0,0,2.86,5.87,8.06,8.19A31.5,31.5,0,0,0,285.89,298.33Z'
              transform='translate(-46 -21.88)' />
            <path className='ac72c0ae-730f-4c9e-8eab-0fe65f770741' d='M277.65,291.66s2.72,8.51,9.53,9.31l-2.31-9.31s-5.76-1.62-5.93-1.65S277.65,291.66,277.65,291.66Z'
              transform='translate(-46 -21.88)' />
            <path className='aa9dec07-24d9-4ba2-ac87-2fac83f6be24' d='M322,191.82c6.32,19.23-9.5,49.13-11.78,57.86s-26.35,49.63-26.35,49.64l0,0-.23.4a13.73,13.73,0,0,0,1.57,1.07,4.32,4.32,0,0,0,1.08.4h0a1.36,1.36,0,0,0,1.39-.57c5.62-7.57,21.36-39.1,21.85-40.45s10.49-26.69,13-32.3C324.55,223.42,331.23,201.91,322,191.82Z'
              transform='translate(-46 -21.88)' />
            <path className='adddccea-2a64-4776-922b-e6a2f1b2b54c' d='M123.64,364.43a6.58,6.58,0,0,1-3.24-.76L47.46,321.56a2.59,2.59,0,0,1-1.46-2.2,3.33,3.33,0,0,1,2-2.71l72-41.55a8.07,8.07,0,0,1,7.36-.28l73,42.11a2.59,2.59,0,0,1,1.46,2.2,3.35,3.35,0,0,1-2,2.71l-72,41.55A8.47,8.47,0,0,1,123.64,364.43ZM121,362.69a7.07,7.07,0,0,0,6.24-.28l72-41.55c.87-.5,1.39-1.15,1.39-1.73a1.54,1.54,0,0,0-.9-1.22L126.71,275.8a7.07,7.07,0,0,0-6.24.28l-72,41.55a2.34,2.34,0,0,0-1.38,1.73,1.55,1.55,0,0,0,.89,1.22Z'
              transform='translate(-46 -21.88)' />
            <polygon className='ea2cc348-9232-45fc-a8ba-3bb4ea5dbef0' points='155.11 266.54 155.11 275.5 77.65 320.22 0.19 275.49 0.19 266.54 155.11 266.54'
            />
            <polygon className='a2303ac9-b4c3-48cd-8e64-3362f99dc4e5' points='77.65 311.27 0.19 266.54 77.65 221.82 155.11 266.54 77.65 311.27'
            />
            <polygon className='f0d933b6-e8c7-423a-88bb-bfe9519257a3' points='77.65 310.22 1.09 266.02 0.19 266.54 77.65 311.27 155.11 266.54 154.21 266.02 77.65 310.22'
            />
            <polygon className='bb38733f-aead-4982-8ca2-142ce5704442' points='77.65 300.95 18.07 266.55 77.65 232.16 137.23 266.55 77.65 300.95'
            />
            <polygon className='e4264f7a-1259-4c4d-b780-4aaec9905075' points='77.65 300.95 18.07 266.55 77.65 232.16 137.23 266.55 77.65 300.95'
            />
            <polygon className='a9a33a15-6e4c-423f-96f1-be646f70d9aa' points='77.65 223.48 137.23 257.88 137.23 266.55 77.65 232.16 77.65 223.48'
            />
            <polygon className='e42f7a4a-0b30-44c6-a745-de03c2b2bdf5' points='77.65 232.16 18.07 266.55 18.07 257.88 77.65 223.48 77.65 232.16'
            />
            <polygon className='b4c9518c-0edf-4a6c-8015-31f201db7a08' points='77.65 292.28 18.07 257.88 77.65 223.48 137.23 257.88 77.65 292.28'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='77.65 271.62 53.84 257.88 77.65 244.13 101.46 257.88 77.65 271.62'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='77.65 245.4 100.36 258.51 101.46 257.88 77.65 244.13 53.84 257.88 54.94 258.51 77.65 245.4'
            />
            <polygon className='b9e3a706-7ced-459d-8a1e-9ccf4b245030' points='77.65 292.28 77.65 300.95 137.23 266.55 137.23 257.88 77.65 292.28'
            />
            <polygon className='bc82723b-1e98-454c-b4a0-aaff82562010' points='18.07 257.88 18.07 266.55 77.65 300.95 77.65 292.28 18.07 257.88'
            />
            <polygon className='eb1d3d31-598f-4869-8cdc-e104ee4af6cb' points='77.65 291.33 18.89 257.41 18.07 257.88 77.65 292.28 137.23 257.88 136.41 257.41 77.65 291.33'
            />
            <path className='adddccea-2a64-4776-922b-e6a2f1b2b54c' d='M123.64,525.11a6.58,6.58,0,0,1-3.24-.76L47.46,482.24A2.57,2.57,0,0,1,46,480a3.33,3.33,0,0,1,2-2.7l72-41.55a8,8,0,0,1,7.36-.28l73,42.11a2.56,2.56,0,0,1,1.46,2.19,3.33,3.33,0,0,1-2,2.71l-72,41.55A8.47,8.47,0,0,1,123.64,525.11ZM121,523.37a7.07,7.07,0,0,0,6.24-.28l72-41.55c.87-.5,1.39-1.14,1.39-1.73a1.51,1.51,0,0,0-.9-1.21l-72.94-42.12a7.14,7.14,0,0,0-6.24.28l-72,41.55A2.34,2.34,0,0,0,47.13,480a1.53,1.53,0,0,0,.89,1.22Z'
              transform='translate(-46 -21.88)' />
            <polygon className='b32341d4-d243-48b4-8119-37605df79f87' points='155.11 427.23 155.11 436.18 77.65 480.9 0.19 436.18 0.19 427.23 155.11 427.23'
            />
            <polygon className='a2303ac9-b4c3-48cd-8e64-3362f99dc4e5' points='77.65 471.95 0.19 427.23 77.65 382.51 155.11 427.23 77.65 471.95'
            />
            <polygon className='f0d933b6-e8c7-423a-88bb-bfe9519257a3' points='77.65 470.9 1.09 426.7 0.19 427.23 77.65 471.95 155.11 427.23 154.21 426.71 77.65 470.9'
            />
            <polygon className='bb38733f-aead-4982-8ca2-142ce5704442' points='77.65 461.63 18.07 427.24 77.65 392.84 137.23 427.24 77.65 461.63'
            />
            <polygon className='e1cd92f6-379b-492f-84bf-269408abdc23' points='77.65 461.64 18.07 427.24 77.65 392.84 137.23 427.24 77.65 461.64'
            />
            <polygon className='ff7a6e6c-e353-4b96-80fb-c2c773e1238c' points='77.65 384.17 137.23 418.56 137.23 427.24 77.65 392.84 77.65 384.17'
            />
            <polygon className='ace075ed-ced3-4a0b-9763-c7f9e516c60d' points='77.65 392.84 18.07 427.24 18.07 418.56 77.65 384.17 77.65 392.84'
            />
            <polygon className='f5fdf758-c09f-4544-a1ee-ddea3b6f3c49' points='77.65 452.96 18.07 418.56 77.65 384.17 137.23 418.56 77.65 452.96'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='77.65 432.31 53.84 418.56 77.65 404.82 101.46 418.56 77.65 432.31'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='77.65 406.08 100.36 419.2 101.46 418.56 77.65 404.82 53.84 418.56 54.94 419.2 77.65 406.08'
            />
            <polygon className='b29af0a4-4efb-4f3a-9931-ce3fcc06df28' points='77.65 452.96 77.65 461.64 137.23 427.24 137.23 418.56 77.65 452.96'
            />
            <polygon className='a9cf4a72-5a42-4aeb-a815-6e2fcfde3d3b' points='18.07 418.56 18.07 427.24 77.65 461.64 77.65 452.96 18.07 418.56'
            />
            <polygon className='eb1d3d31-598f-4869-8cdc-e104ee4af6cb' points='77.65 452.02 18.89 418.09 18.07 418.56 77.65 452.96 137.23 418.56 136.41 418.09 77.65 452.02'
            />
            <path className='adddccea-2a64-4776-922b-e6a2f1b2b54c' d='M434.14,364.43a6.59,6.59,0,0,1-3.25-.76L358,321.56a2.59,2.59,0,0,1-1.46-2.2,3.33,3.33,0,0,1,1.95-2.71l72-41.55a8.09,8.09,0,0,1,7.37-.28l72.94,42.11a2.59,2.59,0,0,1,1.46,2.2,3.35,3.35,0,0,1-1.95,2.71l-72,41.55A8.43,8.43,0,0,1,434.14,364.43Zm-2.68-1.74a7,7,0,0,0,6.23-.28l72-41.55a2.34,2.34,0,0,0,1.38-1.73,1.54,1.54,0,0,0-.9-1.22L437.2,275.8a7,7,0,0,0-6.23.28l-72,41.55a2.34,2.34,0,0,0-1.38,1.73,1.54,1.54,0,0,0,.9,1.22Z'
              transform='translate(-46 -21.88)' />
            <polygon className='b7f95767-835f-4e1a-93e8-349cbf252ef7' points='465.6 266.54 465.6 275.5 388.14 320.22 310.68 275.49 310.68 266.54 465.6 266.54'
            />
            <polygon className='a2303ac9-b4c3-48cd-8e64-3362f99dc4e5' points='388.14 311.27 310.68 266.54 388.14 221.82 465.6 266.54 388.14 311.27'
            />
            <polygon className='f0d933b6-e8c7-423a-88bb-bfe9519257a3' points='388.14 310.22 311.59 266.02 310.68 266.54 388.14 311.27 465.6 266.54 464.7 266.02 388.14 310.22'
            />
            <polygon className='bb38733f-aead-4982-8ca2-142ce5704442' points='388.14 300.95 328.56 266.55 388.14 232.16 447.72 266.55 388.14 300.95'
            />
            <polygon className='ea0c2ee4-6aa4-4e13-8b80-f4538d3ace7a' points='388.14 300.95 328.56 266.55 388.14 232.16 447.72 266.55 388.14 300.95'
            />
            <polygon className='b4f23d29-424a-45d3-a34c-c62588727e0f' points='388.14 223.48 447.72 257.88 447.72 266.55 388.14 232.16 388.14 223.48'
            />
            <polygon className='ab1497ac-f6fc-4432-8a90-f8d34bc4a967' points='388.14 232.16 328.56 266.55 328.56 257.88 388.14 223.48 388.14 232.16'
            />
            <polygon className='e8f40d53-8ec2-43e2-ba06-6c29a9ef5611' points='388.14 292.28 328.56 257.88 388.14 223.48 447.72 257.88 388.14 292.28'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='388.14 271.62 364.33 257.88 388.14 244.13 411.95 257.88 388.14 271.62'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='388.14 245.4 410.85 258.51 411.95 257.88 388.14 244.13 364.33 257.88 365.43 258.51 388.14 245.4'
            />
            <polygon className='af697b83-0cad-487e-94a7-17401556a308' points='388.14 292.28 388.14 300.95 447.72 266.55 447.72 257.88 388.14 292.28'
            />
            <polygon className='fb5ebd06-8ade-4f9d-89c2-3744779211da' points='328.56 257.88 328.56 266.55 388.14 300.95 388.14 292.28 328.56 257.88'
            />
            <polygon className='eb1d3d31-598f-4869-8cdc-e104ee4af6cb' points='388.14 291.33 329.38 257.41 328.56 257.88 388.14 292.28 447.72 257.88 446.9 257.41 388.14 291.33'
            />
            <path className='adddccea-2a64-4776-922b-e6a2f1b2b54c' d='M434.14,525.11a6.59,6.59,0,0,1-3.25-.76L358,482.24a2.57,2.57,0,0,1-1.46-2.2,3.33,3.33,0,0,1,1.95-2.7l72-41.55a8.06,8.06,0,0,1,7.37-.28l72.94,42.11a2.56,2.56,0,0,1,1.46,2.19,3.33,3.33,0,0,1-1.95,2.71l-72,41.55A8.43,8.43,0,0,1,434.14,525.11Zm-2.68-1.74a7,7,0,0,0,6.23-.28l72-41.55c.86-.5,1.38-1.14,1.38-1.73a1.51,1.51,0,0,0-.9-1.21L437.2,436.48a7.11,7.11,0,0,0-6.23.28l-72,41.55a2.34,2.34,0,0,0-1.38,1.73,1.53,1.53,0,0,0,.9,1.22Z'
              transform='translate(-46 -21.88)' />
            <polygon className='e2841df3-3efe-4495-9bec-40885f07cb9f' points='465.6 427.23 465.6 436.18 388.14 480.9 310.68 436.18 310.68 427.23 465.6 427.23'
            />
            <polygon className='a2303ac9-b4c3-48cd-8e64-3362f99dc4e5' points='388.14 471.95 310.68 427.23 388.14 382.51 465.6 427.23 388.14 471.95'
            />
            <polygon className='f0d933b6-e8c7-423a-88bb-bfe9519257a3' points='388.14 470.9 311.59 426.7 310.68 427.23 388.14 471.95 465.6 427.23 464.7 426.71 388.14 470.9'
            />
            <polygon className='bb38733f-aead-4982-8ca2-142ce5704442' points='388.14 461.63 328.56 427.24 388.14 392.84 447.72 427.24 388.14 461.63'
            />
            <polygon className='ffc39e05-bd2c-4acc-92b1-ad8ebd7b2202' points='388.14 461.64 328.56 427.24 388.14 392.84 447.72 427.24 388.14 461.64'
            />
            <polygon className='afe512a7-ba9e-48e6-b292-24a062f26c2e' points='388.14 384.17 447.72 418.56 447.72 427.24 388.14 392.84 388.14 384.17'
            />
            <polygon className='e85d66f4-f31c-4537-bedc-7901b1792788' points='388.14 392.84 328.56 427.24 328.56 418.56 388.14 384.17 388.14 392.84'
            />
            <polygon className='f5f0132a-4cff-49db-a773-ec22c1b75c76' points='388.14 452.96 328.56 418.56 388.14 384.17 447.72 418.56 388.14 452.96'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='388.14 432.31 364.33 418.56 388.14 404.82 411.95 418.56 388.14 432.31'
            />
            <polygon className='ece41173-02aa-4547-ab72-117dd87633fe' points='388.14 406.08 410.85 419.2 411.95 418.56 388.14 404.82 364.33 418.56 365.43 419.2 388.14 406.08'
            />
            <polygon className='a8353288-c45f-4296-9e46-91b60ba3e13b' points='388.14 452.96 388.14 461.64 447.72 427.24 447.72 418.56 388.14 452.96'
            />
            <polygon className='ea2993c8-5542-4c02-b201-76a119d98a36' points='328.56 418.56 328.56 427.24 388.14 461.64 388.14 452.96 328.56 418.56'
            />
            <polygon className='eb1d3d31-598f-4869-8cdc-e104ee4af6cb' points='388.14 452.02 329.38 418.09 328.56 418.56 388.14 452.96 447.72 418.56 446.9 418.09 388.14 452.02'
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default ContactFormImage;
