import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import RolesContext from "../../session/RolesContext";
import GoogleTranslateIntegration from "../GoogleTranslateIntegration/GoogleTranslateIntegration";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  button: {
    color: "#fff",
    fontWeight: "bold",
  },
  googleTranslator: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
  },
});
class Tabs extends React.Component {
  state = {
    isClient: false,
  };

  render() {
    const { classes } = this.props;
    let { isAdmin, isUserSuscribed } = this.props;
    const { isClient } = this.context;
    return (
      <React.Fragment>
        {isAdmin && (
          <Button className={classes.button} href="/admin">
            ADMIN
          </Button>
        )}
        {(isAdmin || isClient) && (
          <Button className={classes.button} href="/challenge_create">
            CREAR DESAFÍO
          </Button>
        )}
        {(isAdmin || isClient) && (
          <Button className={classes.button} href="/create_job_offer">
            CREAR OFERTA LABORAL
          </Button>
        )}
        <Button className={classes.button} href="/challenges">
          DESAFÍOS
        </Button>
        <Button className={classes.button} href="/job_offers">
          OFERTAS LABORALES
        </Button>
        {!isAdmin && isUserSuscribed && (
          <Button className={classes.button} href="/challenges_corporative">
            DESAFÍOS CORPORATIVOS
          </Button>
        )}
        <Box className={classes.googleTranslator}>
          <GoogleTranslateIntegration />
        </Box>
      </React.Fragment>
    );
  }
}

Tabs.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
  isUserSuscribed: PropTypes.bool.isRequired,
};

Tabs.contextType = RolesContext;

export default withStyles(styles)(Tabs);
