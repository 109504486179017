import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import { storage, auth, db } from "../firebase/firebase.js";
import { addingUserDesc } from "../firebase/db";
import { doSignOut } from "../firebase/auth.js";
import Tooltip from "@material-ui/core/Tooltip";
import ProgressBar from "../styling/ProgressBar";
import { SaveButton, BigTitle, TitleIH4 } from "../styling/Styles";

const styles = (theme) => ({
  contentContainer: {
    display: "flex",
    marginTop: "3em",
  },
  photoContainer: {
    margin: "0 2em",
  },
  infoContainer: {
    marginRight: "1em",
  },
  descriptionInput: {
    width: "100%",
    fontSize: "1.3em",
    background: theme.palette.grey["50"],
  },
  userImage: {
    width: "100%",
    borderRadius: "6px",
  },
  photoInput: {
    display: "none",
    padding: 0,
  },
  photoIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    order: 3,
    "&:hover": {
      color: theme.palette.grey["500"],
      padding: 0,
    },
  },
});

class OnlyAuthAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth.currentUser.toJSON(),
      uploadingImage: false,
      userDesc: undefined,
      uploadPercentage: null,
    };
  }

  uploadPhoto = (photo) => {
    const storageRef = storage.ref(`pictures/${auth.currentUser.uid}`);
    const task = storageRef.put(photo);
    task.on(
      "state_changed",
      (snapshot) => {
        let percentage = (
          (snapshot.bytesTransferred / snapshot.totalBytes) *
          100
        ).toFixed(2);
        this.setState({ uploadPercentage: percentage });
      },
      (error) => error,
      () => {
        storageRef.getDownloadURL().then((url) => {
          let newState = Object.assign({}, this.state);
          newState.user.photoURL = url;
          newState.uploadingImage = false;
          this.setState(newState);
          auth.currentUser.updateProfile({
            photoURL: url,
          });
        });
      }
    );
  };

  processPhotoUpload = (e) => {
    this.setState({ uploadingImage: true });
    this.uploadPhoto(e.target.files[0]);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    let { userDesc } = this.state;

    // Create a user description in your own accessible Firebase Database too
    addingUserDesc(auth.currentUser.uid, userDesc)
      .then(() => {
        this.setState({ userDesc });
      })
      .catch((error) => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  componentDidMount() {
    const uid = auth.currentUser.uid;
    const ref = db.ref("users/" + uid);
    //this function returns any value of an exact user using its id
    ref.once("value").then((snapshot) => {
      var userDesc = snapshot.child("userDesc").val();
      this.setState({ userDesc });
    });
  }

  render() {
    const { classes } = this.props;
    let { userDesc } = this.state;
    const isInvalid = userDesc === null;

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <div className={classes.contentContainer}>
          <div className={classes.photoContainer}>
            {this.state.user && (
              <div>
                {this.state.uploadingImage ? (
                  <div style={{ height: "300px", width: "200px" }}>
                    <ProgressBar
                      uploadPercentage={this.state.uploadPercentage}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className={classes.userImage}
                      alt="User"
                      src={
                        this.state.user.photoURL || "images/avatarBinnario.png"
                      }
                      onMouseEnter={() => this.setState({ imageHover: true })}
                      onMouseLeave={() => this.setState({ imageHover: false })}
                    />

                    <label htmlFor="flat-button-file">
                      <div
                        style={this.getVisorStyle()}
                        onMouseEnter={() => this.setState({ imageHover: true })}
                        onMouseLeave={() =>
                          this.setState({ imageHover: false })
                        }
                      >
                        <Icon className={classes.photoIcon}> add_a_photo </Icon>
                      </div>
                    </label>
                    <input
                      onChange={this.processPhotoUpload}
                      name="photo"
                      type="file"
                      accept="image/*"
                      className={classes.photoInput}
                      id="flat-button-file"
                      multiple
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={classes.infoContainer}>
            <BigTitle>
              {auth.currentUser && auth.currentUser.displayName}
            </BigTitle>
            <TitleIH4>{auth.currentUser && auth.currentUser.email}</TitleIH4>

            <Tooltip title="Haz click aquí para editar">
              <textarea
                className={classes.descriptionInput}
                rows="5"
                cols="10"
                data-min-rows="10"
                placeholder="Escribe una breve descripción de ti"
                id="userDesc"
                name="userDesc"
                value={userDesc}
                autoComplete="userDesc"
                onChange={this.onChange}
              ></textarea>
            </Tooltip>

            <SaveButton
              primary
              variant="contained"
              hidden={isInvalid}
              onClick={this.onSubmit}
            >
              Guardar
            </SaveButton>

            <Tooltip title="Cerrar Sesión">
              <Icon
                style={{
                  marginTop: "1em",
                  marginRigth: "0.5em",
                  marginLeft: "15em",
                  position: "relative",
                }}
                onClick={doSignOut}
                className={classes.icon}
              >
                {" "}
                exit_to_app{" "}
              </Icon>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    );
  }

  getVisorStyle = () => {
    return {
      position: "relative",
      bottom: "6em",
      left: "0",
      height: "6em",
      backgroundColor: "#FCFCFC",
      opacity: this.state.imageHover ? "0.8" : "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    };
  };
}

OnlyAuthAccount.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object,
};

// Style for the black box that contains the image upload icon

export default withStyles(styles)(OnlyAuthAccount);
