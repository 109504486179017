import React from "react";
import PropTypes from 'prop-types';
import * as ROUTES from '../../config/routes';
import { withRouter } from 'react-router';
import { LinkingNH5NavBar, SignUpButton } from "../../styling/Styles";

class NavBarButtons extends React.Component {

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <SignUpButton primary onClick={() => this.props.history.push(ROUTES.SIGN_UP)}>
              REGÍSTRATE
          </SignUpButton>
          <LinkingNH5NavBar onClick={() => this.props.history.push(ROUTES.SIGN_IN)}>
              INICIAR SESIÓN
          </LinkingNH5NavBar>
      </div>
    )
  }
}

NavBarButtons.propTypes = {
  history: PropTypes.object,
}
export default withRouter(NavBarButtons);
