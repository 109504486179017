import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { registerBusinessContact } from "../../firebase/db";
import { TitleD, TitleH2, TitleH1 } from "../../styling/Styles";
import SuccessDialog from "./SuccessDialog";
import ContactFormImage from "./ContactFormImage";

const styles = (theme) => ({
  normalInput: {
    borderColor: `${theme.palette.primary.light} !important`,
    borderWidth: "0.5px",
    borderRadius: "10px",
    height: "45px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  textArea: {
    marginTop: "10px",
    borderColor: `${theme.palette.primary.light} !important`,
    borderWidth: "0.5px",
    borderRadius: "10px",
    marginBottom: "10px",
  },
  label: {
    color: "#2B3E7F",
  },
  gridMargin: {
    marginTop: "25px",
    marginBottom: "10px",
  },
  divFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "#251A6A",
    color: "#FFFF",
    borderWidth: "0.5px",
    borderRadius: "10px",
    minWidth: "100px",
    textTransform: "none",
  },
  buttonDisabled: {
    backgroundColor: "#d3d3d3",
    color: "#FFFF",
    borderWidth: "0.5px",
    borderRadius: "10px",
    minWidth: "100px",
    textTransform: "none",
  },
  textHelper: {
    margin: "0px 12px 15px",
  },
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactData: {
        nameAndLastname: "",
        organization: "",
        contactPhone: "",
        contactEmail: "",
        message: "",
      },
      isFormValid: false,
      errorNameAndLastname: "",
      errorOrganization: "",
      errorContactPhone: "",
      errorContactEmail: "",
      errorMessage: "",
      sended: false,
    };
  }

  handleChange = (event) => {
    const isFormValid = this.validateFields(
      event.target.name,
      event.target.value
    );
    this.setState({
      contactData: {
        ...this.state.contactData,
        [event.target.name]: event.target.value,
      },
      isFormValid,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.isFormValid) {
      registerBusinessContact(this.state.contactData);
      this.setState({ sended: true });
    }
  };

  hasError = () => {
    if (this.state.errorNameAndLastname !== "") return true;
    if (this.state.errorOrganization !== "") return true;
    if (this.state.errorContactEmail !== "") return true;
    if (this.state.errorContactPhone !== "") return true;
    if (this.state.errorMessage !== "") return true;
    if (this.state.contactData.nameAndLastname === "") return true;
    if (this.state.contactData.organization === "") return true;
    if (this.state.contactData.contactEmail === "") return true;
    if (this.state.contactData.contactPhone === "") return true;
    if (this.state.contactData.message === "") return true;
  };

  validateFields = (fieldName, value) => {
    switch (fieldName) {
      case "nameAndLastname":
        if (value === "") {
          this.setState({
            errorNameAndLastname:
              'El campo "Nombre y Apellido" no puede estar vacio',
          });
        } else {
          this.setState({ errorNameAndLastname: "" });
        }
        break;
      case "organization":
        if (value === "") {
          this.setState({
            errorOrganization: 'El campo "Organización" no puede estar vacio',
          });
        } else {
          this.setState({ errorOrganization: "" });
        }
        break;
      case "contactPhone":
        if (value === "") {
          this.setState({
            errorContactPhone: 'El campo "Teléfono" no puede estar vacio',
          });
        } else if (value.length !== 9) {
          this.setState({
            errorContactPhone:
              "El teléfono ingresado debe tener máximo 9 números",
          });
        } else {
          this.setState({ errorContactPhone: "" });
        }
        break;
      case "contactEmail":
        if (value === "") {
          this.setState({
            errorContactEmail: 'El campo "Email" no puede estar vacio',
          });
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          this.setState({
            errorContactEmail: "El email ingresado es inválido",
          });
        } else {
          this.setState({ errorContactEmail: "" });
        }
        break;
      case "message":
        if (value === "") {
          this.setState({
            errorMessage: 'El campo "Breve mensaje" no puede estar vacio',
          });
        } else if (value.length > 300) {
          this.setState({
            errorMessage:
              'El campo "Breve mensaje" no puede tener más de 300 caracteres',
          });
        } else {
          this.setState({ errorMessage: "" });
        }
        break;
      default:
        break;
    }
    if (this.hasError()) {
      return false;
    }
    return true;
  };

  closeDialog = () => {
    this.setState({ sended: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SuccessDialog
          open={this.state.sended}
          closeDialog={this.closeDialog}
        />
        <Hidden smDown>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.gridMargin}
          >
            <Grid item xs={6}>
              <TitleH1>
                ¿Listo para involucrar a Data Scientists de todo el mundo para
                encontrar soluciones de alta calidad para tu empresa?
              </TitleH1>
              <hr />
              <TitleD>{"¿Quieres conversar o tienes dudas?"}</TitleD>
              <label className={classes.label} htmlFor="nameAndLastname">
                Nombre y apellido
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="nameAndLastname"
                id="nameAndLastname"
                placeholder="Jhon Doe"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorNameAndLastname !== ""}
                className={classes.textHelper}
              >
                {this.state.errorNameAndLastname}
              </FormHelperText>
              <label className={classes.label} htmlFor="organization">
                Organización
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="organization"
                id="organization"
                placeholder="Google Inc"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorOrganization !== ""}
                className={classes.textHelper}
              >
                {this.state.errorOrganization}
              </FormHelperText>
              <label className={classes.label} htmlFor="contactEmail">
                Email
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="email"
                name="contactEmail"
                id="contactEmail"
                placeholder="jhon@doe.com"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorContactEmail !== ""}
                className={classes.textHelper}
              >
                {this.state.errorContactEmail}
              </FormHelperText>
              <label className={classes.label} htmlFor="contactPhone">
                Teléfono
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="number"
                name="contactPhone"
                id="contactPhone"
                placeholder="9 1234 5678"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorContactPhone !== ""}
                className={classes.textHelper}
              >
                {this.state.errorContactPhone}
              </FormHelperText>
              <label className={classes.label} htmlFor="message">
                Breve mensaje
              </label>
              <TextField
                fullWidth
                InputProps={{ classes: { notchedOutline: classes.textArea } }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="message"
                id="message"
                placeholder="Lorem ipsum dolor..."
                onChange={this.handleChange}
                multiline
                rows={5}
              />
              <FormHelperText
                error={this.state.errorMessage !== ""}
                className={classes.textHelper}
              >
                {this.state.errorMessage}
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <div style={{ padding: "9em 0 0 10em" }}>
                <ContactFormImage />
              </div>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              className={classes.gridMargin}
            >
              <Button
                disabled={!this.state.isFormValid}
                classes={{
                  root: classes.button,
                  disabled: classes.buttonDisabled,
                }}
                type="submit"
                onClick={this.onSubmit}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.gridMargin}
          >
            <Grid item xs={9}>
              <TitleH2>
                ¿Listo para involucrar a Data Scientists de todo el mundo para
                encontrar soluciones de alta calidad para tu empresa?
              </TitleH2>
              <hr />
              <TitleD>{"¿Quieres conversar o tienes dudas?"}</TitleD>
              <label className={classes.label} htmlFor="nameAndLastname">
                Nombre y apellido
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="nameAndLastname"
                id="nameAndLastname"
                placeholder="Jhon Doe"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorNameAndLastname !== ""}
                className={classes.textHelper}
              >
                {this.state.errorNameAndLastname}
              </FormHelperText>
              <label className={classes.label} htmlFor="organization">
                Organización
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="organization"
                id="organization"
                placeholder="Google Inc"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorOrganization !== ""}
                className={classes.textHelper}
              >
                {this.state.errorOrganization}
              </FormHelperText>
              <label className={classes.label} htmlFor="contactEmail">
                Email
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="email"
                name="contactEmail"
                id="contactEmail"
                placeholder="jhon@doe.com"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorContactEmail !== ""}
                className={classes.textHelper}
              >
                {this.state.errorContactEmail}
              </FormHelperText>
              <label className={classes.label} htmlFor="contactPhone">
                Teléfono
              </label>
              <TextField
                fullWidth
                InputProps={{
                  classes: { notchedOutline: classes.normalInput },
                }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="number"
                name="contactPhone"
                id="contactPhone"
                placeholder="9 1234 5678"
                onChange={this.handleChange}
              />
              <FormHelperText
                error={this.state.errorContactPhone !== ""}
                className={classes.textHelper}
              >
                {this.state.errorContactPhone}
              </FormHelperText>
              <label className={classes.label} htmlFor="message">
                Breve mensaje
              </label>
              <TextField
                fullWidth
                InputProps={{ classes: { notchedOutline: classes.textArea } }}
                variant="outlined"
                FormHelperTextProps={{
                  root: classes.textHelper,
                  error: classes.textHelper,
                }}
                type="text"
                name="message"
                id="message"
                placeholder="Lorem ipsum dolor..."
                onChange={this.handleChange}
                multiline
                rows={5}
              />
              <FormHelperText
                error={this.state.errorMessage !== ""}
                className={classes.textHelper}
              >
                {this.state.errorMessage}
              </FormHelperText>
            </Grid>
            <Grid
              item
              container
              xs={9}
              justifyContent="center"
              className={classes.gridMargin}
            >
              <Button
                disabled={!this.state.isFormValid}
                classes={{
                  root: classes.button,
                  disabled: classes.buttonDisabled,
                }}
                type="submit"
                onClick={this.onSubmit}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </React.Fragment>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
