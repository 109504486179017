import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Linking } from "../styling/Styles";
import * as ROUTES from "../config/routes";
import Logo from "../styling/Logo";

const styles = (theme) => ({
  container: {
    padding: "4em",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  logo: {
    width: "150px",
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
  },
});

//test

class ConfirmationPage extends React.Component {
  render() {
    const { history, classes } = this.props;

    return (
      <Box height="100%" className={classes.container}>
        <Typography align="left" color="primary" variant="h3">
          Gracias!
        </Typography>
        <Typography align="left" color="primary" variant="body1">
          Bienvenido a la comunidad de Binnario!
        </Typography>
        <Typography align="left" color="primary" variant="body1">
          Enviamos un link a tu mail para verificar la dirección de correo
          electrónico de tu cuenta{" "}
        </Typography>
        <Box pt={5}>
          <Linking
            history={history}
            onClick={() => this.props.history.push(ROUTES.LANDING)}
          >
            <Logo />
          </Linking>
        </Box>
      </Box>
    );
  }
}

ConfirmationPage.propTypes = {
  classes: PropTypes.string,
  history: PropTypes.object,
};

export default withStyles(styles)(withRouter(ConfirmationPage));
